import React from "react";
import moment from "moment";
import {
    translateCaaStatusToTypo,
    translatePrincipalToTypo
} from "../../../utils/applicationsHelpers";
import {Fields} from "redux-form";
import isEmpty from "lodash.isempty";
import get from "lodash.get"
import defaultTo from "lodash.defaultto";
import {List, ListItem, ListItemIcon, ListItemText, withStyles} from "@material-ui/core"
import StatusField from "../../StatusField";
import {getStatusIconColored} from "../../../utils/Dashboard/statusIcon";
import Typography from "@material-ui/core/Typography";
import {dateTimeWeekDayFormatter} from "../../../utils/momentHelpers";
import {translate} from "react-admin"
import compose from 'recompose/compose';
import {CaaApplicationStatuses} from "../../../../LogicV1Redux/constants/CaaApplicationEnums";

const MSG_GOV = 'MSG_GOV';
const MSG_USER = 'MSG_USER';

const styles = theme => ({
    list: {
        paddingLeft: "10%",
        paddingRight: "10%"
    },
    item: {
        borderLeftStyle: "dashed",
        borderLeftColor: "black"
    },
    timeline: {
        maxWidth: "1024px",
        width: "90%",
        margin: "0 auto",
    },
    timelineItem: {
        paddingBottom: "2rem",
        position: "relative",
        color: "rgba(0, 0, 0, .81)",
        borderLeft: "2px solid",
        borderColor: "black",
        borderRadius: "0",
        margin: "0",
        alignItems: "flex-start",
        // "&:before": {
        //     content: '',
        //     position: "absolute",
        //     left: "2rem",
        //     fontWeight: "bold",
        //     top: "1rem",
        //     display: "block",
        //     fontSize: "0.7rem"
        // },
        "&:after": {
            width: "10px",
            height: "10px",
            display: "block",
            top: "1rem",
            position: "absolute",
            left: "-8px",
            borderRadius: "10px",
            content: "''",
            border: "2px solid black",
            background: theme.palette.colorCircle.second,
        },
        "&:last-child:after": {
            background: theme.palette.primary.light
        },
        "&:last-child": {
            borderImage: `linear-gradient(to bottom, #000 1.5rem, rgba(0,0,0, 0) 1.5rem) 1 100%`,
        }
    }

});

const PureConversationMsgs = ({messages, classes, translate}) => {

    const _getBody = ({message}) => {
        const {_type, timestamp, msg: text, meetings, issuerEmail, status, principal} = message;
        const time = dateTimeWeekDayFormatter(moment(timestamp*1000));
        const _meetings = meetings && meetings.map(meeting => moment(meeting).format("DD-MM-YYYY HH:mm"));
        const _status = translateCaaStatusToTypo(status);

        const _principal = principal &&
            React.cloneElement(translatePrincipalToTypo(principal),
                {style:{display: "inline"}});

        switch (_type) {
        case MSG_USER:
                return (
                    <ListItem key={timestamp} className={classes.timelineItem}>
                        <ListItemIcon>
                            <StatusField source="status"
                                         record={{status}}
                                         tooltip={false}
                                         iconGetter={getStatusIconColored}
                                         statusMappingFn={translateCaaStatusToTypo}
                                         label={'resources.applications.verification'}
                                         mini
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={_status}
                            secondary={
                                <>
                                    <Typography variant="caption">
                                        {translate(`resources.applications.principal`, {smart_count: 1})}, {time}
                                    </Typography>
                                    <br/>
                                    {_principal &&
                                    <>
                                        {translate('resources.applications.principal')}: <span style={{color: 'black'}}>{_principal}</span>
                                        <br/>
                                    </>
                                    }
                                    {text &&
                                    <>
                                        {translate('resources.applications.rejectMeetingDialogLabel')}: <span style={{color: 'black'}}>{text}</span>
                                        <br/>
                                    </>
                                    }
                                    {_meetings &&
                                    <>
                                        {translate('resources.applications.rejectMeetingDatePropsals')}: <span style={{color: 'black'}}>{_meetings.join(', ')}</span>
                                        <br/>
                                    </>
                                    }
                                </>
                            }
                        />
                    </ListItem>
                );
        case MSG_GOV:
                return (
                    <ListItem key={timestamp} className={classes.timelineItem}>
                        <ListItemIcon>
                            <StatusField source="status"
                                         record={{status}}
                                         tooltip={false}
                                         iconGetter={getStatusIconColored}
                                         statusMappingFn={translateCaaStatusToTypo}
                                         label={'resources.applications.verification'}
                                         mini
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={_status}
                            secondary={
                                <>
                                    <Typography variant="caption">
                                        {time}
                                    </Typography>
                                    <br/>
                                    {text &&
                                    <>
                                        {
                                            //two type of messages can come here
                                            translate(
                                            status === CaaApplicationStatuses.HOLD_MEETING_REQUEST ?
                                                'resources.applications.meetingDialogLabel'
                                                :
                                                'resources.applications.rejectDialogDefaultText'
                                            )
                                        }: <span style={{color: 'black'}}>{text}</span>
                                        <br/>
                                    </>
                                    }
                                    {_meetings &&
                                    <>
                                        {translate('resources.applications.meetingDate')}: <span style={{color: 'black'}}>{_meetings.join(', ')}</span>
                                        <br/>
                                    </>
                                    }
                                    {issuerEmail &&
                                    <>
                                        {translate('resources.applications.issuedBy')}: <a href={"mailto:" + issuerEmail}>{issuerEmail}</a>
                                        <br/>
                                    </>
                                    }
                                </>
                            }
                        />
                    </ListItem>
                );
        default:
            return null
        }
    };

    return (
            <List className={classes.timeline}>
                {
                    messages.map(
                        message => _getBody({message})
                    )
                }
            </List>


    )
};

const ConversationMsgs = compose(
    withStyles(styles),
    translate
)(PureConversationMsgs);



export const CaaConversationField = ({record, classes = {}, notInForm, style, className}) => {

    const userMsgs = defaultTo(
        get(record, 'userMsgs'),
        []
    );
    const govMsgs = defaultTo(
        get(record, 'govMsgs'),
        []
    );

    if(isEmpty(govMsgs) && isEmpty(userMsgs)) return null;

    let msgs = [];
    govMsgs.forEach(msg => msgs.push({...msg, _type: MSG_GOV}));
    userMsgs.forEach(msg => msgs.push({...msg, _type: MSG_USER}));

    msgs = msgs.sort((msgA, msgB) => msgA.timestamp - msgB.timestamp);

    const Component = () => <ConversationMsgs messages={msgs}/>;

    return notInForm ?
        <Component/>
        :
        <Fields names={['govMsgs', 'userMsgs', 'status']} component={Component}/>
};

