import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Delete from "@material-ui/icons/Delete";

import classnames from 'classnames';
import { translate } from 'ra-core';
import {Button} from 'react-admin';


import {TooltipProxy as Tooltip} from "../../TooltipProxy"
import {zoneDelete} from "../../../../LogicV1Redux/actions/ZonesV2Actions";
import {RESOURCES} from "../../../logic/MyRestConfig";

const styles = theme => ({
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "inherit",
            color: theme.palette.error.main,
        },
        '&:hover': {
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    }
});


class ZoneDeleteButton extends Component {
    _handleDelete = event => {
        event.preventDefault();
        const {
            dispatchDeleteZone,
            record,
            redirect,
        } = this.props;

        dispatchDeleteZone({uid: record.id, redirect});
    };

    render() {
        const {
            classes = {},
            className,
            record,
            disabled,
        } = this.props;

        const zoneType = record.type;

        const isDRAx =
            zoneType === 'DRAI' ||
            zoneType === 'DRAR' ||
            zoneType === 'DRAP';

        if(!isDRAx) return null;

        const deleteButton = (
            <Button
                onClick={this._handleDelete}
                label={'ra.action.delete'}
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                key="button"
                disabled={disabled}
            >
                <Delete/>
            </Button>
        );

        return (
                disabled ?
                    <Tooltip content={'resources.zones.tooltips.noPermissionsToArchive'} placement={'left'}>
                        <div>
                            {deleteButton}
                        </div>
                    </Tooltip>
                    :
                    deleteButton
            )
    }
}

ZoneDeleteButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    dispatchDeleteZone: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
};

ZoneDeleteButton.defaultProps = {
    redirect: `/${RESOURCES.ZONES}`,
};

export default compose(
    connect(
        null,
        dispatch => ({
                dispatchDeleteZone: (e)=>dispatch(zoneDelete(e)),
        })
    ),
    translate,
    withStyles(styles)
)(ZoneDeleteButton);
