
import React from 'react';
//import PropTypes from 'prop-types';


import sizeMe from "react-sizeme";

import PoiGeomViewer from "./PoiGeomViewer";
import PoiGeomEditor from "./PoiGeomEditor";

import {primitives2Bbox} from "../../../../gl_map/glSetups/oldCommon/_CommonStuff";
import {initializeLayersObject} from "../../../../gl_map/glSetups/newCommon/mapStateConstructors";
import StoreProvider from "../../../MyStoreProvider";
import {storeCustomLayer} from "../../../../LogicV1Redux/actions/CustomLayerActions";
import {clearAllPrimitives, setAllEditablePrimitives} from "../../../../LogicV1Redux/actions/DrawEditorActions";

import {shallowEqual} from 'recompose';
import {fetchAllZonesV2Rq} from "../../../../LogicV1Redux/actions/ZonesV2Actions";
import {deserializePoiData} from "../../../../LogicV1Redux/oldLogic/PoisListV2";


//currently almost the same as ZoneField,
// but
// starting development as viewer only
// will be extended to support multiple poi types

class MyPoiGeomField extends React.Component {

    componentDidMount() {
        console.log("MPF did mount");
        this._do();
    }

    componentWillUnmount() {
        console.log("MPF unmount");
        StoreProvider.getStore().dispatch(clearAllPrimitives());

    }

    componentDidUpdate(prevProps) {
        console.log("MPF did update", prevProps, this.props);
        if (!shallowEqual(this.props, prevProps)) {
            this._do();
        } else {
            console.log('MPF shallow equal false');
        }
    }


    _do = () => {
        console.log("MPF did do");

        //hacking so-called inifinite rerender on opened settings view
        // probably caused by dispatch call below AND multiple store changes created by new form!)
        //i guess can be normally binded as prop from state, but later...

        // const zonesV2 = StoreProvider.getState().zonesV2;
        // if (zonesV2.rawData.length === 0) {
        //     StoreProvider.getStore().dispatch(fetchAllZonesV2Rq());
        // }

        const state = StoreProvider.getStore().getState();

        if (state.mySettings.visible) {
            console.warn('DO NOT update poi on settings open!');
            return;
        }

        const zonesV2 = state.zonesV2;
        if (zonesV2.rawData.length === 0) {
            StoreProvider.getStore().dispatch(fetchAllZonesV2Rq());
        }

        const { record = {}, size, showEditLink = false, showConflicts=false } = this.props;
        const zoneTypesV2 = StoreProvider.getState().zoneTypesV2;

        const poiData = deserializePoiData(record, zoneTypesV2);
        console.log('==================MyPOIV2', poiData);
        console.log('==MyPoiField', { record , size, showEditLink , showConflicts });

        if (showConflicts) {
            console.error('no conflicts support in poi viewer / editor as for now');
        }

        const poiPrimitives = (poiData && poiData.primitive)?[poiData.primitive]:[];
        const bBox = primitives2Bbox(poiPrimitives);

        console.log(poiPrimitives);

        const primitives = poiPrimitives;

        console.log(primitives);

        //yikes. temp!
        const newLayer = initializeLayersObject();
        newLayer.polys = primitives;
        //newLayer.texts = warningsTexts;
        //console.log(newLayer)
        if (this.props.enableEdit) {
            StoreProvider.getStore().dispatch(setAllEditablePrimitives(primitives));
        }
        else {
            StoreProvider.getStore().dispatch(storeCustomLayer({deckData: newLayer}));
        }
        this.setState({bBox});

    };



    render() {
        if (!this.state) {
            return null;
        }

        //sanitize
        const {
            record = {},
            enableEdit = false,
            //permissions,
            //dispatch, //hmm reattaching later in context (btw - is it passed now?)
            //...rest
        } = this.props;

        const {legsPrimitives, helpers, conflicts, bBox} = this.state;

        //temp using zone edit/view
        const EditorOrViewer = (enableEdit) ? PoiGeomEditor : PoiGeomViewer;

        //const EditorOrViewer = PoiViewer; //to reenable enableEdit
        return (
            <EditorOrViewer record={record}
                //helpers={helpers}
                //conflicts={conflicts}
                //legsPrimitives={legsPrimitives}
                            bBox = {bBox}
            />


        )
    }
}

export default MyPoiGeomField;
