import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { translate } from 'ra-core';
import compose from 'recompose/compose';
import pure from 'recompose/pure';

export const TabContainer = (props) => {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }} className={props.className}>
            {props.children}
        </Typography>
    );
};

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    scrollButtons: {
        color: "inherit",
        width: 56,
        flexShrink: 0,
    }
});

class MyScrollableTabs extends React.Component {
    state = {
        value: 0,
    };

    _handleChange = (event, value) => {
        this.setState({ value });
        console.log("MST", event, value)
    };

    _renderTabs = () => {
        const children = this.props.children;
        const translate = this.props.translate;
        const tabs = [];
        let counter = -1;
        React.Children.forEach(children, (child) => {
            tabs.push(<Tab label={translate(child.props.title)} key={++counter}/>);
        });
        return tabs;
    };

    _renderContainers = () => {
        const children = this.props.children;
        const {value} = this.state;
        const containers = [];
        let counter = -1;
        React.Children.forEach(children, (child) => {
            counter++;
            containers.push(value === counter && child);
        });
        return containers;
    };

    render() {
        const { classes }= this.props;
        const { value } = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" color="default" id='rrr'>
                    <Tabs
                        classes={{scrollButtons: classes.scrollButtons}}
                        value={value}
                        onChange={this._handleChange}
                        scrollButtons='on'
                        scrollable
                    >
                        {this._renderTabs()}
                    </Tabs>
                </AppBar>
                {this._renderContainers()}
            </div>
        );
    }
}

MyScrollableTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    children: function (props, propName, componentName) {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, function (child) {
            if (child === null) return null;
            if (child.type !== TabContainer) {
                error = new Error('`' + componentName + '` children should be of type `TabContainer`.');
            }
        });
        return error
    },
};

const enhance = compose(
    withStyles(styles),
    translate,
    pure
);

export default enhance(MyScrollableTabs);