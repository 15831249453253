import React, {Component} from 'react';
import {
    AutocompleteInput,
    Create,
    Datagrid,
    DisabledInput,
    Edit,
    Filter,
    FormDataConsumer,
    LongTextInput,
    maxLength,
    minLength,
    minValue,
    number,
    NumberInput,
    RadioButtonGroupInput,
    required,
    Responsive,
    SelectArrayInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {bindActionCreators} from 'redux'
import {change, Field, formValueSelector, getFormMeta, getFormSyncErrors, isValid, reset} from 'redux-form';
import {find, isEmpty} from 'lodash';

import {connect} from 'react-redux'
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import BasicShowActions from "../MyBasicShowActions";
import {ListDecorator as List} from "../ListDecorator";
//import AutoComplete from 'material-ui/AutoComplete';
import MyOperatorsSearch from './MyOperatorsSearch';

import MyTextField from '../MyTextField';
import {adjustTimeFormatterToScreen, dateTimeWeekDayFormatter} from '../../utils/momentHelpers'
import {isOperator} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions"
import BasicListActions from "../MyBasicListActions"

import DataProvider from "../../MyDataProvider";

import MyTransitionDecorator from "../MyTransitionDecorator";
import MyHorizontalStepper from "../MyHorizontalStepper";
import MyStep from "../MyStep";
import StoreProvider from "../../MyStoreProvider";
import {MyRecordTitle} from "../MyTitle";
import {CaaVerifyToolbar, SaveVerifyToolbar} from "../MyBasicEditToolbar";
import MyPagination from "../MyPagination";
import {TooltipProxy as Tooltip} from '../TooltipProxy';
import TranslatableTypography from "../TranslatableTypography";
import MyFileField from "../MyFileField";
import MyFileInput from "../MyFileInput";
import MyFilesField from "../MyFilesField";
import Labeled from "ra-ui-materialui/esm/input/Labeled";
import {
    isCaaApplMeetingHoldStatus,
    translateCaaStatusToTypo,
    translatePrincipalToTypo
} from "../../utils/applicationsHelpers";
import MyScrollableTabs, {TabContainer} from "../MyScrollableTabs";
import Divider from "@material-ui/core/Divider/Divider";
import {CaaMsgBlockField} from "./ApplicationsCustomComponents/CaaMsgBlockField";
import StatusField from "../StatusField";
import {getStatusIconColored} from "../../utils/Dashboard/statusIcon";
import has from 'lodash.has'
import {InfoText} from "../InfoText";
import {INFO_MSG_DRONE_PHOTO_SERIAL_NO} from "../../logic/box/InfoboxConstants";
import {showInfoMessages} from "../../logic/box/InfoboxActions";
import {OperatorMsgBlockField} from "./ApplicationsCustomComponents/OperatorMsgBlockField";
import {
    CaaApplicationStatuses,
    CaaApplicationStatusesSelectInputArray
} from "../../../LogicV1Redux/constants/CaaApplicationEnums";
import MyIlikeSearch from "./MyIlikeSearch";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import {DroneSummaryCards} from "./DroneCustomComponents/DroneSummaryCards";
import {DroneActionButtons} from "./DroneCustomComponents/DroneActionButtons";
import ApplicationCreateToolbar from "./ApplicationsCustomComponents/ApplicationCreateToolbar";
import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../Configs";
import {CaaMessagesBlockField} from "./ApplicationsCustomComponents/CaaMessagesBlockField";
import {API_VERBS} from "../../MyApiVerbs";

const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);

/*
    "uid": "string",
    "name": "string",
    "make": "string",
    "type": "string",
    "class": "string",
    "mtom": "string",
    "ft": 0,
    "vmax": 0,
    "fs": "string",
    "etc": {},
    "operatorid": "string",
    "registry": "string",
*/
export const droneClasses = [
    { id: 'Quad', name: 'Quad' },
    { id: 'Hexa', name: 'Hexa' },
    { id: 'Octo', name: 'Octo' },
];
//Typ (Pole wyboru): MR - Wielowirnikowiec (Multi Rotor), H - Śmigłowiec (Helicopter), A - Samolot lub szybowiec (Aircraft), AS - Sterowiec, Balon (Airship, Balloon)

export const droneTypes = [
    { id: 'MR', name: 'Multi Rotor' },
    { id: 'H', name: 'Helicopter' },
    { id: 'A', name: 'Aircraft' },
    { id: 'AS', name: 'Airship, Balloon' },
];
//Emergency mode: pole wyboru z możliwością zaznaczenia kilku) RTH (Return-to-home), (Return-to-lunch), Autoland, Hoover

export const droneFailsafes = [

    { id: 'RTH', name: 'Return-to-home (RTH)' },
    { id: 'RTL', name: 'Return-to-launch (RTL)' },
    { id: 'AL', name: 'Autoland (AL)' },
    { id: 'H', name: 'Hover (H)' },
];

export const droneCategories = [
    { id: 'UML5', name: 'UML 5' },
    { id: 'UML25', name: 'UML 25' },
];

export const droneDrives = [
    { id: 'ELEC', name: 'resources.droneModels.fields.driveTypes.electric' },
    { id: 'FUEL', name: 'resources.droneModels.fields.driveTypes.fuel' },
    { id: 'HYB', name: 'resources.droneModels.fields.driveTypes.hybrid' },
];

export const droneFlightModes = [
    { id: 'MANUAL', name: 'resources.droneModels.fields.flightModeTypes.manual' },
    { id: 'GPS', name: 'resources.droneModels.fields.flightModeTypes.gps' },
];

export const droneLights = [
    { id: 'NAV', name: 'resources.droneModels.fields.lightTypes.navigation' },
    { id: 'STROB', name: 'resources.droneModels.fields.lightTypes.strobo' },
];

export const DronesList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureDronesList {...props}/>
    </MyTransitionDecorator>
);

export const DronesArchiveList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureDronesList asArchiveView={true} {...props}/>
    </MyTransitionDecorator>
);

//custom filters to add. null status issue to solve
const DronesFilter = (props) => {
    if (!props.permissions) return null;

    return (
    <Filter {...props}>
        <MyIlikeSearch source="uid"
                       label='resources.missions.fields.uid'
                       inlineStyle={{minWidth: "305px"}}/>

        { (isOperator(props.permissions.roles))
            ?
                null
            //*<BooleanInput source="active" alwaysOn/>*/
            //<BooleanInput source="active" alwaysOn/>
            :
            <MyOperatorsSearch source="operatorid"
                               label={'resources.drones.fields.operator'}
            />
        }
        <MyIlikeSearch source="make"
                       label='resources.droneModels.fields.make'
        />
        <MyIlikeSearch source="model"
                       label='resources.droneModels.fields.model'
        />
        <MyIlikeSearch source="name"
                       label='resources.drones.fields.name'
        />
        <MyIlikeSearch source="registry"
                       label='resources.drones.fields.registry'
        />
        <SelectInput source="type"
                     label='resources.droneModels.fields.type'
                     choices={droneTypes}
                     allowEmpty={false}
        />
        <SelectInput source="status"
                     label='resources.applications.verification'
                     choices={CaaApplicationStatusesSelectInputArray}
                     optionText={
                         choice => props.translate(choice.name, {smart_count: 2})
                     }
                     translateChoice={false}
                     allowEmpty={false}
        />
    </Filter>
)};

const APPLICATION_PRINCIPAL_IN_SHOW_VIEW_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.APPLICATION_SHOW_VIEW_WITH_PRINCIPAL);

const datagridStyles = theme => ({
    rowCell: {
        [`@media screen and (max-width: 970px)`]: {
            '&:last-child': {
                paddingRight: `2px`,
            },

        },
    }
});

const PureDronesList = compose(withWidth(),withStyles(datagridStyles))(
    ({permissions, asArchiveView = false, width, classes, ...props}) => {

    if (!permissions) return null;

    const ActionsList =  (isOperator(permissions.roles))
        ?<BasicListActions
            archiveLink={(asArchiveView)?"/drones":"/dronesArchive"}
            archiveLabel={(asArchiveView)?'resources.archives.activeLabel':'resources.archives.archiveLabel'}
        />
        :<BasicListActions />;

        const isOp = isOperator(permissions.roles);

    return (
    <List {...props}
          title={'resources.drones.menu'}
          filters={<DronesFilter permissions={permissions}/>}
          //Check to avoid using GET as filter method not GET_MANY that passes created and null statuses to gov
          filterDefaultValues={isOp ? { active: !asArchiveView } : undefined}
          sort={{field: 'created', order: 'DESC'}}
          bulkActions={false}
          actions={ActionsList}
          pagination={<MyPagination/>}
          undoable={undefined}

    >
        <Responsive
            small={
                <DroneSummaryCards resource={props.resource}
                                   {...{isOp, permissions, width}}/>
            }
            medium={

                <Datagrid classes={{rowCell: classes.rowCell}}>
                    {/*atc columns reviewed in #96/98 issues, but (re?) enabling modified*/}

                    <MyTextField source="modified"
                                 format={adjustTimeFormatterToScreen({date: true}, width)}
                                 tooltip={isWidthDown('md', width)}
                                 tooltipFormatter={dateTimeWeekDayFormatter}
                    />

                    <TextField source="make"
                               label={'resources.droneModels.fields.make'}/>
                    <TextField source="model" />

                    <TextField source="name"/>
                    <TextField source="registry"/>
                    <TextField source="type"
                               label={'resources.droneModels.fields.type'}/>

                    <StatusField source="status"
                                 tooltip={true}
                                 statusMappingFn={translateCaaStatusToTypo}
                                 iconGetter={getStatusIconColored}
                                 label={'resources.applications.verification'}
                                 />
                    {/*<TextField source="active" />*/}

                    {isOp
                        ? null
                        :
                        <ReferenceField source="operatorid"
                                        reference="operators"
                                        linkType="show"
                                        addLabel={true}
                                        sortable={false}
                                        label={'resources.drones.fields.operator'}
                        >
                            <TextField source="lastName"/>
                        </ReferenceField>
                    }

                    <DroneActionButtons resource={props.resource}
                                        permissions={permissions}
                                        record="record"
                    />
                </Datagrid>
            }
        />
    </List>
)});



const simpleDronesShowFields = [
    <MyTextField key='name' source="name" />,
    <MyTextField
        label={'resources.drones.fields.registry'}
        source="registry"
        key="registry"
    />,
    <MyTextField
        label={'resources.drones.fields.serialNo'}
        source="etc.serialNo"
        key="etc.serialNo"

    />,
    <MyTextField
        label={'resources.droneModels.fields.model'}
        source="model"
        key="model"

    />,
    <MyTextField
        label={'resources.droneModels.fields.make'}
        source="make"
        key="make"

    />,
    <MyTextField
        label={'resources.droneModels.fields.class'}
        source="class"
        key="class"

    />,
    <MyTextField
        label={'resources.droneModels.fields.type'}
        source="type"
        key="type"

    />,
    <MyTextField
        label={'resources.droneModels.fields.category'}
        source="etc.category"
        key="etc.category"

    />,
    <MyTextField
        label={'resources.droneModels.fields.mtom'}
        source="mtom"
        key="mtom"

    />,
    <MyTextField
        label={'resources.droneModels.fields.ft'}
        source="ft"
        key="ft"

    />,
    <MyTextField
        label={'resources.droneModels.fields.vmaxH'}
        source="vmax"
        key="vmax"

    />,
    <MyTextField
        label={'resources.droneModels.fields.vmaxV'}
        source="etc.vmaxV"
        key="etc.vmaxV"

    />,
    <MyTextField
        label={'resources.droneModels.fields.range'}
        source="etc.range"
        key="etc.range"

    />,
    <MyTextField
        label={'resources.droneModels.fields.rangeFollowMe'}
        source="etc.rangeFollowMe"
        key="etc.rangeFollowMe"

    />,
    <MyTextField
        label={'resources.droneModels.fields.maxTakeOffHeight'}
        source="etc.maxTakeOffHeight"
        key="etc.maxTakeOffHeight"

    />,
    <MyTextField
        label={'resources.droneModels.fields.fs'}
        source="fs"
        key="fs"

    />,
    <MyTextField
        label={'resources.drones.fields.etc_call'}
        source="etc.call"
        key="etc.call"

    />,
    <MyTextField
        label={'resources.drones.fields.etc_icao'}
        source="etc.icao"
        key="etc.icao"

    />,
    <MyTextField
        label={'resources.drones.fields.remoteId'}
        source="etc.remoteId"
        key="etc.remoteId"

    />,
    <MyTextField
        label={'resources.drones.fields.mlu'}
        source="etc.mlu"
        key="etc.mlu"

    />,
    <MyTextField
        label={'resources.drones.fields.elu'}
        source="etc.elu"
        key="etc.elu"

    />,
    <MyTextField
        label={'resources.droneModels.fields.drive'}
        source="etc.drive"
        key="etc.drive"

    />,
    <MyTextField
        label={'resources.droneModels.fields.flightModes'}
        source="etc.flightModes"
        key="etc.flightModes"

    />,
    <MyTextField
        label={'resources.drones.fields.fpm'}
        source="etc.fpm"
        key="etc.fpm"

    />,
    <MyTextField
        label={'resources.drones.fields.fps'}
        source="etc.fps"
        key="etc.fps"

    />,
    <MyTextField
        label={'resources.droneModels.fields.camera'}
        source="etc.camera"
        key="etc.camera"

    />,
    <MyTextField
        label={'resources.droneModels.fields.lights'}
        source="etc.lights"
        key="etc.lights"

    />,
    <MyTextField
        label={'resources.drones.fields.tether'}
        source="etc.tether"
        key="etc.tether"

    />,
    <MyTextField
        label={'resources.droneModels.fields.bms'}
        source="etc.bms"
        key="etc.bms"

    />,
    <MyTextField
        label={'resources.droneModels.fields.altitudeSensor'}
        source="etc.altitudeSensor"
        key="etc.altitudeSensor"

    />,
    <MyTextField
        label={'resources.droneModels.fields.uSpaceIntf'}
        source="etc.uSpaceIntf"
        key="etc.uSpaceIntf"

    />,
    <MyTextField
        label={'resources.droneModels.fields.safeEquipment'}
        source="etc.safeEquipment"
        key="etc.safeEquipment"

    />,
    <MyTextField
        label={'resources.drones.fields.limits'}
        source="etc.limits"
        key="etc.limits"

    />,
    <MyTextField
        label={'resources.drones.fields.minAge'}
        source="etc.minAge"
        key="etc.minAge"

    />,
    <MyTextField
        label={'resources.drones.fields.impact'}
        source="etc.impact"
        key="etc.impact"

    />,
    <MyTextField
        label={'resources.drones.fields.lwa'}
        source="etc.lwa"
        key="etc.lwa"

    />,
    <MyTextField
        label={'resources.drones.fields.certification'}
        source="etc.certification"
        key="etc.certification"

    />,
    <MyTextField
        label={'resources.drones.fields.risk'}
        source="etc.risk"
        key="etc.risk"

    />,
    <MyFilesField
        //hot fixing droneadmin issue #131, later (if needed) we may add special snphoto field on backend and fix setup.
        source="attn"
        key="attn"

        label={'resources.drones.fields.photo'} addLabel={true}
    />
];


export const DronesShow = ({permissions, ...props}) => {
    if (!permissions) return null;

    return (
        <Show {...props}
            actions={<BasicShowActions/>}
              title={<MyRecordTitle resource={'drones'}/>}
        >
            <SimpleShowLayout>
                <MyTextField source="uid"/>
                {NEW_ALERTBOX_ENABLED && <CaaMessagesBlockField notInForm/>}

                {isOperator(permissions.roles)
                    ? null
                    :
                    <ReferenceField source="operatorid"
                                    reference="operators"
                                    linkType="show"
                                    addLabel={true}
                                    label={'resources.drones.fields.operator'}
                    >
                        {/*fixme to full name renderer*/}
                        <TextField source="lastName"/>
                    </ReferenceField>
                }
                {!NEW_ALERTBOX_ENABLED && <CaaMsgBlockField notInForm/>}
                {!NEW_ALERTBOX_ENABLED && <OperatorMsgBlockField notInForm/>}
                {!NEW_ALERTBOX_ENABLED && <FormDataConsumer>
                    {({formData}) =>
                        <>
                            <Labeled label={'resources.applications.verification'}>
                                {translateCaaStatusToTypo(formData.status)}
                            </Labeled>

                            {
                                APPLICATION_PRINCIPAL_IN_SHOW_VIEW_ENABLED &&
                                has(formData, 'etc.principal') &&
                                <Labeled label={'resources.applications.principal'}>
                                    {translatePrincipalToTypo(formData.etc.principal)}
                                </Labeled>
                            }
                        </>

                    }
                </FormDataConsumer>}

                {simpleDronesShowFields}


            </SimpleShowLayout>
        </Show>
    )
};

const droneModelOptionRenderer = model => `${model.make} ${model.model}`;

export function failSafes2Array(failSafesString) {
    if (Array.isArray(failSafesString)) {
        return failSafesString;
    }
    if (!failSafesString) return [];
    let ret = failSafesString.split(',').map(x => x.trim());
    console.log('failSafes2Array', failSafesString, ret);
    return ret;
}


//no etc support now
/* */
/* sample server-side filter query using both make and name -> doesn't support merged string ('dji pha' will return empty array)
/* filterToQuery={searchText => ({or: [{make: {ilike:`%${searchText}%`}}, {name:{ilike:`%${searchText}%`}} ]})}
/* */

const droneCreateFormName = 'record-form'; //'drone-create-form';

const droneCreateFormSelector = formValueSelector(droneCreateFormName);

const performanceEditFields = (disabled = false) =>
    <>
        <Tooltip content={'resources.droneModels.tooltips.mtom'}>
            <TextInput source="mtom"
                       label={'resources.droneModels.fields.mtom'}
                       validate={[required(), number(), minValue(1), minLength(2)]}
                       {...{disabled}}
            />
        </Tooltip>
        <Tooltip content={'resources.droneModels.tooltips.ft'}>
            <TextInput source="ft"
                         label={'resources.droneModels.fields.ft'}
                         validate={[required(), number(), minValue(1)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.droneModels.tooltips.vmaxH'>
            <TextInput source="vmax"
                         label={'resources.droneModels.fields.vmaxH'}
                         validate={[required(), number(), minValue(1)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.droneModels.tooltips.vmaxV'>
            <TextInput source="etc.vmaxV"
                         label={'resources.droneModels.fields.vmaxV'}
                         validate={[number(), minValue(1)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.droneModels.tooltips.range'>
            <TextInput source="etc.range"
                         label={'resources.droneModels.fields.range'}
                         validate={[number(), minValue(100)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.droneModels.tooltips.rangeFollowMe'>
            <TextInput source="etc.rangeFollowMe"
                         label={'resources.droneModels.fields.rangeFollowMe'}
                         validate={[number(), minValue(10)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.droneModels.tooltips.maxTakeOffHeight'>
            <TextInput source="etc.maxTakeOffHeight"
                         label={'resources.droneModels.fields.maxTakeOffHeight'}
                         validate={[number(), minValue(10)]}
                         {...{disabled}}
            />
        </Tooltip>
    </>;

const customFeaturesEditFields = (myAppExtras, disabled = false) =>
    <>
        <MyFileInput source="attn"
                     //hot fixing droneadmin issue #131, later (if needed) we may add special snphoto field on backend and fix setup.
                     multiple={true}
                     label={'resources.drones.fields.photo'}
                     labelMultiple={'resources.files.dropboxPlaceholder'}
                     accept="image/jpeg,image/png,application/pdf"
                     maxSize={myAppExtras.app.maxAttnSize}
                     {...{disabled}}
        >
            <MyFileField source="src" title="title"/>
        </MyFileInput>
        <Tooltip content='resources.drones.tooltips.limits'>
            <TextInput source="etc.limits"
                       label={'resources.drones.fields.limits'}
                       {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.drones.tooltips.minAge'>
            <TextInput source="etc.minAge"
                         label={'resources.drones.fields.minAge'}
                         validate={[number(), minValue(0)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.drones.tooltips.impact'>
            <TextInput source="etc.impact"
                         label={'resources.drones.fields.impact'}
                         validate={[number(), minValue(0)]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.drones.tooltips.lwa'>
            <TextInput source="etc.lwa"
                         label={'resources.drones.fields.lwa'}
                         validate={[number()]}
                         {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.drones.tooltips.certification'>
            <TextInput source="etc.certification"
                       label={'resources.drones.fields.certification'}
                       {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.drones.tooltips.risk'>
            <TextInput source="etc.risk"
                       label={'resources.drones.fields.risk'}
                       {...{disabled}}
            />
        </Tooltip>
    </>;

const equipmentEditFields = (disabled = false) =>
    <>
        <Tooltip content='resources.drones.tooltips.mlu'>
            <TextInput source="etc.mlu"
                       label={'resources.drones.fields.mlu'}
                       {...{disabled}}
            />
        </Tooltip>
        <Tooltip content='resources.drones.tooltips.elu'>
            <TextInput source="etc.elu"
                       label={'resources.drones.fields.elu'}
                       {...{disabled}}
            />
        </Tooltip>

        <RadioButtonGroupInput source="etc.drive"
                               label={'resources.droneModels.fields.drive'}
                               choices={droneDrives}
                               {...{disabled}}
        />

        <SelectArrayInput source="etc.flightModes"
                          label={'resources.droneModels.fields.flightModes'}
                          choices={droneFlightModes}
                          {...{disabled}}
        />

        <Tooltip content='resources.drones.tooltips.fpm'>
            <TextInput source="etc.fpm"
                       label={'resources.drones.fields.fpm'}
                       {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.drones.tooltips.fps'>
            <TextInput source="etc.fps"
                       label={'resources.drones.fields.fps'}
                       {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.droneModels.tooltips.camera'>
            <TextInput source="etc.camera"
                       label={'resources.droneModels.fields.camera'}
                       {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.droneModels.tooltips.lights'>
            <SelectArrayInput source="etc.lights"
                              label={'resources.droneModels.fields.lights'}
                              choices={droneLights}
                              {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.drones.tooltips.tether'>
            <TextInput source="etc.tether"
                         label={'resources.drones.fields.tether'}
                         validate={[number(), minValue(0)]}
                         {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.droneModels.tooltips.bms'>
            <TextInput source="etc.bms"
                       label={'resources.droneModels.fields.bms'}
                       {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.droneModels.tooltips.altitudeSensor'>
            <TextInput source="etc.altitudeSensor"
                       label={'resources.droneModels.fields.altitudeSensor'}
                       {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.droneModels.tooltips.uSpaceIntf'>
            <TextInput source="etc.uSpaceIntf"
                       label={'resources.droneModels.fields.uSpaceIntf'}
                       {...{disabled}}
            />
        </Tooltip>

        <Tooltip content='resources.droneModels.tooltips.safeEquipment'>
            <TextInput source="etc.safeEquipment"
                       label={'resources.droneModels.fields.safeEquipment'}
                       {...{disabled}}
            />
        </Tooltip>
    </>;

class DronesCreateBase extends Component {
    constructor(props) {
        super(props);
        // const { items, filters } = props;
        // this.state = {
        //     items,
        //     filteredItems: filterItems(items, filters),
        // };
        console.log('creating state--------')
        this.state = {
            //selectedModel: {name: null},
            lastModelId : null,
            droneModelsChoices: [],
            lastStepTouched: false
        };
        this.fillDroneData = this.fillDroneData.bind(this);

        //this.getDroneData = this.getDroneData.bind(this);
    }

    componentDidMount() {
        const dataProvider = DataProvider.getDataProvider();
        console.log('DronesCreateBase, componentDidMount');
        dataProvider(API_VERBS.GET_LIST, 'droneModels', {pagination:{perPage:null}, sort:{field: "id", order: "DESC"}})
            .then((req) => {
                console.log(API_VERBS.GET_LIST, 'after DronesCreateBase', req );
                console.log('droneModels', req.data );

                let choices=[];
                req.data.forEach((droneModel) => {
                    choices.push({
                        id: droneModel.id,
                        name: `${droneModel.make} ${droneModel.model}`,
                        record: droneModel
                    });
                });

                this.setState({droneModelsChoices: choices});
                //showNotification('myroot.auth.accountReadMsg');
                // push('/myaccount');
            })
            .catch((e) => {
                console.error(e);
                // showNotification('myroot.auth.accountReadError', 'warning');
                // userLogout();
            });
    }

    componentWillReceiveProps(nextProps) {
        this.fillDroneData(nextProps.selectedModelId);

        console.log('componentWillReceiveProps', nextProps);

    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', this);
        //change('flooring', flooring.slice(0, flooring.length - 1).concat(defaultValues));
    }

    fillDroneData(modelId) {
        console.log('pre fill data for', modelId);
        if (!modelId || modelId === this.state.lastModelId)
            return;

        console.log('fill data for', modelId);

        this.setState({lastModelId:modelId});

        let model = find(this.state.droneModelsChoices, {'id': modelId});
        console.log('model', model);

        this.props.change(droneCreateFormName,'name', model.name);

        this.props.change(droneCreateFormName,'model',model.record.model);
        this.props.change(droneCreateFormName,'make',model.record.make);
        this.props.change(droneCreateFormName,'type',model.record.type);
        this.props.change(droneCreateFormName,'class',model.record.class);
        this.props.change(droneCreateFormName,'mtom',model.record.mtom);
        this.props.change(droneCreateFormName,'ft',model.record.ft);
        this.props.change(droneCreateFormName,'vmax',model.record.vmax);
        this.props.change(droneCreateFormName,'fs',failSafes2Array(model.record.fs));
        this.props.change(droneCreateFormName,'etc.category',model.record.etc.category);
        this.props.change(droneCreateFormName,'etc.drive',model.record.etc.drive);
        this.props.change(droneCreateFormName,'etc.maxTakeOffHeight',model.record.etc.maxTakeOffHeight);
        this.props.change(droneCreateFormName,'etc.range',model.record.etc.range);
        this.props.change(droneCreateFormName,'etc.rangeFollowMe',model.record.etc.rangeFollowMe);
        this.props.change(droneCreateFormName,'etc.vmaxV',model.record.etc.vmaxV);
        this.props.change(droneCreateFormName,'etc.flightModes',model.record.etc.flightModes);
        this.props.change(droneCreateFormName,'etc.camera',model.record.etc.camera);
        this.props.change(droneCreateFormName,'etc.lights',model.record.etc.lights);
        this.props.change(droneCreateFormName,'etc.bms',model.record.etc.bms);
        this.props.change(droneCreateFormName,'etc.altitudeSensor',model.record.etc.altitudeSensor);
        this.props.change(droneCreateFormName,'etc.uSpaceIntf',model.record.etc.uSpaceIntf);
        this.props.change(droneCreateFormName,'etc.safeEquipment',model.record.etc.safeEquipment);
    }

    /*
    fillDroneData(item) {
        console.log('fillDroneData', item, lastDroneModels);
        console.log(DronesCreateBase);
        let model;
        if (item && item.value) {
            //model = find(lastDroneModels.data, {'id': item.value, name:});
            console.log(model);
            //this.state.selectedModel.name = model.name;
            //this.state.selectedModel.make = model.make;
        }
        else {
            model = {
                model : 'custom',
                make : 'n/a'
            }
            //this.state.selectedModel.name = 'custom';
            //this.state.selectedModel.make = 'n/a'
        }
        // this.setState(() => {
        //     return {selectedModel: model};
        // });
        // console.log(this.state, this);
        //this.forceUpdate();
        this.props.change('record-form','name',droneModelOptionRenderer(model));

        this.props.change('record-form','model',model.model);
        this.props.change('record-form','make',model.make);
        this.props.change('record-form','type',model.type);
        this.props.change('record-form','class',model.class);
        this.props.change('record-form','mtom',model.mtom);
        this.props.change('record-form','ft',model.ft);
        this.props.change('record-form','vmax',model.vmax);
        this.props.change('record-form','fs',failSafes2Array(model.fs));

    }
    */

    _resetForm = (formName) => {
        StoreProvider.getStore().dispatch(reset(formName));
        this.setState({
            lastStepTouched: false
        })
    };


    _setLastStepTouched() {
        if (this.state.lastStepTouched === false)
            this.setState({
                lastStepTouched: true
            })
    }

    _handleStepChange = (step) => {
        if(step===6) {
            this.props.showInfoMessages({situationID: INFO_MSG_DRONE_PHOTO_SERIAL_NO, locationValid: '/drones/create'});
        }
    };

    render() {
        console.log('drone render', this.props, this.state);

        //sanitizing react props,
        const {droneModels, selectedModelId, change, formSyncErrors, classes, isFormValid, formMeta, failSafeArray,
            call, icao, registry, serialNo, mlu, elu, drive, camera, fpm, fps, lights, tether, bms, uSpaceIntf,
            altitudeSensor, safeEquipment, customFeatures, limits, minAge, impact, lwa, certification,
            risk, photo, flightModes, myAppExtras, showInfoMessages, ...props} = this.props;
        const {droneModelsChoices, lastStepTouched} = this.state;

        return (
            <Create {...props}
                    record={{model: "DIY", make: "DIY", name: 'DIY'}}
                /* undoable={false} -  no undo in create anyway */
                    title={'resources.drones.create'}
                    className={classes.createPaper}
            >
                <SimpleForm //form={droneCreateFormName} // must be default
                    defaultValue={{}}
                    className={classes.createForm}
                    toolbar={isFormValid && Object.values(formSyncErrors).length === 0 && lastStepTouched ? <ApplicationCreateToolbar/> : null}
                >
                    <MyHorizontalStepper labelWhenOptional={'myroot.validation.optional'}
                                         title={'resources.drones.create'}
                                         onLastEditableStepEntered={() => this._setLastStepTouched()}
                                         onReset={() => this._resetForm(droneCreateFormName)}
                                         lastStepIsEditableOne
                                         onStepChanged={this._handleStepChange}
                    >
                        <MyStep label={'resources.droneModels.fields.model'} key={0}
                                isValid={!formSyncErrors.model && !formSyncErrors.make}>
                            <AutocompleteInput source="etc.modelId"
                                               label={'resources.droneModels.menu'}
                                //InputProps={{onChange:()=>{console.log('onChange')}}}
                                //onChange={(e)=>{console.log('onChange', e)}}
                                //limitChoicesToValue={true}
                                               translateChoice={false}
                                //classes={{suggestionsContainerOpen:'mypaper'}}
                                               allowEmpty
                                               choices={droneModelsChoices}
                            />
                            {/* not longer workin -> hardcoded formname in ra 2.1.2
                            <FormDataConsumer>
                                {({formData, ...rest}) => {
                                    //nasty? clever? workaround for self changing form livecycle
                                    console.log('FormDataConsumer', formData, ...rest);
                                    if (formData.etc && formData.etc.modelId) {
                                        this.fillDroneData(formData.etc.modelId);
                                    }
                                }}
                            </FormDataConsumer>
                            */}

                            <Tooltip content={'resources.droneModels.tooltips.model'}>
                                <TextInput source="model"
                                           label={'resources.droneModels.fields.model'}
                                           validate={[required(), minLength(2), maxLength(32)]}/>
                            </Tooltip>
                            <TextInput source="make"
                                       label={'resources.droneModels.fields.make'}
                                //db is set to required field..
                                       validate={[required(), minLength(2), maxLength(16)]}/>
                        </MyStep>

                        <MyStep label={'resources.droneModels.fields.type'} key={1}
                                isValid={!formSyncErrors.type}
                        >
                            <RadioButtonGroupInput source="type"
                                                   label={'resources.droneModels.fields.type'}
                                                   choices={droneTypes}
                                                   validate={[required()]}/>
                            <RadioButtonGroupInput source="class"
                                                   label={'resources.droneModels.fields.class'}
                                                   choices={droneClasses}/>
                            <RadioButtonGroupInput source="etc.category"
                                                   label={'resources.droneModels.fields.category'}
                                                   choices={droneCategories}/>
                        </MyStep>

                        <MyStep label={'resources.droneModels.fields.performance'} key={2}
                                isValid={
                                    !has(formSyncErrors, 'mtom') &&
                                    !has(formSyncErrors, 'ft') &&
                                    !has(formSyncErrors, 'vmax') &&
                                    !has(formSyncErrors, 'etc.vmaxV') &&
                                    !has(formSyncErrors, 'etc.range') &&
                                    !has(formSyncErrors, 'etc.rangeFollowMe')
                                }
                        >

                            {performanceEditFields()}

                        </MyStep>

                        <MyStep label={'resources.droneModels.fields.fs'} key={3} optional
                                isValid={
                                    !!failSafeArray
                                    && failSafeArray.length > 0
                                    && Object.values(failSafeArray[0]).length > 0}>

                            <Tooltip content='resources.droneModels.tooltips.fs'>
                                <SelectArrayInput source="fs"
                                                  label={'resources.droneModels.fields.fs'}
                                                  choices={droneFailsafes}/>
                            </Tooltip>
                        </MyStep>
                        {/*Validation needed for proper buttons displaying order*/}
                        <MyStep label={'resources.drones.fields.registry'} key={4}
                                isValid={
                                    (!!call || !!icao || !!registry || !!serialNo)
                                    && !formSyncErrors.registry
                                    && !has(formSyncErrors, 'etc.serialNo')
                                }
                        >
                            <Tooltip content='resources.drones.tooltips.registry'>
                                <TextInput source="registry"
                                           label={'resources.drones.fields.registry'}
                                    //reqistry required removed on 29-11-18
                                           validate={[minLength(2), maxLength(15)]}/>
                            </Tooltip>
                            <Tooltip content='resources.drones.tooltips.serialNo'>
                                <TextInput source="etc.serialNo"
                                           label={'resources.drones.fields.serialNo'}
                                           validate={[minLength(2), required()]}
                                />
                            </Tooltip>
                            <Tooltip content='resources.drones.tooltips.etc_call'>
                                <TextInput source="etc.call"
                                           label={'resources.drones.fields.etc_call'}
                                />
                            </Tooltip>
                            <Tooltip content='resources.drones.tooltips.etc_icao'>
                                <TextInput source="etc.icao"
                                           label={'resources.drones.fields.etc_icao'}
                                />
                            </Tooltip>
                            <Tooltip content='resources.drones.tooltips.remoteId'>
                                <TextInput source="etc.remoteId"
                                           label={'resources.drones.fields.remoteId'}
                                />
                            </Tooltip>
                        </MyStep>

                        <MyStep label={'resources.drones.fields.equipment'} key={5}
                                isValid={
                                    (
                                        !isEmpty(flightModes) ||
                                        !!mlu ||
                                        !!elu ||
                                        !!drive ||
                                        !!camera ||
                                        !!fpm ||
                                        !!fps ||
                                        !isEmpty(lights) ||
                                        !!tether ||
                                        !!bms ||
                                        !!uSpaceIntf ||
                                        !!altitudeSensor ||
                                        !!safeEquipment
                                    )
                                    && !has(formSyncErrors, 'etc.tether')
                                }
                                optional
                        >

                            {equipmentEditFields()}

                        </MyStep>

                        <MyStep label={'resources.drones.fields.customFeatures'} key={6}
                                isValid={
                                    !!customFeatures ||
                                    !!limits ||
                                    !!minAge ||
                                    !!impact ||
                                    !!lwa ||
                                    !!certification ||
                                    !!risk ||
                                    (!!photo && photo.length > 0)
                                }
                                optional
                        >
                            <InfoText content={<TranslatableTypography content="resources.drones.photoSerialNo"/>}/>

                            {customFeaturesEditFields(myAppExtras)}

                        </MyStep>

                        <MyStep label={'resources.drones.fields.name'} key={7}
                                isValid={!formSyncErrors.name}>
                            <Tooltip content="resources.drones.tooltips.name">
                                <TextInput source="name"
                                           label={'resources.drones.fields.name'}
                                           validate={[required(), minLength(2), maxLength(32)]}
                                           id="droneName"
                                           onChange={() => {
                                               if (formSyncErrors.name) {
                                                   document.getElementById("droneName").blur();
                                                   document.getElementById("droneName").focus();
                                               }
                                           }}
                                />
                            </Tooltip>
                        </MyStep>
                    </MyHorizontalStepper>

                </SimpleForm>
            </Create>
        )
    }
}

//yikes.. my first custom extends for aor
//either local stuff or component (which one?) rewrite;.. let's wait for next aor update
let lastDroneModels = {};
//let selectedModel = {};


function updateDroneData(item) {
    //console.log('updateDroneData', item)
}
const mapStateToProps = state => {
    console.log('create drone mapStateToProps', state);
    const form = state.form["record-form"];

    const modelId = (form && form.values && form.values.etc && form.values.etc.modelId)?form.values.etc.modelId:null;
    //lastDroneModels = state.admin.resources.droneModels;
    //console.log('zzzmapStateToProps', lastDroneModels);
    return {
        //droneModels : state.admin.resources.droneModels;
        selectedModelId: modelId,
        //global validation
        isFormValid: isValid(droneCreateFormName)(state),
        //touched or blured
        formMeta: getFormMeta(droneCreateFormName)(state),
        //partial validation
        formSyncErrors: getFormSyncErrors(droneCreateFormName)(state),
        failSafeArray: droneCreateFormSelector(state, 'fs'),
        call: droneCreateFormSelector(state, 'etc.call'),
        icao: droneCreateFormSelector(state, 'etc.icao'),
        registry: droneCreateFormSelector(state, 'registry'),
        serialNo: droneCreateFormSelector(state, 'etc.serialNo'),
        mlu: droneCreateFormSelector(state, 'etc.mlu'),
        elu: droneCreateFormSelector(state, 'etc.elu'),
        drive: droneCreateFormSelector(state, 'etc.drive'),
        camera: droneCreateFormSelector(state, 'etc.camera'),
        fpm: droneCreateFormSelector(state, 'etc.fpm'),
        fps: droneCreateFormSelector(state, 'etc.fps'),
        lights: droneCreateFormSelector(state, 'etc.lights'),
        tether: droneCreateFormSelector(state, 'etc.tether'),
        bms: droneCreateFormSelector(state, 'etc.bms'),
        uSpaceIntf: droneCreateFormSelector(state, 'etc.uSpaceIntf'),
        altitudeSensor: droneCreateFormSelector(state, 'etc.altitudeSensor'),
        safeEquipment: droneCreateFormSelector(state, 'etc.safeEquipment'),
        customFeatures: droneCreateFormSelector(state, 'etc.customFeatures'),
        limits: droneCreateFormSelector(state, 'etc.limits'),
        minAge: droneCreateFormSelector(state, 'etc.minAge'),
        impact: droneCreateFormSelector(state, 'etc.impact'),
        lwa: droneCreateFormSelector(state, 'etc.lwa'),
        certification: droneCreateFormSelector(state, 'etc.certification'),
        risk: droneCreateFormSelector(state, 'etc.risk'),
        flightModes: droneCreateFormSelector(state, 'etc.flightModes'),
        //hot fixing droneadmin issue #131, later (if needed) we may add special snphoto field on backend and fix setup.
        photo: droneCreateFormSelector(state, 'attn'),
        myAppExtras: state.myAppExtras,
    }
};

const mapDispatchToProps = (dispatch) => ({
    change: bindActionCreators(change, dispatch),
    showInfoMessages: bindActionCreators(showInfoMessages, dispatch),
});

//class added. styling not working.. fixme..
const styles = theme => ({
    // mypaper: {
    //     color: 'red',
    //     backgroundColor: 'blue'
    // },
    createForm: {
        margin: "0 10% 0",
        [theme.breakpoints.down('sm')]: {
            margin: "0"
        }
    },
    createPaper: {
        marginTop: "1rem"
    },
});

const editStyles = theme => ({
    editBox: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        padding: theme.spacing.unit * 2,
        marginRight: "2px",
        marginTop: theme.spacing.unit * 2,
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.5), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    },
    editBoxes: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        }
    },
    divider: {
        width: "100%",
        marginTop: "1rem",
        marginBottom: "0.7rem"
    },
    editHeader: {
        width: "100%"
    }
});

const enhanceCreate = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    //withStyles(styles),
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DronesCreateBase);

export const DronesCreate = enhanceCreate;

const mapStateToProps_edit = state => ({
    myAppExtras: state.myAppExtras,
});

//TODO work
const getDiffTextFields = (diffs, resource) => {
    const diffsTextFields = [];

    const {etc, ...cuttedDiffs} = diffs;

    for (let [key, value] of Object.entries(cuttedDiffs)) {
        if(value.changed !== 'equal') {
            const oldValue = value.removed;
            const entry =
                <Labeled label={`resources.${resource}.${key}`}>
                    <>{oldValue}</>
                </Labeled>;
            diffsTextFields.push(entry);
        }
    }

    for (let [key, value] of Object.entries(etc.value)) {
        if(value.changed !== 'equal') {
            const oldValue = value.removed;
            const entry =
                <Labeled label={`resources.${resource}.${key}`}>
                    <>{oldValue}</>
                </Labeled>;
            diffsTextFields.push(entry);
        }
    }

    return diffsTextFields;
};

//fast approach to SHOW massive amounts of fields with accept/reject btns
//can be more nested in DronesEditBase, but that one is currently reworked. so not touching there....
const DroneEditCaa = ({permissions, classes, ...props}) => {
    return (
        <Edit {...props}
              undoable={false}
              actions={<BasicEditActions />}
              title={<MyRecordTitle resource={'drones'}/>}
        >
            <SimpleForm //form={'drones-edit-form'} // must be default
                defaultValue={{}}
                toolbar= {<CaaVerifyToolbar/>}
            >
                <MyTextField source="uid"/>
                {NEW_ALERTBOX_ENABLED && <CaaMessagesBlockField/>}

                {isOperator(permissions.roles)
                    ? null
                    :
                    <ReferenceField source="operatorid"
                                    reference="operators"
                                    linkType="show"
                                    addLabel={true}
                                    label={'resources.drones.fields.operator'}
                    >
                        {/*fixme to full name renderer*/}
                        <TextField source="lastName"/>
                    </ReferenceField>
                }

                {!NEW_ALERTBOX_ENABLED && <FormDataConsumer>
                    {({formData}) =>
                        <>
                            <Labeled label={'resources.applications.verification'}>
                                {translateCaaStatusToTypo(formData.status)}
                            </Labeled>
                            {has(formData, 'etc.principal') &&

                                <Labeled label={'resources.applications.principal'}>
                                    {translatePrincipalToTypo(formData.etc.principal)}
                                </Labeled>

                            }
                            {/*{has(formData, 'etc.diffs') && getDiffTextFields(formData.etc.diffs, 'drones')}*/}
                        </>
                    }
                </FormDataConsumer>}
                {!NEW_ALERTBOX_ENABLED && <CaaMsgBlockField/>}
                {!NEW_ALERTBOX_ENABLED && <OperatorMsgBlockField/>}

                {
                    simpleDronesShowFields
                }
            </SimpleForm>
        </Edit>
    )
};

const DronesEditBase =  ({permissions, classes, myAppExtras, dispatch, ...props}) => {
    if (!permissions) return null;
    const isOp = isOperator(permissions.roles);

    if (!isOp) //assuming caa
        return <DroneEditCaa permissions={permissions} {...props}/>;

    return (
        <Edit {...props}
              undoable={false}
              actions={<BasicEditActions/>}
              title={<MyRecordTitle resource={'drones'}/>}
        >
            <SimpleForm //form={'drones-edit-form'} // must be default
                defaultValue={{}}
                toolbar={<SaveVerifyToolbar/>}
            >
                <MyTextField source="uid"/>
                {NEW_ALERTBOX_ENABLED && <CaaMessagesBlockField/>}
                {!NEW_ALERTBOX_ENABLED && <FormDataConsumer>
                    {({formData}) =>
                        <>
                            <Labeled label={'resources.applications.verification'}>
                                {/*TODO react admin form initialize updates only registred fields - AAAA!*/}
                                {/*TODO meta: {..., updateUnregisteredFields: false}*/}
                                {/*
                                    https://redux-form.com/7.4.0/docs/api/actioncreators.md/
                                    If the updateUnregisteredFields parameter is true, it will update every initialValue
                                    if still pristine instead of only registered fields. Highly recommended, defaults to
                                    false because of non-breaking backwards compatibility.*/}
                                <Field name='status' component={() => translateCaaStatusToTypo(formData.status)}/>
                            </Labeled>

                            {has(formData, 'etc.principal') &&
                            <Labeled label={'resources.applications.principal'}>
                                <Field name='etc.principal'
                                       component={() => translatePrincipalToTypo(formData.etc.principal)}/>
                            </Labeled>
                            }
                        </>
                    }
                </FormDataConsumer>}
                {!NEW_ALERTBOX_ENABLED && <CaaMsgBlockField/>}
                {!NEW_ALERTBOX_ENABLED && <OperatorMsgBlockField/>}
                <>
                    <Divider className={classes.divider}/>
                </>

                <FormDataConsumer>
                    {({formData, ...rest}) => {

                        const disableEdit =
                            isCaaApplMeetingHoldStatus(formData.status) ||
                            formData.status === CaaApplicationStatuses.VERIFY ||
                            formData.status === CaaApplicationStatuses.VERIFY_AGAIN;

                        return (
                            <>
                                <LongTextInput source="name"
                                               validate={[required(), minLength(2), maxLength(64)]}
                                               disabled={disableEdit}
                                               {...rest}
                                />
                                <MyScrollableTabs>
                                    <TabContainer title={'resources.droneModels.fields.model'}
                                                  className={classes.editBox}>
                                        <TranslatableTypography content={'resources.droneModels.fields.model'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}/>
                                        <DisabledInput source="model"
                                                       label={'resources.droneModels.fields.model'}
                                        />
                                        <DisabledInput source="make"
                                                       label={'resources.droneModels.fields.make'}
                                        />
                                    </TabContainer>

                                    <TabContainer title={'resources.droneModels.fields.type'}
                                                  className={classes.editBox}>
                                        <TranslatableTypography content={'resources.droneModels.fields.type'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}/>
                                        <DisabledInput source="type"
                                                       label={'resources.droneModels.fields.type'}
                                        />
                                        <DisabledInput source="class"
                                                       label={'resources.droneModels.fields.class'}
                                        />
                                        <DisabledInput source="etc.category"
                                                       label={'resources.droneModels.fields.category'}
                                        />
                                    </TabContainer>

                                    <TabContainer title={'resources.drones.fields.customFeatures'}
                                                  className={classes.editBox}>
                                        <TranslatableTypography content={'resources.drones.fields.customFeatures'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}
                                        />
                                        {customFeaturesEditFields(myAppExtras, disableEdit)}

                                    </TabContainer>

                                    <TabContainer title={'resources.droneModels.fields.performance'}
                                                  className={classes.editBox}>
                                        <TranslatableTypography content={'resources.droneModels.fields.performance'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}/>
                                        {performanceEditFields(disableEdit)}
                                    </TabContainer>

                                    <TabContainer title={'resources.droneModels.fields.fs'} className={classes.editBox}>
                                        <TranslatableTypography content={'resources.droneModels.fields.fs'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}
                                        />
                                        <SelectArrayInput source="fs"
                                                          format={failSafes2Array}
                                                          label={'resources.droneModels.fields.fs'}
                                                          choices={droneFailsafes}
                                                          disabled={disableEdit}
                                        />
                                    </TabContainer>

                                    <TabContainer title={'resources.drones.fields.equipment'}
                                                  className={classes.editBox}>
                                        <TranslatableTypography content={'resources.drones.fields.equipment'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}
                                        />

                                        {equipmentEditFields(disableEdit)}

                                    </TabContainer>

                                    <TabContainer title={'resources.drones.fields.registry'}
                                                  className={classes.editBox}>
                                        <TranslatableTypography content={'resources.drones.fields.registry'}
                                                                variant={"headline"}
                                                                className={classes.editHeader}
                                        />
                                        <DisabledInput source="registry"
                                                       label={'resources.drones.fields.registry'}
                                        />
                                        <DisabledInput source="etc.serialNo"
                                                       label={'resources.drones.fields.serialNo'}
                                        />
                                        <Tooltip content='resources.drones.tooltips.etc_call'>
                                            <TextInput source="etc.call"
                                                       label={'resources.drones.fields.etc_call'}
                                                       disabled={disableEdit}
                                            />
                                        </Tooltip>
                                        <Tooltip content='resources.drones.tooltips.etc_icao'>
                                            <TextInput source="etc.icao"
                                                       label={'resources.drones.fields.etc_icao'}
                                                       disabled={disableEdit}
                                            />
                                        </Tooltip>
                                        <Tooltip content='resources.drones.tooltips.remoteId'>
                                            <TextInput source="etc.remoteId"
                                                       label={'resources.drones.fields.remoteId'}
                                                       disabled={disableEdit}
                                            />
                                        </Tooltip>
                                    </TabContainer>

                                </MyScrollableTabs>
                            </>
                        )
                    }

                    }
                </FormDataConsumer>
                <>
                    <Divider className={classes.divider}/>
                </>
            </SimpleForm>
        </Edit>
    )};

export const DronesEdit = compose(
    withStyles(editStyles),
    //translate
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps_edit,
        null
    )
)(DronesEditBase);
