import Card from "./Card";
import CardHeader from "./CardHeader";
import CardIcon from "./CardIcon";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import React from "react";
import {withStyles} from '@material-ui/core/styles';
import {LinkDecorator as Link} from "../LinkDecorator";
import {MissionV2Statuses} from "../../../LogicV1Redux/constants/MissionsV2Enums";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TranslatableTypography from "../TranslatableTypography";
import TableFooter from "@material-ui/core/TableFooter/TableFooter";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {TooltipProxy as Tooltip} from '../TooltipProxy';
import {ArrowForward} from "@material-ui/icons";
import {getStatusColorText, getStatusIcon, getStatusIconColored} from "../../utils/Dashboard/statusIcon";
import {cardStylesCustom as cardStyle} from "./style/cardStylesCustom";
import {mapStatusToEntryGroupName} from "../../utils/Dashboard/groups";
import {CaaApplicationHoldStatuses, CaaApplicationStatuses} from "../../../LogicV1Redux/constants/CaaApplicationEnums";
import has from 'lodash.has'
import moment from "moment";
import Icon from "@material-ui/core/Icon/Icon";
import StatusField from "../StatusField";
import {translateCaaStatusToTypo} from "../../utils/applicationsHelpers";
import classnames from 'classnames';
import {isCaaEditor, isOperator} from "../../logic/MyRestConfig";
import {DashboardContext} from "../DashboardOriExt";
import withWidth, {isWidthDown, isWidthUp} from '@material-ui/core/withWidth';
import compose from 'recompose/compose'
import {connect} from 'react-redux'

import {ResizableBox} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {dateTimeFormatter} from "../../utils/momentHelpers";
import {setConfigurationKeyObject} from "../../logic/settings/ConfigurationActions";
import {CONFIGURATION_KEY_DASH_CARDS} from "../../logic/settings/ConfigurationConstants";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../Configs";


const ENTRIES_COUNT = 10;

const LINK_SORT = 'created'; //TODO discuss if should use created or modified.

const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);

//currently NEITHER data format NOT the same logic as in RT-MAP row renderer, although 'looks' similar
//btw... TODO only CTR ?, to checkout...

function _filterWarnings (mission) {
    //console.error('filterwarnings', warnings);

    //const ctrs = warnings.filter(e => e.type === 'CTR');

    //removing duplicates
    let out = new Set();

    //hopefully type and name have same idx after psql aggregation in view...
    //otherwise we'll have to think out some solution for working filters (splitted) and passing zone slim data
    for (let i=0; i < mission.zoneTypes.length; i++) {
        const type = mission.zoneTypes[i];
        //console.error('filterwarnings in', warning);
        if (type === 'CTR') {
            out.add(mission.zoneNames[i]);
        }
    }

    //console.error('filtered', out);

    return Array.from(out).join();
}

const _renderGroupedMissionsRows = (missions, group, isOperator, classes) => {
    const groupedMissions = {
        rows: [],
        //areTrimmed: false
        areTrimmed: missions[group].total > 10 //? ok?
    };

    for (const [i, mission] of missions[group].data.entries()) {

        // if(i>ENTRIES_COUNT-1) {
        //     groupedMissions.areTrimmed = true;
        //     break;
        // }

        //console.error('rendering missions row', mission);

        const isEditableForOp = (mission.status !== MissionV2Statuses.PLANNED
            && mission.status !== MissionV2Statuses.ACCEPTED);
        const url = `/missions/${mission.uid}/${(isOperator && isEditableForOp) ? 'edit' : 'show'}`;

        const is112 = MISSION_112_ENABLED && mission.is112
        const rowClassname = is112 && mission.isKSID ? classes.rowKSID112 :
                                 is112 ? classes.row112 :
                                 mission.isKSID ? classes.rowKSID : 
                                 classes.listItem
        const row = (
                    <TableRow
                        key={mission.uid}
                        className={rowClassname}
                        hover
                        component={Link}
                        to={{pathname: url}}
                    >
                        <TableCell padding="checkbox" className={classes.counterCell} component="div">
                            {i+1}.
                        </TableCell>
                        {isOperator ?
                            (
                                <>
                                    <TableCell padding="dense" scope="row" align="left"  component="div">
                                        {mission.name}
                                    </TableCell>
                                    <TableCell component="div"/>
                                </>
                            )
                            :
                            (
                                <>
                                    <TableCell padding="dense" scope="row"  component="div" className={classes.missionGovCell}>
                                       {_filterWarnings(mission)}
                                    </TableCell>
                                    <TableCell align="right"  component="div" className={classes.missionGovCell}>{mission.operatorName}</TableCell>
                                    <TableCell align="right"  component="div" className={classes.missionGovCell}>{mission.type}</TableCell>
                                    <TableCell align="right"  component="div" className={classes.missionGovCell}>{dateTimeFormatter(mission.start)}</TableCell>
                                </>
                            )
                        }
                    </TableRow>
                );
         groupedMissions.rows.push(row);
    }
    return groupedMissions;
};


//IMO (LGZ) bit overcomplicated now, but we had issues with backend earlier..
// and since we still are not 100% sure about requirements , maybe let's delay refactoring
function _filterApplicationsToRows(applications, group, classes, filter, isMeetingRow, mySelf) {

    console.log('==_filterApplicationsToRows', group, applications );

    const groupedApplications = {
        rows: [],
        areTrimmed: false
    };

    console.log("APP ms",  mySelf);

    //if more than one group should be selected
    const groups = typeof group === 'object' ?

        (() => {
            const values = Object.values(group);
            const mappedNames = [];
            for (const value of values) {
                mappedNames.push(mapStatusToEntryGroupName(value));
            }
            return mappedNames;
        })()
        :
        [group];

    for(const gr of groups) {
        for (const [i, application] of applications[gr].data.filter(filter).entries()) {

            if (i > (ENTRIES_COUNT - 1) / 2) {
                groupedApplications.areTrimmed = true;
                break;
            }

            const url = `/${application.kind}/${application.uid}/show`;

            let applicationLetter;
            switch (application.kind) {
                case 'drones':
                    applicationLetter = 'D';
                    break;
                case 'certs':
                    applicationLetter = 'C';
                    break;
                default:
                    applicationLetter = '?';
            }

            const lastTuple = isMeetingRow ?
                <>
                    {has(application, 'meeting') &&
                    <TableCell align="right" component="div" className={classes.operatorCell}>
                        {moment(application.meeting).format('D.MM.YYYY HH:mm')}
                    </TableCell>}
                </>
                :
                <>
                    {has(application, 'registry') &&
                    <TableCell align="right" component="div" className={classes.operatorCell}>
                        {application.registry}
                    </TableCell>}
                </>;


            const row = (
                <TableRow
                    key={application.uid}
                    className={classnames(classes.listItem, {
                        [classes.blinks]:
                            (application.status === CaaApplicationHoldStatuses.HOLD_MEETING_REQUEST) && isOperator(mySelf.roles) ||
                            (application.status === CaaApplicationHoldStatuses.HOLD_MEETING_REJECTED) &&  isCaaEditor(mySelf.roles)
                    })}
                    hover
                    component={Link}
                    to={{pathname: url}}
                >
                    <TableCell padding="checkbox" className={classes.counterCell} component="div">
                        {i + 1}.
                    </TableCell>

                    <TableCell padding="dense" scope="row" component="div">
                        {application.name}
                    </TableCell>
                    {
                        has(application, 'operator.lastName') &&
                        <TableCell padding="dense" scope="row" component="div">
                            {application.operator.lastName}
                        </TableCell>
                    }

                    {lastTuple}

                    {
                        !has(application, 'operator.lastName') &&
                        <TableCell padding="dense" scope="row" component="div">
                            <StatusField source="status"
                                         record={application}
                                         mini
                                         statusMappingFn={translateCaaStatusToTypo}
                                         iconGetter={getStatusIconColored}
                            />
                        </TableCell>
                    }

                </TableRow>
            );
            groupedApplications.rows.push(row);
        }
    }


    return groupedApplications;
}

const _renderGroupedApplicationsRows = (applications, group, classes, isMeetingRow = false, mySelf) => {
    const groupedApplications = {};
    groupedApplications.certs = _filterApplicationsToRows(applications, group, classes, (e) => e.kind === 'certs', isMeetingRow, mySelf);
    groupedApplications.drones = _filterApplicationsToRows(applications, group, classes, (e) => e.kind === 'drones', isMeetingRow, mySelf);
    return groupedApplications;
};

//TODO More button should link to missions table with group (mission status) attached
const MissionList = ({classes, missions, group, mySelf}) => {
    if(!missions || missions[group].length === 0) return null;

    const isOperator = (mySelf && mySelf.roles.indexOf('operator') !== -1);
    const groupedMissions = _renderGroupedMissionsRows(missions, group, isOperator, classes);
    return (
        <Table component="div">
        <TableHead component="div">
            <TableRow component="div">
                <TableCell padding="checkbox" component="div"/>
                {
                isOperator ?
                    <>
                        <TableCell padding="dense" component="div">
                            <TranslatableTypography content={'resources.missions.name'} options={{smart_count: 2}}/>
                        </TableCell>
                        <TableCell padding="dense" component="div"/>
                    </>
                    :
                    <>
                        <TableCell align="right" component="div" className={classes.missionGovCell}>
                            <TranslatableTypography content={'myroot.dashboard.ctrZonesHeader'} options={{smart_count: 2}}/>
                        </TableCell>
                        <TableCell align="right" component="div" className={classes.missionGovCell}>
                            <TranslatableTypography content={'resources.operators.name'} options={{smart_count: 2}}/>
                        </TableCell>
                        <TableCell align="right" component="div" className={classes.missionGovCell}>
                            <TranslatableTypography content={'resources.missions.fields.type'} options={{smart_count: 1}}/>
                        </TableCell>
                        <TableCell align="right" component="div" className={classes.missionGovCell}>
                            <TranslatableTypography content={'resources.missions.fields.start'} options={{smart_count: 1}}/>
                        </TableCell>
                    </>
                }
            </TableRow>
        </TableHead>
        <TableBody component="div">
            {groupedMissions.rows}
        </TableBody>
        <TableFooter component="div">
            {groupedMissions.areTrimmed &&
            <TableRow component="div">
                <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                <TableCell padding="dense" scope="row"  component="div"/>
                {!isOperator &&
                    <>
                        <TableCell padding="dense" scope="row"  component="div"/>
                        <TableCell padding="dense" scope="row"  component="div"/>
                    </>

                }
                <TableCell classes={{root: classes.footer}} component="div">
                    <Tooltip content='myroot.navigation.more'>
                        <IconButton aria-label="More" component={Link}
                                    to={{
                                        pathname: `/missions`,
                                        search: `?order=DESC&page=1&perPage=10&sort=${LINK_SORT}`,
                                        //TODO refactor repair lexical inaccuracy
                                        state: {
                                            filter: {
                                                status: group === 'pending' ? 'planned' : group
                                            }
                                        }
                                    }}
                        >
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            }
        </TableFooter>
    </Table>
    )
};

const ApplicationsList = ({classes, applications, group, mySelf}) => {
    if(!applications) return null;

    const groupedApplications = _renderGroupedApplicationsRows(applications, group, classes, false, mySelf);
    return (
        <>
            {groupedApplications.drones.rows.length > 0 &&
            <>
                <TranslatableTypography variant="title" content={'resources.drones.name'}
                                        options={{smart_count: 2}}/>
                <Table component="div">
                    <TableHead component="div">
                        <TableRow component="div">
                            <TableCell padding="checkbox" component="div"/>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.applications.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.operators.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell align="right" component="div">
                                <TranslatableTypography content={'resources.drones.fields.registry'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody component="div">
                        {groupedApplications.drones.rows}
                    </TableBody>
                    <TableFooter component="div">
                        {groupedApplications.drones.areTrimmed &&
                        <TableRow component="div">
                            <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell classes={{root: classes.footer}} component="div">
                                <Tooltip content='myroot.navigation.more'>
                                    <IconButton aria-label="More" component={Link}
                                                to={`drones?order=DESC&page=1&perPage=25&sort=status&sort=${LINK_SORT}`}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        }
                    </TableFooter>
                </Table>

            </>
            }
            {groupedApplications.certs.rows.length > 0 &&
            <>
                <br/><br/>
                <TranslatableTypography variant="title" content={'resources.certs.name'}
                                        options={{smart_count: 2}}/>
                <Table component="div">
                    <TableHead component="div">
                        <TableRow component="div">
                            <TableCell padding="checkbox" component="div"/>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.applications.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.operators.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody component="div">
                        {groupedApplications.certs.rows}
                    </TableBody>
                    <TableFooter component="div">
                        {groupedApplications.certs.areTrimmed &&
                        <TableRow component="div">
                            <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell classes={{root: classes.footer}} component="div">
                                <Tooltip content='myroot.navigation.more'>
                                    <IconButton aria-label="More" component={Link}
                                                to={`certs?order=DESC&page=1&perPage=25&sort=status&sort=${LINK_SORT}`}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        }
                    </TableFooter>
                </Table>
            </>
            }
        </>
    )
};

const ApplicationsMeetingsList = ({classes, applications, group, mySelf}) => {
    if(!applications) return null;

    const groupedApplications = _renderGroupedApplicationsRows(applications, group, classes, true, mySelf);
    return (
        <>
            {groupedApplications.drones.rows.length > 0 &&
            <>
                <TranslatableTypography variant="title" content={'resources.drones.name'}
                                        options={{smart_count: 2}}/>
                <Table component="div">
                    <TableHead component="div">
                        <TableRow component="div">
                            <TableCell padding="checkbox" component="div"/>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.applications.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.operators.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell align="right" component="div">
                                <TranslatableTypography content={'resources.applications.meetingDate'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody component="div">
                        {groupedApplications.drones.rows}
                    </TableBody>
                    <TableFooter component="div">
                        {groupedApplications.drones.areTrimmed &&
                        <TableRow component="div">
                            <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell classes={{root: classes.footer}} component="div">
                                <Tooltip content='myroot.navigation.more'>
                                    <IconButton aria-label="More" component={Link}
                                                to={`drones?order=DESC&page=1&perPage=25&sort=status&sort=${LINK_SORT}`}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        }
                    </TableFooter>
                </Table>

            </>
            }
            {groupedApplications.certs.rows.length > 0 &&
            <>
                <br/><br/>
                <TranslatableTypography variant="title" content={'resources.certs.name'}
                                        options={{smart_count: 2}}/>
                <Table component="div">
                    <TableHead component="div">
                        <TableRow component="div">
                            <TableCell padding="checkbox" component="div"/>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.applications.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.operators.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell align="right" component="div">
                                <TranslatableTypography content={'resources.applications.meetingDate'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody component="div">
                        {groupedApplications.certs.rows}
                    </TableBody>
                    <TableFooter component="div">
                        {groupedApplications.certs.areTrimmed &&
                        <TableRow component="div">
                            <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell classes={{root: classes.footer}} component="div">
                                <Tooltip content='myroot.navigation.more'>
                                    <IconButton aria-label="More" component={Link}
                                                to={`certs?order=DESC&page=1&perPage=25&sort=status&sort=${LINK_SORT}`}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        }
                    </TableFooter>
                </Table>
            </>
            }
        </>
    )
};

const ApplicationsSelectedMeetingsList = ({classes, applications, group, mySelf}) => {
    if(!applications) return null;

    const groupedApplications = _renderGroupedApplicationsRows(applications, group, classes, true, mySelf);
    return (
        <>
            {groupedApplications.drones.rows.length > 0 &&
            <>
                <TranslatableTypography variant="title" content={'resources.drones.name'}
                                        options={{smart_count: 2}}/>
                <Table component="div">
                    <TableHead component="div">
                        <TableRow component="div">
                            <TableCell padding="checkbox" component="div"/>
                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.applications.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>

                            <TableCell align="right" component="div">
                                <TranslatableTypography content={'resources.applications.meetingDate'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            {/*status cell*/}
                            <TableCell padding="dense" component="div">

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody component="div">
                        {groupedApplications.drones.rows}
                    </TableBody>
                    <TableFooter component="div">
                        {groupedApplications.drones.areTrimmed &&
                        <TableRow component="div">
                            <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell classes={{root: classes.footer}} component="div">
                                <Tooltip content='myroot.navigation.more'>
                                    <IconButton aria-label="More" component={Link}
                                                to={`drones?order=DESC&page=1&perPage=25&sort=status&sort=${LINK_SORT}`}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        }
                    </TableFooter>
                </Table>

            </>
            }
            {groupedApplications.certs.rows.length > 0 &&
            <>
                <br/><br/>
                <TranslatableTypography variant="title" content={'resources.certs.name'}
                                        options={{smart_count: 2}}/>
                <Table component="div">
                    <TableHead component="div">
                        <TableRow component="div">
                            <TableCell padding="checkbox" component="div"/>

                            <TableCell padding="dense" component="div">
                                <TranslatableTypography content={'resources.applications.name'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            <TableCell align="right" component="div">
                                <TranslatableTypography content={'resources.applications.meetingDate'}
                                                        options={{smart_count: 1}}/>
                            </TableCell>
                            {/*status cell*/}
                            <TableCell padding="dense" component="div">

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody component="div">
                        {groupedApplications.certs.rows}
                    </TableBody>
                    <TableFooter component="div">
                        {groupedApplications.certs.areTrimmed &&
                        <TableRow component="div">
                            <TableCell padding="checkbox" className={classes.counterCell} component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell padding="dense" scope="row" component="div"/>
                            <TableCell classes={{root: classes.footer}} component="div">
                                <Tooltip content='myroot.navigation.more'>
                                    <IconButton aria-label="More" component={Link}
                                                to={`certs?order=DESC&page=1&perPage=25&sort=status&sort=${LINK_SORT}`}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        }
                    </TableFooter>
                </Table>
            </>
            }
        </>
    )
};

const _getCardHeader = (classes, translate, entries, status, title) => {

    let total = (entries && entries.count)?entries.count: -1;
    let subcount = -1;
    if (entries) {
        if (entries[mapStatusToEntryGroupName(status)].total < 0) {
            //ugh, applications still passes whole list, not custom count
            subcount = entries[mapStatusToEntryGroupName(status)].data.length;
        } else {
            subcount = entries[mapStatusToEntryGroupName(status)].total;
        }
    }

    return (<CardHeader color={getStatusColorText(status)} stats icon>
        <CardIcon color={getStatusColorText(status)}>
            {getStatusIcon(status)}
        </CardIcon>
        <p className={classes.cardCategory}>{translate(title, {smart_count: 2})}</p>
        {/*hot fixing droneadmin issue #131*/}
        {/*<h3 className={classes.cardTitle}>*/}
        {/*    {subcount} / {total}*/}
        {/*</h3>*/}
    </CardHeader>)
};

const _getSelectedMeetingsCardHeader = (classes, translate, title) => {
    return <CardHeader color={'rose'} stats icon>
        <CardIcon color={'rose'}>
            <Icon>insert_invitation</Icon>
        </CardIcon>
        <p className={classes.cardCategory}>{translate(title, {smart_count: 2})}</p>
        <h3 className={classes.cardTitle}>

        </h3>
    </CardHeader>;
};

// TODO refactor with cardStylesCustom
const getInitialCardWidth = (dashboardDOM, width, uniqueName = null, configuration) => {
    let cardWidth;
    if(has(configuration[CONFIGURATION_KEY_DASH_CARDS], `${uniqueName}.size`)) cardWidth = configuration[CONFIGURATION_KEY_DASH_CARDS][uniqueName].size.width;
    else if(isWidthUp('xl', width)) cardWidth = dashboardDOM.clientWidth * 0.32;
    else if(isWidthUp('lg', width)) cardWidth = window.innerWidth * 0.36;
    else if(isWidthUp('md', width)) cardWidth = dashboardDOM.clientWidth * 1;
    else cardWidth = dashboardDOM.clientWidth * 0.32;
    return cardWidth;
};

const CustomizedCardPlain =
    ({classes, header, body, footer, width, uniqueName, configuration, setConfigurationKeyObject}) =>
    <DashboardContext.Consumer>
        {dashboardDOM =>
            <>
                <ResizableBox width={getInitialCardWidth(dashboardDOM.current, width, uniqueName, configuration)}
                              height={Infinity}
                              axis="x"
                              className={classes.card}
                              onResizeStop={
                                  (e, data) => setConfigurationKeyObject(CONFIGURATION_KEY_DASH_CARDS,
                                      {
                                        [uniqueName]: {size: data.size}
                                      })
                              }
                              draggableOpts={{disabled: isWidthDown('md', width)}}
                              >
                    <Card>
                        {header}
                        <CardBody>
                            {body}
                        </CardBody>
                        <CardFooter stats>
                            {footer}
                        </CardFooter>
                    </Card>
                </ResizableBox>
            </>
        }

    </DashboardContext.Consumer>
;

const CustomizedCard = compose(
    withWidth(),
    connect(
        state => ({configuration: state.configuration}),
        ({setConfigurationKeyObject})
    )

)(CustomizedCardPlain);


export const AcceptedMissionsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, missions, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                    header={
                        _getCardHeader(classes, translate, missions, MissionV2Statuses.ACCEPTED, 'resources.missions.status.accepted')
                    }
                    body={
                        <MissionList classes={classes}
                                     missions={missions}
                                     mySelf={mySelf}
                                     group={mapStatusToEntryGroupName(MissionV2Statuses.ACCEPTED)}/>
                    }
                    uniqueName={uniqueName}
        />
);


export const PendingMissionsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, missions, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                    header={
                        _getCardHeader(classes, translate, missions, MissionV2Statuses.PLANNED, 'resources.missions.status.pending')
                    }
                    body={
                        <MissionList classes={classes}
                                     missions={missions}
                                     mySelf={mySelf}
                                     group={mapStatusToEntryGroupName(MissionV2Statuses.PLANNED)}/>
                    }
                    uniqueName={uniqueName}
        />
);


export const RejectedMissionsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, missions, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                    header={
                        _getCardHeader(classes, translate, missions, MissionV2Statuses.REJECTED, 'resources.missions.status.rejected')
                    }
                    body={
                        <MissionList classes={classes}
                                     missions={missions}
                                     mySelf={mySelf}
                                     group={mapStatusToEntryGroupName(MissionV2Statuses.REJECTED)}/>
                    }
                    uniqueName={uniqueName}
        />
);

export const UnknownMissionsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, missions, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                    header={
                        _getCardHeader(classes, translate, missions, null, 'resources.missions.status.unknown')
                    }
                    body={
                        <MissionList classes={classes}
                                     missions={missions}
                                     mySelf={mySelf}
                                     group={mapStatusToEntryGroupName(null)}/>
                    }
                    uniqueName={uniqueName}
        />
);

export const CreatedMissionsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, missions, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, missions, MissionV2Statuses.CREATED, 'resources.missions.status.created')
                        }
                        body={
                            <MissionList classes={classes}
                                         missions={missions}
                                         mySelf={mySelf}
                                         group={mapStatusToEntryGroupName(MissionV2Statuses.CREATED)}/>
                        }
                        uniqueName={uniqueName}
        />
);

export const AcceptedApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.ACCEPTED, 'resources.applications.status.accepted')
                        }
                        body={
                            <ApplicationsList classes={classes}
                                              applications={applications}
                                              mySelf={mySelf}
                                              group={mapStatusToEntryGroupName(CaaApplicationStatuses.ACCEPTED)}/>
                        }
                        uniqueName={uniqueName}
        />
);


export const PendingApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.VERIFY, 'resources.applications.status.pending')
                        }
                        body={
                            <ApplicationsList classes={classes}
                                              applications={applications}
                                              mySelf={mySelf}
                                              group={mapStatusToEntryGroupName(CaaApplicationStatuses.VERIFY)}/>
                        }
                        uniqueName={uniqueName}
        />
);

export const PendingAgainApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.VERIFY_AGAIN, 'resources.applications.status.pendingAgain')
                        }
                        body={
                            <ApplicationsList classes={classes}
                                              applications={applications}
                                              mySelf={mySelf}
                                              group={mapStatusToEntryGroupName(CaaApplicationStatuses.VERIFY_AGAIN)}/>
                        }
                        uniqueName={uniqueName}
        />
);


export const RejectedApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.REJECTED, 'resources.applications.status.rejected')
                        }
                        body={
                            <ApplicationsList classes={classes}
                                              applications={applications}
                                              mySelf={mySelf}
                                              group={mapStatusToEntryGroupName(CaaApplicationStatuses.REJECTED)}/>
                        }
                        uniqueName={uniqueName}
        />
);

export const UnknownApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.CREATED, 'resources.applications.status.unknown')
                        }
                        body={
                            <ApplicationsList classes={classes}
                                              applications={applications}
                                              mySelf={mySelf}
                                              group={mapStatusToEntryGroupName(CaaApplicationStatuses.CREATED)}/>
                        }
                        uniqueName={uniqueName}
        />
);

export const RequestedMeetingsApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.HOLD_MEETING_REQUEST, 'resources.applications.status.meetingRequest')
                        }
                        body={
                            <ApplicationsMeetingsList classes={classes}
                                                      applications={applications}
                                                      mySelf={mySelf}
                                                      group={mapStatusToEntryGroupName(CaaApplicationStatuses.HOLD_MEETING_REQUEST)}/>
                        }
                        uniqueName={uniqueName}
        />
);

export const AcceptedMeetingsApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.HOLD_MEETING_ACCEPTED, 'resources.applications.status.meetingAccepted')
                        }
                        body={
                            <ApplicationsMeetingsList classes={classes}
                                                      applications={applications}
                                                      mySelf={mySelf}
                                                      group={mapStatusToEntryGroupName(CaaApplicationStatuses.HOLD_MEETING_ACCEPTED)}/>
                        }
                        uniqueName={uniqueName}
        />
);

export const RejectedMeetingsApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getCardHeader(classes, translate, applications, CaaApplicationStatuses.HOLD_MEETING_REJECTED, 'resources.applications.status.meetingRejected')
                        }
                        body={
                            <ApplicationsMeetingsList classes={classes}
                                                      applications={applications}
                                                      mySelf={mySelf}
                                                      group={mapStatusToEntryGroupName(CaaApplicationStatuses.HOLD_MEETING_REJECTED)}/>
                        }
                        uniqueName={uniqueName}
        />
);


export const SelectedMeetingsApplicationsCard = withStyles(cardStyle)
(
    ({classes = {}, translate, applications, mySelf, uniqueName}) =>
        <CustomizedCard classes={classes}
                        header={
                            _getSelectedMeetingsCardHeader(classes, translate, 'resources.applications.meeting')
                        }
                        body={
                            <ApplicationsSelectedMeetingsList classes={classes}
                                                              applications={applications}
                                                              mySelf={mySelf}
                                                              group={CaaApplicationHoldStatuses}/>
                        }
                        uniqueName={uniqueName}
        />
);
