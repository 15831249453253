import React from 'react';
import { Filter, Responsive, SimpleList, Edit, Create, Datagrid, ReferenceField, TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, Show, SimpleShowLayout, ShowButton, FunctionField , required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {dateTimeFormatter} from "../../utils/momentHelpers";

//some strange fakap with rendering of the fake json ... using delay fixes

import Delay from 'react-delay';
import {canUpdate} from "../../logic/MyRestConfig";
import BasicListActions from "../MyBasicListActions"
import BasicShowActions from "../MyBasicShowActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import {MyResourceListTitle} from "../MyTitle";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";

//some issues with permissions... not using anyway now only view allowed
export const CertificateTypesList = ({permissions, ...props}) => (
    //Required for json server data provider
    <Delay
        wait={100}
    >
        <MyTransitionDecorator timeout={700} classNames={"menucard"} unmountOnExit>
            <List {...props}
                  bulkActions={false}
                  actions={<BasicListActions/>}
                  title={<MyResourceListTitle resource={'certTypes'} />}
                  pagination={<MyPagination/>}
            >

                <Responsive
                    // small={
                    //     <SimpleList
                    //         primaryText={record => record.cert_code}
                    //     />
                    // }
                    small={
                        <Datagrid>
                            <TextField source="id"/>
                            <TextField source="country"/>
                            <TextField source="cert_code"/>
                            <ShowButton/>
                        </Datagrid>
                    }
                />

            </List>
        </MyTransitionDecorator>
    </Delay>

);

export const CertificateTypesShow = ({permissions, ...props}) => (
    <Delay
        wait={100}
    >
        <Show {...props}
          actions={<BasicShowActions/>}
        >
            <SimpleShowLayout>

                <TextField source="country"/>
                <TextField source="cert_code"/>
                <FunctionField source="fields"
                               render={record => {
                                   console.log(record);
                                   return (JSON.stringify(record.fields));
                               }}
                />
            </SimpleShowLayout>
        </Show>
    </Delay>

);

//no fields support now (json)
//fixme validation
export const CertificateTypesCreate = ({permissions, ...props}) => (
    <Delay
        wait={100}
    >
        <Create {...props}
            /* undoable={false} -  no undo in create anyway */
        >
            <SimpleForm>

                <TextInput source="country" validate={[ required, minLength(2), maxLength(2) ]} />
                <TextInput source="cert_code" validate={[ required, minLength(2), maxLength(15) ]}/>
                <TextInput source="fields" />

            </SimpleForm>
        </Create>
    </Delay>

);

export const CertificateTypesEdit = ({permissions, ...props}) => (
    <Delay
        wait={100}
    >
        <Edit {...props}
              undoable={false}
        >
            <SimpleForm>
                <DisabledInput source="id"/>

                <TextInput source="country" validate={[required(), minLength(2), maxLength(2)]}/>
                <TextInput source="cert_code" validate={[required(), minLength(2), maxLength(15)]}/>
            </SimpleForm>
        </Edit>
    </Delay>
);


