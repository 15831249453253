import dashboardStyle from "./dashboardStyle";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";
const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);

export const cardStylesCustom = theme => ({
    card: {
        maxWidth: "100%",
        marginRight: "1em",
        willChange: "transform",

        [theme.breakpoints.up('sm')]: {
            minWidth: "100%",
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: "36vw",
        },
        [theme.breakpoints.up('xl')]: {
            minWidth: "32%",
        },
    },
    body: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    listItem: {
        paddingBottom: "1px",
        paddingTop: "0"
    },
    footer: {
        textAlign: "right",
        '&:last-child': {
            paddingRight: "0"
        },
    },
    counterCell: {
        width: "8%"
    },
    operatorCell: {
        width: "40%"
    },
    missionGovCell: {
        padding: 0
    },
    blinks: {
        animation: "blinkSlow 2s linear infinite",
    },
    '@keyframes blinkSlow': {
        '50%': {
            backgroundColor: theme.palette.primary.light,
        }
    },
    rowKSID: {
        background: `repeating-linear-gradient(
                    -45deg,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 10px,
              rgba(0, 0, 255, 0.1) 10px,
              rgba(0, 0, 255, 0.1) 20px
                    )`,           
        textShadow: '-1px -1.5px 0 #FFF, 1px -1.5px 0 #FFF, -1px 1.5px 0 #FFF, 1px 1.5px 0 #FFF',
        '&:after': {
            content: "'KSID'",
            color: "#FFF",
            backgroundColor: "rgb(0, 0, 255)",
            padding: "0 2px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            textShadow: "none",
            fontWeight: "800",
            position: "absolute",
            right: "3ch"
        }
    },
    // notice that 112 row style on dashboard quite differs from mission data grid style
    ...(MISSION_112_ENABLED && {
        row112: {
            background: `repeating-linear-gradient(
                        -45deg,
                        #0000,
                        #0000 10px,
                        #F001 10px,
                        #F001 20px)`,
            textShadow: '-1px -1.5px 0 #FFF, 1px -1.5px 0 #FFF, -1px 1.5px 0 #FFF, 1px 1.5px 0 #FFF',
            '&:after': {
                content: "'112'",
                color: "#FFF",
                backgroundColor: "#F00",
                padding: "0 2px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "clip",
                textShadow: "none",
                fontWeight: "800",
                position: "absolute",
                right: "3ch"
            }
        },
        rowKSID112: {
            background: `repeating-linear-gradient(
                        -45deg,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0) 10px,
                rgba(212, 0, 255, 0.1) 10px,
                rgba(212, 0, 255, 0.1) 20px
                        )`,           
            textShadow: '-1px -1.5px 0 #FFF, 1px -1.5px 0 #FFF, -1px 1.5px 0 #FFF, 1px 1.5px 0 #FFF',
            '&:after': {
                content: "'KSID112'",
                color: "#FFF",
                backgroundColor: "rgb(212, 0, 255)",
                padding: "0 2px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "clip",
                textShadow: "none",
                fontWeight: "800",
                position: "absolute",
                right: "3ch"
            }
        }
    }),
    ...dashboardStyle
});
