import Typography from "@material-ui/core/Typography";
import {LongTextInput} from "ra-ui-materialui";
import {required as requiredFn, translate} from 'react-admin'
import React from "react";
import {RESOURCES} from "../../../logic/MyRestConfig";
import {validateAllLangsInput} from "../../../utils/validationHelpers";


const options = {
    onDrag: (e) => {
        e.preventDefault()
    }
};

const parseEmptyToNull = (val) => {
    if (val === '') return null;
    else return val;
};

export const AcmTermsTextInput = translate(
    ({source, rpaType, ...rest}) =>
{
    const validate = rpaType === 'def' ?
        [
            requiredFn(`resources.${RESOURCES.ACM_RULES}.validation.defaultsEmpty`),
        ]
        :
        [
            validateAllLangsInput(rpaType, source, `resources.${RESOURCES.ACM_RULES}.validation.onlyOneTerms`),

        ];

    return (
        <div style={{maxHeight: "30vh", overflow: "auto"}}>
            <Typography component={'div'}>
                <LongTextInput label={`resources.${RESOURCES.ACM_RULES}.fields.etc.terms.description.${source}`}
                               source={source}
                               style={{marginRight: 0}}
                               options={options}
                               validate={validate}
                               parse={parseEmptyToNull}
                />
            </Typography>
        </div>
    )
});
