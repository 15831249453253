import {CREATE_ACM_RULE, LOAD_ACM_RULES_CTR, SAVE_ACM_RULES_RPA, ACM_RULES_TERMS_DIALOG_CALLBACK} from "../constants/AcmRulesConstants";


export const loadAcmRulesCTR = (payload) => {
    return ({
        type: LOAD_ACM_RULES_CTR,
        payload,
    });
};

export const saveAcmRulesRPA = (payload) => {
    return ({
        type: SAVE_ACM_RULES_RPA,
        payload,
    });
};

export const createAcmRule = (payload) => {
    return ({
        type: CREATE_ACM_RULE,
        payload,
    });
};

export const callbackFromAcmTermsDialog = (payload) => {
    return ({
        type: ACM_RULES_TERMS_DIALOG_CALLBACK,
        ...payload,
    });
};
