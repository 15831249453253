
//dev note: 'application' here is not about the *the app* itself ,
// but certs and drones submissions statuses
// ...probably should be renamed/refactored to avoid confusion

import {
    CREATE_APPLICATION,
    SAVE_APPLICATION_STATUS,
    SAVE_APPLICATION_STATUS_AND_UPDATE
} from "../constants/ApplicationsConstants";



export const saveApplicationStatus = (payload) => {

    console.log('saveApplicationStatus', payload);
    return ({
        type: SAVE_APPLICATION_STATUS,
        payload,
    });
};


export const saveApplicationStatusWithUpdate = (payload) => {

    console.log('saveApplicationStatusWithUpdate', payload);
    return ({
        type: SAVE_APPLICATION_STATUS_AND_UPDATE,
        payload,
    });
};

export const createApplication = (payload) => {

    console.log('create application', payload);
    return ({
        type: CREATE_APPLICATION,
        payload,
    });
};
