import {PUBLISH_NOTAM} from "../constants/NotamsConstants";

export const publishNotam = (payload) => {

    console.log('publish notam', payload);
    return ({
        type: PUBLISH_NOTAM,
        payload,
    });
};
