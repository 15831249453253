import * as React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import {translate, FormDataConsumer} from 'react-admin';
import compose from 'recompose/compose';
import {connect} from "react-redux";
import {CardHeader, withStyles} from "@material-ui/core";
import CardBody from "./dashboard/CardBody";
import SanitizedMuiButton from "./SanitizedMuiButton";
import {isSuperAdmin} from "../logic/MyRestConfig";
import * as PropTypes from 'prop-types';


const styles = theme => ({

    root: {
        backgroundColor: theme.palette.colorCircle.third
    }
});

class AdminTools extends React.Component {

    state = {
        action: ''
    };

    _handleChange = (event) => {
        this.setState({
            action: event.target.value
        })
    };

    _handleClick = (userData) => {
        const {action} = this.state;
        if (action !== '' || !userData) {
            this.props.dispatchAdminAction(action, {userData})
        }
    };

    render() {
        const {action} = this.state;
        const {translate, classes, permissions, scope, ...rest} = this.props;

        if (!permissions || !isSuperAdmin(permissions.roles)) return null;

        return (
            <FormDataConsumer {...rest}>
                {({
                      formData
                  }) =>
                    <Card className={classes.root}>
                        <CardHeader title={translate('myroot.admin.tools')}
                                    titleTypographyProps={{variant: 'body2'}}
                        />
                        <CardBody>
                            <TextField
                                select
                                label={translate('myroot.admin.actionsLabel')}
                                value={action}
                                onChange={this._handleChange}
                                margin="normal"
                            >
                                {scope.map(adminAction =>
                                    <MenuItem key={adminAction.id} value={adminAction.id}>
                                        {translate(adminAction.label)}
                                    </MenuItem>
                                )}
                            </TextField>
                            <SanitizedMuiButton
                                variant='raised'
                                color='primary'
                                onClick={() => this._handleClick(formData)}
                                disabled={action === ''}
                            >
                                {translate('myroot.action.execute')}
                            </SanitizedMuiButton>
                        </CardBody>
                    </Card>
                }
            </FormDataConsumer>
        )
    }
}

AdminTools.propTypes = {
    scope: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAdminAction: (type, payload) => dispatch({type, payload})
});

const enhance = compose(
    withStyles(styles),
    connect(
        null,
        mapDispatchToProps
    ),
    translate,
);

export default enhance(AdminTools)
