import React from 'react'
import {fetchZoneTypesData} from "../../../utils/zoneTypeHelpers";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {LongTextInput} from "ra-ui-materialui";
import {translate} from 'react-admin';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {change} from "redux-form";
import {bindActionCreators} from "redux";
import compose from 'recompose/compose';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {find} from "lodash";


class ZoneDescriptionInputBase extends React.Component {

    state = {
        zoneTypesFetched: [],
        currentZoneDesciption: null,
        isForcingTemplate: false
    };

    componentDidMount() {

            fetchZoneTypesData(
                (zoneTypes) => {
                    this.setState({zoneTypesFetched: zoneTypes});

                    if (this.props.forceTemplate) {
                        this.setState({
                            isForcingTemplate: true
                        });
                        this._updateZoneTypeDescription(this.props, {forceEquality: true});
                    }
                }
            );
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(!shallowEqual(this.props.forceTemplate, nextProps.forceTemplate)) {
            this.setState({
                isForcingTemplate: nextProps.forceTemplate
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.isForcingTemplate) {
            this._updateZoneTypeDescription(prevProps);
        }
    }

    _updateZoneTypeDescription(prevProps, {forceEquality} = {}) {
        if (!shallowEqual(prevProps.zoneTypeName, this.props.zoneTypeName) || forceEquality) {

            const {zoneTypesFetched} = this.state;
            const {zoneTypeName, change, formName, isEdit} = this.props;

            const model = find(zoneTypesFetched, {'name': zoneTypeName});
            const {description = ""} = !!model ? model : {};

            if (!!model) {
                if(!isEdit) {
                    change(formName, 'description', description);
                }
            }

            this.setState({
                currentZoneDesciption: description
            })
        }
    }

    _normalizer = (description) => (value = "", previousValue = "") => {
        if(!value.startsWith(description) && previousValue.startsWith(description)) {
            return previousValue;
        }
        else if(!previousValue.startsWith(description)){
            return description;
        }
        else {
            return value;
        }
    };

    _handleCheckboxChange = event => {
        const value = event.target.checked;

        if(value) {
            this._updateZoneTypeDescription(this.props, {forceEquality: true});
        }

        this.setState({
            isForcingTemplate: value
        })
    };

    render() {
        const {currentZoneDesciption, isForcingTemplate} = this.state;
        const {showTemplateSwitch, formName, change, forceTemplate, zoneTypeName, className, translate, isEdit, ...props} = this.props;

        return (
            <>
                {
                    showTemplateSwitch &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isForcingTemplate}
                                onChange={this._handleCheckboxChange}
                            />
                        }
                        label={translate('resources.zones.useDescriptionTemplate')}
                    />

                }
                <LongTextInput
                    source="description.pl"
                    normalize={
                        isForcingTemplate && currentZoneDesciption ?
                            this._normalizer(currentZoneDesciption.pl)
                            :
                            undefined
                    }
                    {...props}
                />
                <LongTextInput source="description.en"
                               normalize={
                                   isForcingTemplate && currentZoneDesciption ?
                                       this._normalizer(currentZoneDesciption.en)
                                       :
                                       undefined
                               }
                               {...props}
                />
            </>
        )
    }
}

ZoneDescriptionInputBase.propTypes = {
    showTemplateSwitch: PropTypes.bool,
    zoneTypeName: PropTypes.string,
    forceTemplate: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    isEdit: PropTypes.bool
};

export const ZoneDescriptionInput = compose(
    translate,
    connect(
        null,
        dispatch => ({
            change: bindActionCreators(change, dispatch),
        })
    )
)(ZoneDescriptionInputBase);
