import React from 'react';
import { AutocompleteInput, Filter, Responsive, SimpleList, Edit, Create, Datagrid,
    TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, RadioButtonGroupInput, SelectArrayInput, TextInput, EmailField, EmailInput, BooleanInput,  SimpleShowLayout, Show, ShowButton, FunctionField, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {dateFormatter, dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';
import {canUpdate} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import {MyResourceListTitle} from "../MyTitle";
import {SaveOnlyToolbar} from "../MyBasicEditToolbar";

/*
UnitGov  20.04.18
  {
    created timestamp with time zone DEFAULT now(),
    uid uuid NOT NULL DEFAULT gen_random_uuid(),
    role text COLLATE pg_catalog."default",
    govid uuid NOT NULL,
    unitid uuid NOT NULL,
    CONSTRAINT unitgov_pkey PRIMARY KEY (uid),
    CONSTRAINT gov_fk FOREIGN KEY (govid)
        REFERENCES public.gov (uid) MATCH SIMPLE
        ON UPDATE NO ACTION
        ON DELETE NO ACTION,
    CONSTRAINT unit_fk FOREIGN KEY (unitid)
        REFERENCES public.unit (uid) MATCH SIMPLE
        ON UPDATE NO ACTION
        ON DELETE NO ACTION
  }
*/

const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];


//fixme -> should support roles, and be visible ONLY for superadmin
const rolesChoices = [
    {id: 'droneadmin', name: 'SUPERADMIN'}, //as in the acm
    {id: 'manager', name: 'Manager'},
    {id: 'editor', name: 'Editor'},
    {id: 'viewer', name: 'Viewer'},
];

const UnitGovFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput label="Search" source="q" alwaysOn />*/}
        <ReferenceInput label="Unit"
                        source="unitId"
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={null}
                        reference="units">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Email"
                        source="govId"
                        sort={{field: 'email', order: 'ASC'}}
                        perPage={null}
                        reference="govs">
            <SelectInput optionText="email" />
        </ReferenceInput>
    </Filter>
);

export const UnitGovsList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureUnitGovsList {...props}/>
    </MyTransitionDecorator>
);

export const PureUnitGovsList = ({permissions, ...props}) => (
    <List {...props}
          //title="!All! UnitGovs"
          title={<MyResourceListTitle resource={'unitGovs'}/>}
          sort={{ field: 'created', order: 'DESC' }}
          filters={<UnitGovFilter/>}

          bulkActions={false}
          actions={<BasicListActions />}
          pagination={<MyPagination/>}

    >
        <Responsive
            // small={
            //     <SimpleList
            //         primaryText={record => record.key}
            //         secondaryText={record =>
            //             permissions === 'test' ? record.status : null}
            //     />
            // }
            small={
                <Datagrid>
                    <MyTextField source="created"
                                 format={dateTimeFormatter} />
                    <TextField source="role" />
                    <ReferenceField source="govId"
                                    sort={{field: 'email', order: 'ASC'}}
                                    label={'resources.govs.menu'}
                                    reference="govs">
                        <TextField source="email" />
                    </ReferenceField>
                    <ReferenceField source="unitId"
                                    sort={{field: 'name', order: 'ASC'}}
                                    label={'resources.units.menu'}
                                    reference="units">
                        <TextField source="name" />
                    </ReferenceField>
                    {canUpdate(props.resource, permissions) && <EditButton />}
                    {!canUpdate(props.resource, permissions) && <ShowButton />}
                </Datagrid>
            }
        />

    </List>
);

export const UnitGovsShow = ({permissions, ...props}) => (
    <Show {...props}
      actions={<BasicShowActions/>}
    >
        <SimpleShowLayout>
            <h5>'stub!'</h5>

            <TextField label='id'
                       source="id" />

        </SimpleShowLayout>
    </Show>
);

export const UnitGovsCreate = ({permissions, ...props}) => (
    <Create {...props}
            /* undoable={false} -  no undo in create anyway */
            title={'resources.unitGovs.create'}
    >
        <SimpleForm // form={'unit-gov-create-form'} // must be default
                    defaultValue={{}}
        >
            <ReferenceInput label={'resources.units.menu'}
                            source="unitId"
                            reference="units"
                            perPage={null}
                            sort={{ field: 'name', order: 'ASC' }}
                            allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label={'resources.govs.menu'}
                            source="govId"
                            reference="govs"
                            perPage={null}
                            sort={{ field: 'email', order: 'ASC' }}
                            allowEmpty>
                <SelectInput optionText="email" />
            </ReferenceInput>
            <SelectInput label={'resources.unitGovs.fields.role'}
                         source="role"
                         validate={defaultRequired}
                         choices={rolesChoices}/>
        </SimpleForm>
    </Create>
);

export const UnitGovsEdit = ({permissions, ...props}) => (
    <Edit {...props}
          undoable={false}
          actions={<BasicEditActions />}
    >
        <SimpleForm // form={'unit-gov-edit-form'} // must be default
                    defaultValue={{}}
                    toolbar={<SaveOnlyToolbar/>}
        >
            <DisabledInput source="id"
                           fullWidth />
            <ReferenceInput label={'resources.units.menu'}
                            source="unitId"
                            reference="units"
                            perPage={null}
                            sort={{ field: 'name', order: 'ASC' }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label={'resources.govs.menu'}
                            source="govId"
                            reference="govs"
                            perPage={null}
                            sort={{ field: 'email', order: 'ASC' }}
            >
                <SelectInput optionText="email" />
            </ReferenceInput>
            <SelectInput label={'resources.unitGovs.fields.role'}
                         source="role"
                         validate={defaultRequired}
                         choices={rolesChoices}/>
        </SimpleForm>
    </Edit>
);
