import React from 'react';
import {
    Datagrid,
    Filter,
    FormDataConsumer,
    FunctionField,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
} from 'react-admin';
import {dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import MyIlikeSearch from "./MyIlikeSearch";
import {getSubjectResourceByAction, getUserResource, getUserSource} from "../../utils/eventHelpers";
import Labeled from "ra-ui-materialui/lib/input/Labeled";
import EventsStreamButton from "../EventsStreamButton";
import {MyReferenceField as ReferenceField} from "../MyReferenceField";
import ExtendedUserSearch from "./MyExtendedUserSearch";
import {withStyles} from "@material-ui/core";

const defaultSort = {field: 'created', order: 'DESC'};

const EventsFilter = (props) => {
    return (
        <Filter {...props}>
            <ExtendedUserSearch
                source={getUserSource(props.resource)}
                reference={getUserResource(props.resource)}
                inputStyles={{width: "439px"}}
            />
            <MyIlikeSearch source="action"
            />
            <MyIlikeSearch source="subject"
            />
        </Filter>
    )
};

export const EventsList = ({permissions, ...props}) => (
    <List {...props}
          title={`resources.${props.resource}.menu`}
          bulkActions={false}
          actions={
              <BasicListActions>
                  <EventsStreamButton resource={props.resource}/>
              </BasicListActions>
          }
          pagination={<MyPagination/>}
          sort={defaultSort}
          filters={<EventsFilter/>}
    >
        <Datagrid>
            <MyTextField source="created"
                         format={dateTimeFormatter}/>
            <ReferenceField source={getUserSource(props.resource)}
                            reference={getUserResource(props.resource)}
                            linkType={'show'}>
                <FunctionField render={
                    (record) => {
                        if (record.firstName && record.lastName) return `${record.firstName} ${record.lastName}`;
                        else if (record.email) return `${record.email}`;
                        else return record.uid;
                    }
                }/>
            </ReferenceField>
            <TextField source="action"/>

            <FormDataConsumer source='subject'>
                {({formData: record, dispatch, ...rest}) => {
                    const targetResource = getSubjectResourceByAction(record.action, props.resource);
                    if (record.subject && targetResource) {
                        return (
                            <ReferenceField source="subject"
                                            reference={targetResource}
                                            linkType={'show'}
                                            {...rest}
                            >
                                <TextField source="uid"/>
                            </ReferenceField>
                        )
                    } else return (<TextField source='subject' {...rest}/>)
                }
                }
            </FormDataConsumer>

            <ShowButton/>

        </Datagrid>
    </List>
);

const showStyles = {
    diff: {
        overflow: 'auto',
        maxWidth: '100%'
    }
};

export const EventsShow = withStyles(showStyles)(
    ({permissions, classes,  ...props}) => (
    <Show {...props}
          actions={<BasicShowActions/>}
    >
        <SimpleShowLayout>
            <MyTextField source="created"
                         format={dateTimeFormatter}/>
            <Labeled label={`resources.${props.resource}.fields.${getUserSource(props.resource)}`}>
                <ReferenceField source={getUserSource(props.resource)}
                                reference={getUserResource(props.resource)}
                                linkType={'show'}
                >
                    <FunctionField render={
                        (record) => {
                            if (record.firstName && record.lastName) return `${record.firstName} ${record.lastName}`;
                            else if (record.email) return `${record.email}`;
                            else return record.uid;
                        }
                    }/>
                </ReferenceField>
            </Labeled>

            <TextField source="action"/>
            <Labeled label={`resources.${props.resource}.fields.subject`}>
                <FormDataConsumer>
                    {({formData: record, dispatch, ...rest}) => {
                        const targetResource = getSubjectResourceByAction(record.action, props.resource);
                        if (record.subject && targetResource) {
                            return (
                                <ReferenceField source="subject"
                                                reference={targetResource}
                                                linkType={'show'}
                                                {...rest}
                                >
                                    <TextField source="uid"/>
                                </ReferenceField>
                            )
                        } else return (<TextField source='subject' {...rest}/>)
                    }
                    }
                </FormDataConsumer>
            </Labeled>

            <FunctionField source="diff"
                           render={record => (
                               <pre>
                               {JSON.stringify(record.diff, null, 2)}
                               </pre>
                           )}
                           className={classes.diff}
            />
        </SimpleShowLayout>
    </Show>
));