import React from 'react';
import {
    AutocompleteInput,
    Filter,
    Responsive,
    SimpleList,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    DisabledInput,
    LongTextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    RadioButtonGroupInput,
    SelectArrayInput,
    TextInput,
    EmailField,
    EmailInput,
    BooleanInput,
    SimpleShowLayout,
    Show,
    ShowButton,
    FunctionField,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    translate, Link
} from 'react-admin';
import {dateFormatter, dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';
import {canUpdate} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";

import Icon from '@material-ui/core/Icon';
import {ZoneLegsV2Statuses} from "../../../LogicV1Redux/constants/ZoneLegsV2Enums";

import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
/*
ZoneLegs  20.04.18
  {
  created timestamp with time zone DEFAULT now(),
  uid uuid NOT NULL DEFAULT gen_random_uuid(),
  legid uuid NOT NULL,
  zoneid uuid NOT NULL,
  status text,
  CONSTRAINT zoneleg_pkey PRIMARY KEY (uid),
  CONSTRAINT leg_fk FOREIGN KEY (legid)
      REFERENCES public.leg (uid) MATCH SIMPLE
      ON UPDATE CASCADE ON DELETE CASCADE,
  CONSTRAINT zone_fk FOREIGN KEY (zoneid)
      REFERENCES public.zone (uid) MATCH SIMPLE
      ON UPDATE CASCADE ON DELETE CASCADE
  }
*/

const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];



//temp
const zoneLegsStatuses = [
    {id: 'accepted', name: 'Accepted'},
    {id: 'rejected', name: 'Rejected'},
];

//temp.. especially 'other'
//almost duplicate to merge/unify in missions.jsx!

export function zoneLegsStatusesCounter (zoneLegsArr) {
    let others = 0, accepts = 0, rejects = 0;

    zoneLegsArr.forEach((zoneLegRecord) => {
        switch (zoneLegRecord.status) {
            case ZoneLegsV2Statuses.ACCEPTED:
                accepts++;
                break;
            case ZoneLegsV2Statuses.REJECTED:
                rejects++;
                break;
            default:
                others++;
        }
    });

    console.log('zoneLegsStatusesCounter', accepts, rejects, others);

    return {accepts, rejects, others};
}

const iconStyle = {fontSize:"16px", paddingRight:"6px", paddingLeft:"6px", lineHeight: "130%"};
//https://material-ui.com/style/icons/

//almost duplicate to merge/unify in missions.jsx!
export const zoneLegsCntFormatter = (arr) => {
    if (!arr && !Array.isArray(arr)) {
        console.warn ('wrong zoneLegsCntFormatter param', arr);
        return null;
    }
    console.log('zoneLegsCntFormatter', arr);
    const {accepts, rejects, others} = zoneLegsStatusesCounter(arr);
    return (
        <span>
            <span style={{color:"orange"}}><Icon style={iconStyle}>warning</Icon>{others}</span>
            <span style={{color:"green"}}><Icon style={iconStyle}>thumb_up</Icon>{accepts}</span>
            <span style={{color:"red"}}><Icon style={iconStyle}>thumb_down</Icon>{rejects}</span>
        </span>
    );
};

//true component
//
export const ZoneLegsCntFormatter = ({record}) => {
    return zoneLegsCntFormatter(record.zoneLeg || []);
};


const ZoneLegsFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput label="Search" source="q" alwaysOn />*/}
        <ReferenceInput label="Unit" source="unitId" reference="units">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const ZoneLegsList = ({permissions, ...props}) => (
    <List {...props}
          title="ZoneLegs"
          sort={{ field: 'created', order: 'DESC' }}
          filters={<ZoneLegsFilter/>}
          bulkActions={false}
          actions={<BasicListActions />}
          pagination={<MyPagination/>}

    >
        <Responsive
            // small={
            //     <SimpleList
            //         primaryText={record => record.key}
            //         secondaryText={record =>
            //             permissions === 'test' ? record.status : null}
            //     />
            // }
            small={
                <Datagrid>
                    <MyTextField format={dateTimeFormatter}
                                 label={'resources.zoneLegs.fields.created'}
                                 source="created"/>
                    <TextField label={'resources.zoneLegs.fields.status'}
                               source="status" />
                    <ReferenceField label={'resources.zones.menu'}
                                    source="zoneId"
                                    reference="zones">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField label={'resources.legs.menu'}
                               source="legId" />
                    {canUpdate(props.resource, permissions) && <EditButton />}
                    {!canUpdate(props.resource, permissions) && <ShowButton />}
                </Datagrid>
            }
        />

    </List>
);

export const ZoneLegsShow = ({permissions, ...props}) => (
    <Show {...props}
      actions={<BasicShowActions/>}
    >
        <SimpleShowLayout>
            <h5>'stub!'</h5>

            <TextField label='id'
                       source="id" />

        </SimpleShowLayout>
    </Show>
);

export const ZoneLegsCreate = ({permissions, ...props}) => {
    console.error('no manual create for zone legs!')
};

export const ZoneLegsEdit = ({permissions, ...props}) => (
    <Edit {...props}
          undoable={false}
          actions={<BasicEditActions />}
    >
        <SimpleForm // form={'zone-leg-edit-form'} // must be default
                    defaultValue={{}}
        >
            <DisabledInput source="id"
                           fullWidth />
            <SelectInput label={'resources.zoneLegs.status'}
                         source="status"
                         //validate={defaultRequired}
                         choices={zoneLegsStatuses}/>
        </SimpleForm>
    </Edit>
);