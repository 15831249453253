import axios from 'axios';
import React from 'react';
import ArchiveIcon from '@material-ui/icons/Archive';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import StoreProvider from "../../MyStoreProvider";
import {throwLoopbackError} from "../../utils/axiosLoopbackErrors"
import Configs from "../../../Configs"

class DronesAndCertsSortable extends React.Component {
    constructor(props) {
        super();
        this.state = {
            orderDrones: false, // sort order is DESC when false and ASC when true
            drones: props.drones,
            translate: props.translate,
            operatorid: props.operatorid,
            store: StoreProvider.getStore(),
            dronesData: null,
        };
    };

    getDronesData = (field = 'name', order = 'ASC') => {
        axios.get(`${Configs.apiBaseV2}/Govs/drones?filter={"where":{"operatorid": "${this.state.operatorid}"}, "fields":["uid", "name", "status","registry", "active"], "order":["${field} ${order}"]}`,
            { headers: { authorization: this.state.store.getState().myAuth.token } }).then((response) => {

                this.setState({ dronesData: response.data, orderDrones: !this.state.orderDrones })
                return response.data
            }).catch((err) => {
                throwLoopbackError(err)
            })


    }


    getData = () => {
        this.getDronesData();
       
    }

    componentDidMount() {
        this.getData()
    }

    sortData = (field, resource) => {
        if (resource === 'drones') {
            this.getDronesData(field, this.state.orderDrones ? 'DESC' : 'ASC')
        }
    }

    render() {

        if (this.state.drones && this.state.dronesData) {
            return (
                <div>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <Tooltip
                                    title={this.state.translate('myroot.action.sortBy')}
                                    placement='left'
                                    enterDelay={300}>
                                    <TableCell key={1} align="right" >

                                        <TableSortLabel

                                            onClick={() => { this.sortData('name', 'drones') }}

                                        >
                                            {this.state.translate('resources.drones.fields.name')}

                                        </TableSortLabel>
                                    </TableCell>
                                </Tooltip>
                                <TableCell align="right">
                                    <TableSortLabel
                                        onClick={() => { this.sortData('registry', 'drones') }}
                                        direction='asc'
                                    >
                                        {this.state.translate('resources.drones.fields.registry')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right"><TableSortLabel

                                    onClick={() => { this.sortData('status', 'drones') }}
                                    direction='asc'
                                >
                                    {this.state.translate('resources.applications.verification')}
                                </TableSortLabel></TableCell>
                                <TableCell align="right"><TableSortLabel

                                    onClick={() => { this.sortData('active', 'drones') }}
                                    direction='asc'
                                >
                                    {this.state.translate('resources.applications.archive')}
                                </TableSortLabel></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.dronesData.map(row => (
                                <TableRow key={row.uid}>
                                    <TableCell component="th" scope="row">
                                        <a style={{color: 'orange'}} href={`#/drones/${row.uid}/show`}>{row.name}</a>
                                    </TableCell>
                                    <TableCell align="right">{row.registry}</TableCell>
                                    <TableCell align="right">{row.status}</TableCell>
                                    <TableCell align="right">{row.active ? '' : <ArchiveIcon/> }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            );
        }  else {
            return (<div></div>)
        }
    }
}

export default DronesAndCertsSortable;