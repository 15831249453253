import React from "react";
import DataProvider from "../../../MyDataProvider";
import {RESOURCES} from "../../../logic/MyRestConfig";
import PropTypes from 'prop-types';
import {Datagrid, TextField} from "ra-ui-materialui";
import {isEmpty, keyBy} from "lodash";
import MyTextField from "../../MyTextField";
import {FormDataConsumer} from 'react-admin';
import {formatHourSlot} from "../../../utils/acmHelpers";
import {notamMoment} from "../../../utils/notamDiurnal";
import {InfoText} from "../../InfoText";
import TranslatableTypography from "../../TranslatableTypography";
import {withStyles} from "@material-ui/core";
import {colorListStyles} from "./AcmListStyles";
import {API_VERBS} from "../../../MyApiVerbs";


export const ACM_GRID_MODE = {
    ONLY_ACTIVE: 'ONLY_ACTIVE',
    FIND_PARENT: 'FIND_PARENT',
    EXCEPTIONS: 'EXCEPTIONS',
    ALL: 'ALL'
};

const styles = {
    root: {
        margin: "1rem 0"
    }
};

/**
 * Standalone acm grid view with own fetch support, based on designator, can work in 3 modes:
 * - shows all acm rules
 * - shows only active rules
 * - shows parent rule for start time
 */
class AcmGrid extends React.Component {

    static defaultProps = {
        mode: ACM_GRID_MODE.ONLY_ACTIVE,
        onRulesFetched: () => {}
    };

    state = {
        rules: [],
    };

    _getParentStartValue = () => {
        const hour = this.props.start.hour();
        const parentStartValue = notamMoment(`1970-01-01 ${hour}:00Z`).toISOString();
        return {start: parentStartValue};
    };

    _getFilter = () => {
        const {mode, parentid, designator} = this.props;
        const {ONLY_ACTIVE, FIND_PARENT, EXCEPTIONS, ALL} = ACM_GRID_MODE;

        switch (mode) {
            case ONLY_ACTIVE:
                return {
                    and: [
                        {designator: designator},
                        {
                            or: [
                                {parentid: null},
                                {start: {gt: notamMoment().format()}}
                            ]
                        }
                    ]
                };
            case FIND_PARENT:
                return {
                    and: [
                        {designator: designator},
                        {parentid: null},
                        this._getParentStartValue()
                    ]
                };
            case EXCEPTIONS:
                return {
                    and: [
                        {parentid},
                    ]
                };
            case ALL:
                return {};

        }
    };



    _fetchRules = () => {
        const dataProvider = DataProvider.getDataProvider();
        const {designator, mode, start, parentid, onRulesFetched} = this.props;
        const findParentMode = mode === ACM_GRID_MODE.FIND_PARENT;
        const exceptionsMode = mode === ACM_GRID_MODE.EXCEPTIONS;
        const onlyActiveMode = mode === ACM_GRID_MODE.ONLY_ACTIVE;
        const allMode = mode === ACM_GRID_MODE.ALL;

        if((onlyActiveMode || findParentMode) && !designator) return;
        if(findParentMode && !start) return;
        if(exceptionsMode && !parentid) return;


        const promise = dataProvider(API_VERBS.GET_LIST, RESOURCES.ACM_RULES,
            {
                filter: this._getFilter()
            });

        const sortRules = (prev, next) => notamMoment(prev.start).valueOf() - notamMoment(next.start).valueOf();

        promise.then(
            res =>
            {
                const rules = res.data.sort(sortRules);
                this.setState({
                    rules
                }, onRulesFetched(rules))
            }
        )
    };

    componentDidMount() {
        this._fetchRules();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.designator !== this.props.designator) this._fetchRules();

        const {mode} = this.props;
        const findParentMode = mode === ACM_GRID_MODE.FIND_PARENT;
        const exceptionsMode = mode === ACM_GRID_MODE.EXCEPTIONS;

        if(findParentMode && (prevProps.start !== this.props.start)) this._fetchRules();
        if(exceptionsMode && (prevProps.parentid !== this.props.parentid)) this._fetchRules();
    }

    render() {
        const {rules} = this.state;
        const data = keyBy(rules, 'uid');
        const {classes, mode} = this.props;
        const {root, ...inherited} = classes;

        const findParentMode = mode === ACM_GRID_MODE.FIND_PARENT;
        const onlyActiveMode = mode === ACM_GRID_MODE.ONLY_ACTIVE;
        const exceptionsMode = mode === ACM_GRID_MODE.EXCEPTIONS;

        return (
            <div className={root}>
                {
                    onlyActiveMode && !findParentMode && <InfoText content={<TranslatableTypography content={`resources.${RESOURCES.ACM_RULES}.infoOnlyActive`}/>}/>
                }
                {
                    findParentMode && !isEmpty(rules) && <TranslatableTypography variant={'body2'} content={`resources.${RESOURCES.ACM_RULES}.defaultRule`}/>
                }
                {
                    exceptionsMode && !isEmpty(rules) && <TranslatableTypography variant={'body2'} content={`resources.${RESOURCES.ACM_RULES}.exceptionRule`} options={{smart_count: 2}}/>
                }
                <Datagrid data={data}
                          currentSort={{
                              field: 'start', order: 'ASC'
                          }}
                          hasBulkActions={false}
                          ids={Object.keys(data)}
                          selectedIds={[]}
                          classes={inherited}
                          resource={RESOURCES.ACM_RULES}
                          >

                    <TextField source="designator" />

                    <FormDataConsumer label={`resources.${RESOURCES.ACM_RULES}.fields.start`}>
                        {
                            ({formData, ...rest}) => (
                                <MyTextField source={'start'} {...rest} format={formatHourSlot(!rest.record.parentid)}/>
                            )
                        }
                    </FormDataConsumer>
                    <TextField source="blue"/>
                    <TextField source="green"/>
                    <TextField source="yellow"/>
                    <TextField source="red"/>
                </Datagrid>
            </div>
        );
    }
}

AcmGrid.propTypes = {
    mode: PropTypes.string.isRequired
};

export default withStyles({...colorListStyles, ...styles})(AcmGrid);
