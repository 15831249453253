import {TextField} from 'react-admin'
import React from "react";
import {getStatusIconColored} from "../../../utils/Dashboard/statusIcon";
import StatusField from "../../StatusField";
import {
    dateFormatter,
    dateTimeFormatter,
} from "../../../utils/momentHelpers";
import MyTextField from "../../MyTextField";
import {ReferenceFieldDecorator as ReferenceField} from "../../ReferenceFieldDecorator";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import Security from '@material-ui/icons/Security';
import {compose} from "redux";
import {translate} from "react-admin";
import {SummaryCards} from "../../SummaryCards";
import {translateCaaStatusToTypo} from "../../../utils/applicationsHelpers";
import {CertificateActionButtons} from "./CertificateActionButtons"

const sortFieldOptions = [
    {
        label: 'resources.missions.fields.created',
        value: 'created'
    },
    {
        label: 'resources.missions.fields.modified',
        value: 'modified'
    },
    {
        label: 'resources.drones.fields.name',
        value: 'name'
    },
    {
        label: 'resources.applications.verification',
        value: 'status'
    },
    {
        label: 'resources.certTypes.fields.country',
        value: 'country'
    },
    {
        label: 'resources.certTypes.fields.expire',
        value: 'expire'
    },
    {
        label: 'resources.drones.fields.operator',
        value: 'operatorid'
    },
];




export const CertificateSummaryCards = compose(translate, withWidth())
(
    ({
         basePath,
         isOp,
         permissions,
         resource,
         translate,
         width,
         ...rest
     }) => (
        <SummaryCards
            title={
                ({record}) => <TextField record={record} source="name"/>
            }
            subheader={
                ({record}) => <TextField  source="country"
                                          record={record}/>
            }
            avatarIcon={<Security/>}
            actions={
                ({record}) => <CertificateActionButtons resource={resource}
                                                  permissions={permissions}
                                                  record={record}
                                                  {...{basePath}}/>

            }
            {...{sortFieldOptions, ...rest}}
        >
            {
                ({record}) =>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.applications.verification')}
                                </TableCell>
                                <TableCell>
                                    <StatusField source="status"
                                                 tooltip={isWidthDown('md', width)}
                                                 statusMappingFn={translateCaaStatusToTypo}
                                                 iconGetter={getStatusIconColored}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            {isOp
                                ? null //<TextField source="name"/>. both for op and gov now

                                : <TableRow>
                                    <TableCell>
                                        Operator
                                    </TableCell>
                                    <TableCell>
                                        <ReferenceField source="operatorid"
                                                        reference="operators"
                                                        linkType="show"
                                                        addLabel={true}
                                                        label={'resources.drones.fields.operator'}
                                                        record={record}
                                            //TODO I think it should be set automatically
                                                        basePath={'operators'}
                                        >
                                            {/*
                                                             was: fixme to full name renderer

                                                             TODO connect to custom mission view, should be faster and
                                                                we have there full name done
                                                            */}

                                            <TextField source="lastName" record={record}/>
                                        </ReferenceField>
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell>
                                    {translate('resources.certTypes.fields.expire')}
                                </TableCell>
                                <TableCell>
                                    <MyTextField source="expire"
                                                 record={record}
                                                 format={dateFormatter}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.modified')}
                                </TableCell>
                                <TableCell>
                                    <MyTextField source="modified"
                                                 format={dateTimeFormatter}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
            }
        </SummaryCards>
    )
);