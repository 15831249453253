import DataProvider from "../MyDataProvider";
import {API_VERBS} from "../MyApiVerbs";


export const fetchZoneTypesData = (callback) => {
    const dataProvider = DataProvider.getDataProvider();
    dataProvider(API_VERBS.GET_LIST, 'zoneTypes', {})
        .then((req) => {

            let zoneTypes = [];

            req.data.forEach((zoneType) => {
                zoneTypes.push({
                    name: zoneType.name,
                    description: zoneType.description
                });
            });

            callback(zoneTypes);
        })
        .catch((e) => {
            console.error(e);
        });
};
