import React from 'react';
import {
    AutocompleteInput, Filter, Responsive, SimpleList, Edit, Create, Datagrid, BooleanField,
    TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, RadioButtonGroupInput, SelectArrayInput, TextInput, EmailField, EmailInput, BooleanInput, SimpleShowLayout, Show, ShowButton, FunctionField, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, ArrayField, translate
} from 'react-admin';
import { dateFormatter, dateTimeFormatter } from '../../utils/momentHelpers'
import MyTextField from '../MyTextField';
import { canUpdate, isSuperAdmin } from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import { MyRecordTitle, MyResourceListTitle } from "../MyTitle";
import { ReferenceFieldDecorator as ReferenceField } from "../ReferenceFieldDecorator";
import MyPagination from "../MyPagination";
import { ListDecorator as List } from "../ListDecorator";
import compose from 'recompose/compose';
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import AdminTools from "../AdminTools";
import { ADMIN_ON_OPERATOR } from "../../../LogicV1Redux/constants/AdminConstants";
import MyExtendedUserSearch from "./MyExtendedUserSearch";
import MyIlikeSearch from "./MyIlikeSearch";
import PrivilegesField from "./OperatorCustomComponents/PrivilegesField";
import DronesAndCertsSortable from './DronesAndCertsSortable'
/*
Operator {
        uid (string),
        emailVerified (boolean, optional),
        created (string, optional),
        realm (string, optional),
        username (string, optional),
        email (string)
}
*/


const OperatorsFilter = translate(
    ({ translate, permissions, ...props }) => {
        if (!permissions) return null;
        return (
            <Filter {...props}>
                {
                    isSuperAdmin(permissions.roles) &&
                    <MyExtendedUserSearch
                        source='uid'
                        reference={props.resource}
                        label={translate('resources.operators.search', { smart_count: 1 })}
                        alwaysOn
                        inputStyles={{
                            width: '500px'
                        }}
                    />
                }
                <MyIlikeSearch source="lastName" />
                <MyIlikeSearch source="email" />
                <TextInput source="phone" />
            </Filter>
        )
    });

export const OperatorsList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureOperatorsList {...props} />
    </MyTransitionDecorator>
);

export const PureOperatorsList = ({ permissions, ...props }) => {
    if (!permissions) return null;
    return (
        <List {...props}
            filters={<OperatorsFilter permissions={permissions} />}

            title={<MyResourceListTitle resource={'operators'} />}
            sort={{ field: 'created', order: 'DESC' }}
            bulkActions={false}
            actions={<BasicListActions />}
            pagination={<MyPagination />}

        >
            <Responsive
                // small={
                //     <SimpleList
                //         primaryText={record => record.email}
                //         secondaryText={record =>
                //             permissions === 'test' ? record.status : null}
                //     />
                // }
                small={
                    <Datagrid>
                        {/*atc columns reviewed in #96/98 issues*/}


                        {/*<MyTextField source="created"*/}
                        {/*format={dateTimeFormatter}*/}
                        {/*/>*/}
                        <TextField source="lastName" />

                        <TextField source="email" />

                        <TextField source="phone" />

                        {isSuperAdmin(permissions.roles)
                            ? <TextField source="username" />
                            : null
                        }

                        {
                            /* cannot sort on jsonb loopback field
                            <TextField label='test'
                                       source="address.town" />
                           */
                        }
                        {canUpdate(props.resource, permissions) && <EditButton />}
                        {!canUpdate(props.resource, permissions) && <ShowButton />}
                    </Datagrid>
                }
            />

        </List>
    )
};

/* no email send.. probably
export const OperatorCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <EmailField source="email" />
        </SimpleForm>
    </Create>
);
*/
const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];

export const OperatorsEdit = ({ permissions, ...props }) => (
    <Edit {...props}
        undoable={false}
        actions={<BasicEditActions />}
        title={<MyRecordTitle resource={'operators'} recordName={'email'} />}
    >
        <SimpleForm // form={'operator-edit-form'} // must be default
            defaultValue={{}}
        >
            <h5>'to review!'</h5>
            <BooleanInput source="emailVerified" />
            <DisabledInput source="created"
                format={dateTimeFormatter} />
            {/*<TextInput source="realm" validate={defaultNonRequired}/>*/}
            <TextInput source="username"
                validate={defaultNonRequired} />
            <TextInput source="email"
                validate={defaultRequired} />
        </SimpleForm>
    </Edit>
);

export const OperatorsShow = compose(translate)(
    ({ permissions, translate, ...props }) => (

        <Show {...props}
            actions={<BasicShowActions />}
            title={<MyRecordTitle resource={'operators'} recordName={'email'} />}
        >
            <SimpleShowLayout>
                <AdminTools permissions={permissions} scope={ADMIN_ON_OPERATOR} />
                <h5>to review public/gov data</h5>
                <PrivilegesField source="priv" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="username" />
                <TextField source="nickName" />
                <MyTextField source="created"
                    format={dateTimeFormatter} />

                {
                    permissions && isSuperAdmin(permissions.roles) &&
                    <MyTextField source="ban"
                        format={dateTimeFormatter} />
                }

                <Divider style={{ marginTop: "1rem" }} />
                <Typography variant="display1">{translate('resources.operators.fields.email')}</Typography>
                <TextField source="email" />
                <BooleanField source="emailVerified" />

                <Divider style={{ marginTop: "1rem" }} />
                <Typography variant="display1">{translate('resources.operators.fields.phone')}</Typography>
                <TextField source="phone" />
                <BooleanField source="phoneVerified" />

                <Divider style={{ marginTop: "1rem" }} />
                <Typography variant="display1">{translate('myroot.address.address')}</Typography>
                <TextField label={translate('myroot.address.address') + ' 1'}
                    source="address.line1" />
                <TextField label={translate('myroot.address.address') + ' 2'}
                    source="address.line2" />
                <TextField label={'myroot.address.town'}
                    source="address.town" />
                <TextField label={'myroot.address.zipcode'}
                    source="address.zipcode" />
                <TextField label={'myroot.address.country'}
                    source="country" />

                <Divider style={{ marginTop: "1rem" }} />
                <Typography variant="display1">{translate('myroot.company.data')}</Typography>
                <TextField label={'myroot.company.name'}
                    source="etc.company.name" />
                <TextField label={'myroot.company.tax'}
                    source="etc.company.tax" />
                <TextField label={translate('myroot.address.address') + ' 1'}
                    source="etc.company.address.line1" />
                <TextField label={translate('myroot.address.address') + ' 2'}
                    source="etc.company.address.line2" />
                <TextField label={'myroot.address.town'}
                    source="etc.company.address.town" />
                <TextField label={'myroot.address.zipcode'}
                    source="etc.company.address.zipcode" />
                <TextField label={'myroot.address.country'}
                    source="etc.company.address.country" />

                <Divider style={{ marginTop: "1rem" }} />
                <Typography variant="display1">{translate('resources.applications.resource', { smart_count: 2 })}</Typography>

                <ArrayField source="drones"
                    label={'resources.drones.menu'}
                >

                    <DronesAndCertsSortable drones={true} operatorid={props.id} translate={translate} />
                </ArrayField>

            </SimpleShowLayout>
        </Show>
    )
);


