
import {FETCH_ALL_POIS_V2_RQ, SAVE_POI_DATA_V2_RQ, STORE_ALL_POIS_V2} from "../constants/PoisV2Constants";

export const fetchAllPoisV2Rq = (payload) => {
    console.log('fetchAllPoisV2Rq', payload);
    return ({
        type: FETCH_ALL_POIS_V2_RQ,
        payload,
    });
};

export const storeAllPoisV2 = (payload) => {
    console.log('storeAllPoisV2', payload);
    return ({
        type: STORE_ALL_POIS_V2,
        payload,
    });
};

export const savePoiDataV2Rq = (payload) => {

    console.log('savePoiDataV2Rq', payload);
    return ({
        type: SAVE_POI_DATA_V2_RQ,
        payload,
    });
};
