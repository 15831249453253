import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Archive from "@material-ui/icons/Archive";
import Unarchive from "@material-ui/icons/Unarchive";

import classnames from 'classnames';
import { translate } from 'ra-core';
import {Button} from 'react-admin';

import {myCrudArchive, myCrudUnarchive} from '../../LogicV1Redux/actions/MyArchiveActions'
import {CaaApplicationStatuses} from "../../LogicV1Redux/constants/CaaApplicationEnums";

import {TooltipProxy as Tooltip} from "./TooltipProxy"

const styles = theme => ({
    archiveButton: {
        backgroundColor: theme.palette.error.main,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "inherit",
            color: theme.palette.error.main,
        },
        '&:hover': {
            //backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
});


//1. if we will need disabled archive button for some reason,
// we should probably add passed custom tooltip
//2. archive USUALLY works by setting record field active to false,
// but mission archivization works otherway -> moves snapshot to different table,
// so we cannot use it there (only clone)

class ArchiveToggleButton extends Component {
    handleArchive = event => {
        event.preventDefault();
        const {
            dispatchCrudArchive,
            dispatchCrudUnarchive,
            resource,
            record,
            basePath,
            redirect,

        } = this.props;

        console.log('dispatching archive/unarchive request', this.props);

        //hmm.
        const archived = (record.active === false);

        if (archived) {
            dispatchCrudUnarchive(resource, record.id, record, basePath, redirect);
        }
        else {
            dispatchCrudArchive(resource, record.id, record, basePath, redirect);
        }
    };

    render() {
        const {
            classes = {},
            className,
            record
        } = this.props;

        //hmm.
        const archived = (record.active === false);
        const {status} = record;
        const isDisabled =
            status !== CaaApplicationStatuses.ACCEPTED &&
            status !== CaaApplicationStatuses.REJECTED &&
            status !== CaaApplicationStatuses.CREATED &&
            status !== CaaApplicationStatuses.MODIFIED;

        const label = (archived)?'resources.archives.action.unarchive':'resources.archives.action.archive';

        const archiveButton = (
            <Button
                onClick={this.handleArchive}
                label={label}
                className={classnames(
                    'ra-delete-button',
                    classes.archiveButton,
                    className
                )}
                key="button"
                disabled={isDisabled}
            >
                <Archive/>
            </Button>
        );

        return (archived)
            ? (
                <Button
                    onClick={this.handleArchive}
                    label={label}
                    className={classnames(
                        'ra-delete-button',
                        classes.archiveButton,
                        className
                    )}
                    key="button"
                >
                    <Unarchive/>
                </Button>
            )
            : (
                isDisabled ?
                    <Tooltip content={'resources.applications.archiveDisabled'} placement={'left'}>
                        <div>
                            {archiveButton}
                        </div>
                    </Tooltip>
                    :
                    archiveButton
            )
    }
}

ArchiveToggleButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    dispatchCrudArchive: PropTypes.func.isRequired,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
};

ArchiveToggleButton.defaultProps = {
    redirect: 'list',
};

export default compose(
    connect(
        null,
        {
            dispatchCrudArchive: myCrudArchive,
            dispatchCrudUnarchive: myCrudUnarchive
        }
    ),
    translate,
    withStyles(styles)
)(ArchiveToggleButton);
