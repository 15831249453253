import {withStyles} from "@material-ui/core";
import {canUpdate} from "../../../logic/MyRestConfig";
import EditButton from "ra-ui-materialui/esm/button/EditButton";
import ShowButton from "ra-ui-materialui/esm/button/ShowButton";
import ArchiveToggleButton from "../../MyArchiveToggleButton";
import React from "react";

const droneActionButtonStyles = theme => ({
    root: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "unset"
        }
    }
});

export const DroneActionButtons = withStyles(droneActionButtonStyles)((props) => {
    const {mySelf, record, permissions, classes, ...rest} = props;

    const isOperator = permissions && permissions.roles && permissions.roles.indexOf('operator') !== -1;

    const canEdit = canUpdate(props.resource, permissions);

    return (
        <div className={classes.root}>
            {
                canEdit
                    ? <EditButton record={record} {...rest} />
                    : <ShowButton record={record} {...rest}/>
            }
            {
                isOperator
                    ? <ArchiveToggleButton record={record} {...rest} />
                    : null
            }
        </div>
    )
});