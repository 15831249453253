import React from "react";
import {OldStatusMsg} from "../MissionCustomComponents/AtcMsgBlock";
import has from "lodash.has";
import moment from "moment";
import Labeled from "ra-ui-materialui/esm/input/Labeled";
import {
    CaaApplicationHoldStatuses,
    CaaApplicationStatuses
} from "../../../../LogicV1Redux/constants/CaaApplicationEnums";
import {Fields} from "redux-form";
import TranslatableTypography from "../../TranslatableTypography";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";

const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);

//TODO refactor to separate component - exists also as AtcMsgBlock
export const OperatorMsgBlockField = ({record, classes = {}, style, className, notInForm}) => {
    if(!has(record, 'etc.rejectedMsg') || !has(record, 'etc.rejectedMeetingTime0')) return null;
    if(record.status === CaaApplicationStatuses.ACCEPTED || record.status === CaaApplicationHoldStatuses.HOLD_MEETING_ACCEPTED) return null;

    const operatorMsg = record.etc.rejectedMsg;
    const rejectedMeetingTime0 = record.etc.rejectedMeetingTime0;
    const rejectedMeetingTime1 = has(record, 'etc.rejectedMeetingTime1') ? record.etc.rejectedMeetingTime1 : null;
    const rejectedMeetingTime2 = has(record, 'etc.rejectedMeetingTime2') ? record.etc.rejectedMeetingTime2 : null;

    const msgStyle = {color:"tomato", whiteSpace: "pre-wrap"};

    const Component = () =>
        <div className={className} style={style}>
            {NEW_ALERTBOX_ENABLED && <TranslatableTypography content='resources.applications.messagesPrincipal' variant='body2'/>}
            <OldStatusMsg classes={classes} header={'resources.applications.rejectMeetingDialogLabel'} msg={operatorMsg} style={msgStyle}/>
            <Labeled label={'resources.applications.rejectMeetingDatePropsals'} >
                <>
                    <OldStatusMsg classes={classes}
                                  header={'resources.applications.rejectMeetingDate'}
                                  headerOptions={{number: 1}}
                                  msg={moment(rejectedMeetingTime0).format('YY-MM-DD HH:mm')}
                                  style={msgStyle}
                    />

                    {!!rejectedMeetingTime1 &&
                    <OldStatusMsg classes={classes}
                                  header={'resources.applications.rejectMeetingDate'}
                                  headerOptions={{number: 2}}
                                  msg={moment(rejectedMeetingTime1).format('YY-MM-DD HH:mm')}
                                  style={msgStyle}
                    />
                    }
                    {!!rejectedMeetingTime2 &&
                    <OldStatusMsg classes={classes}
                                  header={'resources.applications.rejectMeetingDate'}
                                  headerOptions={{number: 3}}
                                  msg={moment(rejectedMeetingTime2).format('YY-MM-DD HH:mm')}
                                  style={msgStyle}
                    />
                    }
                </>
            </Labeled>
        </div>;


    return notInForm ?
        <Component/>
        :
        <Fields names={[
            'etc.rejectedMsg',
            'etc.rejectedMeetingTime0',
            'etc.rejectedMeetingTime1',
            'etc.rejectedMeetingTime2',
            'status'
        ]} component={Component}/>

};