import {Button as RaButton} from "ra-ui-materialui";
import Polymer from "@material-ui/icons/Polymer";
import React from "react";
import {loadAcmRulesCTR} from "../../../../LogicV1Redux/actions/AcmRulesActions";

import {connect} from "react-redux";
import compose from 'recompose/compose';
import {RESOURCES} from "../../../logic/MyRestConfig";
import {toggleAcmRpaDialog} from "../../../logic/ui/MyUiActions";
import AcmRpaInputDialog from "./AcmRpaInputDialog";
import AcmTermsCreatorDialog from "./AcmTermsCreatorDialog";

const AcmButton = ({zoneType, designator, loadAcmRulesCTR, loadAcmRulesRPA, uid, etc}) => {
    const isCtr = zoneType === 'CTR';
    const isRpa = zoneType === 'RPA';

    if (!isRpa && !isCtr) return null;

    const loadAcmRules = isCtr ? ()=>loadAcmRulesCTR(designator) : ()=>loadAcmRulesRPA();

    return (
        <>
            <RaButton
                label={`resources.${RESOURCES.ACM_RULES}.menu`}
                onClick={loadAcmRules}
            >
                <Polymer/>
            </RaButton>
            {
                isRpa && <AcmRpaInputDialog data={{uid, etc, designator}}/>
            }
            <AcmTermsCreatorDialog ctr={designator}/>
        </>

    )
};

export default compose(
    connect(
        null,
        dispatch => ({
            loadAcmRulesCTR: (designator) => dispatch(loadAcmRulesCTR({designator})),
            loadAcmRulesRPA: () => dispatch(toggleAcmRpaDialog({show: true}))
        })
    )
)(AcmButton);