import {Toolbar} from "ra-ui-materialui";
import React from "react";
import ApplicationCreateActionsButton from "./ApplicationCreateActionsButton";

const ApplicationCreateToolbar = props => (

    <Toolbar>
        <ApplicationCreateActionsButton
            {...props}
            label={'ra.action.save'}
        />
    </Toolbar>
);

export default ApplicationCreateToolbar;