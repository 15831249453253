import {Toolbar} from "ra-ui-materialui";
import React from "react";
import ZoneSaveActionButton from "./ZoneSaveActionButton";

const ZoneSaveToolbar = props => (

    <Toolbar  >
        <ZoneSaveActionButton
            //submitOnEnter={true}
            {...props}
            label={'ra.action.save'}

        />
    </Toolbar>
);

export default ZoneSaveToolbar;