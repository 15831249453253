import React from "react";
import {ReferenceFieldView} from "ra-ui-materialui/esm/field/ReferenceField";
import {ReferenceFieldController} from "ra-core";
import {isEmpty} from "lodash";
import {TextField} from "ra-ui-materialui";

export const MyReferenceField = ({children, ...props}) => {
    if (React.Children.count(children) !== 1) {
        throw new Error('<ReferenceField> only accepts a single child');
    }
    return (
        <ReferenceFieldController {...props}>
            {controllerProps => {
                if (!isEmpty(controllerProps.referenceRecord)) {
                    return (
                        <ReferenceFieldView
                            {...props}
                            {...{children, ...controllerProps}}
                        />
                    )
                } else {
                    return (
                        <TextField {...props} />
                    )
                }
            }
            }
        </ReferenceFieldController>
    );
};