
import React from 'react';
//import PropTypes from 'prop-types';
import {deserializeZoneData, isLockedSourceZone} from '../../../../LogicV1Redux/oldLogic/ZonesListV2';

import ZoneViewer from "./ZoneViewer";
import ZoneEditor from "./ZoneEditor";

import {primitives2Bbox} from "../../../../gl_map/glSetups/oldCommon/_CommonStuff";
import {initializeLayersObject} from "../../../../gl_map/glSetups/newCommon/mapStateConstructors";
import StoreProvider from "../../../MyStoreProvider";
import {clearCustomLayer, storeCustomLayer} from "../../../../LogicV1Redux/actions/CustomLayerActions";
import {clearAllPrimitives, setAllEditablePrimitives} from "../../../../LogicV1Redux/actions/DrawEditorActions";

import {shallowEqual} from 'recompose';
import {fetchAllZonesV2Rq} from "../../../../LogicV1Redux/actions/ZonesV2Actions";
import {UNIT_TYPES} from "../Units";
import {has} from "lodash"
import {deserializeUnitData} from "../../../../LogicV1Redux/oldLogic/UnitsListV2";

import {connect} from 'react-redux';
import compose from 'recompose/compose';

class MyZoneField extends React.Component {


    componentDidMount() {

        console.log("MZF did mount", this.props);

        //check if still needed
        if (this.props.zonesV2.rawData.length === 0) {
            StoreProvider.getStore().dispatch(fetchAllZonesV2Rq());
        }

        this._do();

    }

    componentWillUnmount() {
        console.log("MZF unmount");
        StoreProvider.getStore().dispatch(clearAllPrimitives());
        StoreProvider.getStore().dispatch(clearCustomLayer());

    }

    componentDidUpdate(prevProps) {
        console.log("MZF did update", prevProps, this.props);
        if (!shallowEqual(this.props, prevProps)) {
            this._do();
        } else {
            console.log('MZF shallow equal false');
        }
    }

    _do = () => {
        console.log("MZF did do");
        let bBox;

        const { record = {}, size, showEditLink = false, showConflicts=false, zoneTypesV2, mySettingsVisible, mySelf, unitUid  } = this.props;

        //hacking so-called inifinite rerender on opened settings view
        // probably caused by dispatch call below AND multiple store changes created by new form!)
        //i guess can be normally binded as prop from state, but later...
        //18-03-20, binded, and undefined... since long time i guess

        if (mySettingsVisible) {
            console.warn('DO NOT update zone on settings open!');
            return;
        }

        const zoneData = deserializeZoneData(record, zoneTypesV2);
        console.log('==================MyZoneV2', zoneData);
        console.log('==MZF', { record , size, showEditLink , showConflicts, zoneData });

        if (showConflicts) {
            console.error('no conflicts support in zone viewer / editor as for now');
        }

        const zonePrimitives = (zoneData && zoneData.primitive)?[zoneData.primitive]:[];

        //hmm, using cached data, as there is no unit data available here.

        console.log('=== mySelf', mySelf);

        console.log(zonePrimitives);

        const newLayer = initializeLayersObject();

        if (zonePrimitives.length > 0) {
            bBox = primitives2Bbox(zonePrimitives);
        }

        if (this.props.enableEdit) {

            StoreProvider.getStore().dispatch(setAllEditablePrimitives(zonePrimitives));
            //unit border display, can be switched in form.
            let finalUnitUid = (unitUid)?unitUid:record.unitid;
            const ownerUnit = has(mySelf, 'units') ? (mySelf.units.find(el => el.uid === finalUnitUid)) : false;
            console.log('=== mzf ownerUnit', ownerUnit);

            if (ownerUnit) {
                const unitData = deserializeUnitData(ownerUnit, zoneTypesV2);

                newLayer.polys = (unitData && unitData.primitive)?[unitData.primitive]:[];
                StoreProvider.getStore().dispatch(storeCustomLayer({deckData: newLayer}));

                if (!bBox && newLayer.polys.length > 0) {
                    bBox = primitives2Bbox(newLayer.polys);
                }
            }
        }
        else {
            //yikes. temp?
            newLayer.polys = zonePrimitives;
            //newLayer.texts = warningsTexts;
            //console.log(newLayer)

            StoreProvider.getStore().dispatch(storeCustomLayer({deckData: newLayer}));
        }
        if (bBox)
            this.setState({bBox});
        else {
            //hmm, some problems with superadmin
            this.setState({bBox:primitives2Bbox([])})
        }

    };



    render() {
        if (!this.state) {
            return null;
        }

        //sanitize
        const {
            record = {},
            enableEdit = false,
            showGeometry,
            //permissions,
            //dispatch, //hmm reattaching later in context (btw - is it passed now?)
            //...rest
        } = this.props;

        const {legsPrimitives, helpers, conflicts, bBox} = this.state;
        const EditorOrViewer = (enableEdit) ? ZoneEditor : ZoneViewer;

        //const EditorOrViewer = ZoneViewer; //to reenable enableEdit
        return (
            <EditorOrViewer record={record}
                            //helpers={helpers}
                            //conflicts={conflicts}
                            //legsPrimitives={legsPrimitives}
                            bBox = {bBox}
                            showGeometry = {showGeometry}
            />


        )
    }
}

const mapStateToProps = state => {
    return {
        mySettingsVisible: state.mySettings.visible,  //UNDEFINED!!!
        zonesV2: state.zonesV2,
        zoneTypesV2: state.zoneTypesV2,
        mySelf: state.mySelf,
    }
};

export default compose(
    connect(
        mapStateToProps,
        null
    ),
)(MyZoneField)
