
import React, {createElement} from 'react';
//import PropTypes from 'prop-types';

import { put } from 'redux-saga/effects';

import {connect} from 'react-redux'
import { Notification, defaultTheme } from 'react-admin';
import {
    showNotification,
    hideNotification,
} from 'react-admin';


import { withRouter } from 'react-router-dom';
import { Authenticated } from 'react-admin';

import Button from '@material-ui/core/Button';

//import { translate } from 'ra-core';
import compose from 'recompose/compose';

import sizeMe from "react-sizeme";
import DataProvider from '../../dr_ra2/MyDataProvider'
import PubSub from "pubsub-js";
import get from 'lodash/get';

import {LiveMapMenu} from "../Components/_MyMenus";

import LiveMap from '../Components/Maps/LiveMap';


//fixme notifications/error support

class FSLiveMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        console.log('FSLiveMap', this.props);
        console.log('FSLiveMap', this.state);
        //put(showNotification('myroot.httperror', 'warning', {autoHideDuration: 10000}));
        //hmm what about parallel editing...
    }

    render() {
        //const { width, height } = this.props.size;
        const { location, notification, showNotification } = this.props;
        return (
            <Authenticated authParams={{ foo: 'bar' }} location={location}>
                <div style={{position:"absolute", top:0, bottom:0, left:0, right:0}}>
                    <div >
                        <LiveMap
                            //height={height}
                            //width={width}
                            menuStore={{liveADSB:false, tickUpdate:true}}
                            menuComponent={LiveMapMenu}
                        />
                    </div>
                    {/*<div style={{position:"absolute", top:0, height:10, right:0}}>My size is {width} px x {height}px</div>*/}

                    <div style={{position:"absolute", top:100, height:10, right:0}}>
                        <Button variant="outlined" color="primary"
                                //className={classes.button}
                                onClick={() => { console.log('onClick');showNotification('clicked!', 'info'); }}
                        >
                            ClickMe
                        </Button>
                    </div>

                    <div style={{position:"absolute", bottom:0, left:0}}>
                        {createElement(notification)}
                    </div>

                </div>
            </Authenticated>
        )
    }
}

FSLiveMap.defaultProps = {
    //appBar: AppBar,
    //menu: Menu,
    notification: Notification,
};

const mapStateToProps = state => {
    //console.log('FSZoneEditor mapStateToProps', state);
    return {
        isLoading: state.admin.loading > 0,
        myAuth: state.myAuth,
    }
};
//
const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            showNotification,
            //userLogout: userLogoutAction,
        }
    )
);

export default enhance(FSLiveMap);
//export default sizeMe({ monitorHeight: true })(enhance(FSLiveMap));

//export default sizeMe({ monitorHeight: true })(withRouter(FSLiveMap));
