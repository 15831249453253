import React from "react";
import {Link} from "react-admin";
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    link: {
        color: theme.palette.colorCircle.link,
    }
});

export const LinkDecorator = withStyles(styles)(Link);
