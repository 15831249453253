export const NotamType = {
    NEW: 'NOTAMN',
    REPLACE: 'NOTAMR',
    CANCEL: 'NOTAMC'
};

export const ActivityRepeatMode = {
    NONSTOP: 'non-stop',
    MONTHS: 'months',
    DAYS: 'days',
    WEEKDAYS: 'weekdays',
};

export const ActivityPeriodMode = {
    SINGLE: 'single',
    REPEAT: 'repeat',
};

export const ActivitySlotMode = {
    H24: 'h24',
    SLOTS: 'slots',
};

export const ZoneReferenceType = {
    NEW: "new",
    EXIST: 'existing'
};

export const PUBLISH_NOTAM = "PUBLISH_NOTAM";