import React from 'react';
import {
    AutocompleteInput,
    Filter,
    Responsive,
    SimpleList,
    Edit,
    Create,
    Datagrid,
    ReferenceField,
    TextField,
    EditButton,
    DisabledInput,
    LongTextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    RadioButtonGroupInput,
    SelectArrayInput,
    TextInput,
    EmailField,
    EmailInput,
    BooleanInput,
    SimpleShowLayout,
    Show,
    ShowButton,
    FunctionField,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    ReferenceManyField,
    translate
} from 'react-admin';
import {dateFormatter, dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';
import {canUpdate} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import {JSONInput, nullableJSONObjectValidation} from "../MyJSONInput";
import {isSuperAdmin} from "../../utils/tempCommon";
import MyIlikeSearch from "./MyIlikeSearch";
import AdminTools from "../AdminTools";
import {ADMIN_ON_GOV} from "../../../LogicV1Redux/constants/AdminConstants";
import {SaveOnlyToolbar} from "../MyBasicEditToolbar";
import {MyResourceListTitle} from "../MyTitle";
import MyExtendedUserSearch from "./MyExtendedUserSearch";
import {validatePassword} from "../../utils/validationHelpers";

/*
Operator
  {
    "address": {},
    "created": "2018-03-07T14:39:23.515Z",
    "firstName": "string",
    "lang": "string",
    "lastName": "string",
    "phone": "string",
    "role": "string",
    "uid": "string",
    "realm": "string",
    "username": "string",
    "email": "string",
    "emailVerified": true
  }

*/

const GovsFilter = translate(
    ({translate, permissions, ...props}) => {
    if (!permissions) return null;
    return (
        <Filter {...props}>
            {
                isSuperAdmin(permissions.roles) &&
                    <MyExtendedUserSearch
                        source='uid'
                        reference={props.resource}
                        label={translate('resources.govs.search', {smart_count: 1})}
                        alwaysOn
                        inputStyles={{
                            width: '500px'
                        }}
                    />
            }
            <MyIlikeSearch source="username"
                             label='resources.govs.fields.username'/>
            <MyIlikeSearch source="email"
                           label='resources.govs.fields.email'/>
            <MyIlikeSearch source="lastName"
                           label='resources.govs.fields.lastName'/>
        </Filter>
    )
});


export const GovsList = ({permissions, ...props}) => (
    <List {...props}
          // title="!All! Govs"
          title={<MyResourceListTitle resource={'govs'}/>}
          filters={<GovsFilter permissions={permissions}/>}
          sort={{ field: 'created', order: 'DESC' }}
          bulkActions={false}
          actions={<BasicListActions />}
          pagination={<MyPagination />}
    >
        <Responsive
            // small={
            //     <SimpleList
            //         primaryText={record => record.key}
            //         secondaryText={record =>
            //             permissions === 'test' ? record.status : null}
            //     />
            // }
            small={
                <Datagrid>
                    <MyTextField source="created"
                                 format={dateTimeFormatter}
                                 />
                    <TextField source="email" />
                    <TextField source="username" />
                    {/*NO LONGER SINGLE AREA*/}
                    {/*<TextField source="pandora.areas" />*/}

                    {canUpdate(props.resource, permissions) && <EditButton />}
                    {!canUpdate(props.resource, permissions) && <ShowButton />}
                </Datagrid>
            }
        />
    </List>
);

export const GovsShow = ({permissions, ...props}) => (
    <Show {...props}
      actions={<BasicShowActions/>}
    >
        <SimpleShowLayout>
            <h5>'stub!'</h5>

            <TextField label='id'
                       source="id" />

        </SimpleShowLayout>
    </Show>
);

/* no email send.. probably */
export const GovsCreate = ({permissions, ...props}) => (
    <Create {...props}
            /* undoable={false} -  no undo in create anyway */
            title={'resources.govs.create'}
    >
        <SimpleForm //form={'govs-create-form'} // must be default
                    defaultValue={{}}
        >
            {/*<TextInput source="realm" validate={defaultNonRequired}/>*/}
            <TextInput source="email"
                       validate={defaultRequired}/>
            <TextInput source="password"
                       validate={[...defaultRequired, validatePassword]}/>
            <TextInput source="username"
                       validate={defaultNonRequired}/>
            <TextInput source="firstName"
                       validate={defaultNonRequired}/>
            <TextInput source="lastName"
                       validate={defaultNonRequired}/>
            {/*<TextInput source="phone" validate={defaultNonRequired}/>*/}

        </SimpleForm>
    </Create>
);

const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];

const etcJSONValidation = [
    nullableJSONObjectValidation,
];

//fixme? add address?

export const GovsEdit = ({permissions, ...props}) => (
    <Edit {...props}
          undoable={false}
          actions={<BasicEditActions />}
    >
        <SimpleForm //form={'govs-edit-form'} // must be default
                    defaultValue={{}}
                    toolbar={<SaveOnlyToolbar/>}
        >
            <AdminTools permissions={permissions} scope={ADMIN_ON_GOV}/>
            <DisabledInput source="id"/>
            <DisabledInput source="created"
                           format={dateTimeFormatter} />
            <BooleanInput source="emailVerified" />

            {/*<TextInput source="realm" validate={defaultNonRequired}/>*/}
            <TextInput source="email"
                       validate={defaultRequired}/>
            {/*<DisabledInput //label="password to fix! (special case)"*/}
                       {/*source="password"*/}
                       {/*/>*/}
            <TextInput source="username"
                       validate={defaultNonRequired}/>
            <TextInput source="firstName"
                       validate={defaultNonRequired}/>
            <TextInput source="lastName"
                       validate={defaultNonRequired}/>
            <TextInput
                       source="phone"
                       validate={defaultNonRequired}/>

            <ReferenceManyField target="govId"
                                reference="unitGovs"
                                label="resources.govs.permissions"
                                addLabel={true}
            >
                <Datagrid rowClick="show">
                    <ReferenceField source="unitId" reference="units" linkType={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="role"/>

                </Datagrid>
            </ReferenceManyField>

            {/*only superadmin now*/}

            {isSuperAdmin()
                ?< JSONInput source="etc"
                validate={etcJSONValidation}
                />
                :null
            }
            {isSuperAdmin()
                ?< JSONInput source="pandora"
                             validate={etcJSONValidation}
                />
                :null
            }
        </SimpleForm>
    </Edit>
);
