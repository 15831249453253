// removing export in default toolbar, -> was breaking change, to be fixed by react admin
// https://github.com/marmelab/react-admin/pull/2340

import React, {Component} from 'react';
import {Toolbar, SaveButton, required, translate} from 'react-admin';
import DataProvider from "../MyDataProvider";
import {CaaApplicationStatuses} from "../../LogicV1Redux/constants/CaaApplicationEnums";
import { crudUpdate } from 'react-admin';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {saveApplicationStatus, saveApplicationStatusWithUpdate} from "../../LogicV1Redux/actions/ApplicationsActions";
import {withStyles} from "@material-ui/core"
import compose from 'recompose/compose';
import {_getStatusColor} from "../utils/Dashboard/statusIcon";
import classnames from 'classnames';
import MyInputDialog from "./MyInputDialog";
import objectDiff from "objectdiff"
import {getFormInitialValues} from "redux-form";
import {isCaaApplMeetingHoldStatus, translatePrincipalToTypo} from "../utils/applicationsHelpers";
import {
    APPLICATION_PRINCIPAL_COMPANY,
    APPLICATION_PRINCIPAL_OPERATOR
} from "../../LogicV1Redux/constants/ApplicationsConstants";
import has from 'lodash.has';
import get from 'lodash.get';
import defaultTo from 'lodash.defaultto';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Typography from "@material-ui/core/Typography/Typography";
import {bindActionCreators} from "redux";
import {toggleProfileView, toggleSettingsView} from "../logic/settings/MySettingsActions";
import TextField from "@material-ui/core/TextField/TextField";
import {DateTimePickerLocale} from "./TimePickersLocaleProxy";
import moment from "moment";
import Labeled from "ra-ui-materialui/esm/input/Labeled";
import {publishNotam} from "../../LogicV1Redux/actions/NotamActions";
import {unixTimeNow} from "../../modules2lib/common/src/TimeUtils";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";

const APPLICATION_NEW_GOV_USER_MSGS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.APPLICATION_NEW_GOV_USER_MSGS);

// TODO refactor this whole mess quickly!

export const SaveOnlyToolbar = (props) => (
    <Toolbar {...props} >
        <SaveButton {...props} redirect={props.basePath}  />
    </Toolbar>
);

class PublishToolbarPure extends React.Component {

    _handleClick = () => {
        const {record, activities, dLine, geometry, handleSubmitWithRedirect, dispatchPublishNotam, handleSubmit, resource, ...rest} = this.props;

        return handleSubmit(values => {
            dispatchPublishNotam(
                {
                    record: {
                        ...values,
                        d: dLine,
                        activities,
                        geometry
                    },
                    resource
                }
            );
        });
    };

    render() {
        const {activities, geometry, handleSubmitWithRedirect, dispatchPublishNotam, ...props} = this.props;
        return (
            <Toolbar {...props}>
                <SaveButton
                    {...props}
                        redirect={this.props.basePath}
                        handleSubmitWithRedirect={this._handleClick}
                        label={'Publish'}

                />
            </Toolbar>
        )
    }
}

const mapDispatchPublishToProps = dispatch =>
    (
        {
            dispatchPublishNotam: (e) => {
                console.log('dispatchin notam publish', e);
                dispatch(publishNotam(e))
            },
        }
    );


export const NotamPublishToolbar = compose(
    connect(
        null,
        mapDispatchPublishToProps
    )
)(PublishToolbarPure);


export const SaveVerifyToolbar = (props) => {
    const {pristine: isPristine} = props;
    return(
        <Toolbar {...props} >
            {
                !isCaaApplMeetingHoldStatus(props.record.status) &&
                <SaveButtonWithStatusCheck
                    disabled={
                        isPristine ||
                        props.record.status === CaaApplicationStatuses.VERIFY ||
                        props.record.status === CaaApplicationStatuses.VERIFY_AGAIN ||
                        isCaaApplMeetingHoldStatus(props.record.status)
                    }
                    {...props}
                />
            }


            {
                props.record.status === CaaApplicationStatuses.HOLD_MEETING_REQUEST &&
                <StatusChangeButton
                    label={'resources.applications.confirmMeeting'}
                    status={CaaApplicationStatuses.HOLD_MEETING_ACCEPTED}
                    disabled={!isPristine}
                    {...props}
                />
            }
            {/*One can reject meeting when arranged for accidentally issues*/}
            {
                (props.record.status === CaaApplicationStatuses.HOLD_MEETING_REQUEST ||
                    props.record.status === CaaApplicationStatuses.HOLD_MEETING_ACCEPTED) &&
                <StatusChangeButton
                    label={props.record.status === CaaApplicationStatuses.HOLD_MEETING_ACCEPTED ? 'resources.applications.cancelMeeting' : 'resources.applications.denyMeeting'}
                    status={CaaApplicationStatuses.HOLD_MEETING_REJECTED}
                    disabled={!isPristine}
                    {...props}
                />
            }
            {
                !isCaaApplMeetingHoldStatus(props.record.status) &&
                <StatusChangeButton
                    label={'resources.applications.verify'}
                    //enable button only if created
                    disabled={!isPristine || props.record.status !== CaaApplicationStatuses.CREATED && props.record.status !== CaaApplicationStatuses.MODIFIED}
                    status={props.record.status === CaaApplicationStatuses.MODIFIED ? CaaApplicationStatuses.VERIFY_AGAIN : CaaApplicationStatuses.VERIFY}
                    {...props}
                />
            }
        </Toolbar>
    )};


export const CaaVerifyToolbar = (props) => (
    <Toolbar {...props} >
        <CaaDecisionButton
            disabled={
                props.record.status !== CaaApplicationStatuses.VERIFY &&
                props.record.status !== CaaApplicationStatuses.VERIFY_AGAIN &&
                props.record.status !== CaaApplicationStatuses.HOLD_MEETING_ACCEPTED
            }
            label={'resources.applications.accept'}
            decision={CaaApplicationStatuses.ACCEPTED}
            {...props}/>
        <CaaDecisionButton
            label={'resources.applications.createMeeting'}
            disabled={
                props.record.status !== CaaApplicationStatuses.VERIFY &&
                props.record.status !== CaaApplicationStatuses.VERIFY_AGAIN &&
                !isCaaApplMeetingHoldStatus(props.record.status)
                }
            decision={CaaApplicationStatuses.HOLD_MEETING_REQUEST}
            {...props}
        />
        <CaaDecisionButton
            label={'resources.applications.reject'}
            disabled={
                props.record.status !== CaaApplicationStatuses.VERIFY &&
                props.record.status !== CaaApplicationStatuses.VERIFY_AGAIN &&
                props.record.status !== CaaApplicationStatuses.HOLD_MEETING_REJECTED &&
                props.record.status !== CaaApplicationStatuses.HOLD_MEETING_ACCEPTED
            }
            decision={CaaApplicationStatuses.REJECTED}
            {...props}
        />
    </Toolbar>
);


const mapDispatchToProps_principal = (dispatch) => ({
    toggleProfileView: bindActionCreators(toggleProfileView, dispatch),
});

const enhance = compose(
    connect(
        null,
        mapDispatchToProps_principal
    )
);

const PrincipalRadioGroup = enhance(
    ({value, onChange, classes, translate, userState, toggleProfileView}) =>
        <>

            <RadioGroup
                aria-label="principal"
                name="principal"
                className={classes.group}
                value={value}
                onChange={onChange}
            >
                <FormControlLabel value={APPLICATION_PRINCIPAL_OPERATOR}
                                  control={
                                      <Radio disabled={!userState.canSendApplAsOperator}/>
                                  }
                                  label={translatePrincipalToTypo(APPLICATION_PRINCIPAL_OPERATOR)}/>
                <FormControlLabel value={APPLICATION_PRINCIPAL_COMPANY}
                                  control={
                                      <Radio disabled={!userState.canSendApplAsCompany}/>
                                  }
                                  label={translatePrincipalToTypo(APPLICATION_PRINCIPAL_COMPANY)}/>
            </RadioGroup>
            {(!userState.canSendApplAsCompany || !userState.canSendApplAsOperator) &&
            <Typography>
                <a href="javascript:void(0)"
                   onClick={()=>toggleProfileView({show: true})}
                >
                    {translate('error.PersonalOrCompanyDataForCAA')}
                </a>
            </Typography>
            }
        </>
);





const statusChangeButtonStyles = theme => ({
    root: {
        "&:not(:first-child)": {
            marginLeft: theme.spacing.unit
        }
    },
    textField: {
        width: "100%",
        marginRight: "0",
    },
    group: {}
});

class StatusChangeButtonView extends Component {

    state = {
        principalDialogOpen: false,
        principal: APPLICATION_PRINCIPAL_OPERATOR,// has(this.props.record, 'etc.principal') ? this.props.record.etc.principal : "",
        rejectMeetingDialogOpen: false,
        rejectMeetingText: undefined,
        rejectMeetingTime0: undefined,
        rejectMeetingTime1: undefined,
        rejectMeetingTime2: undefined
    };


    _onPrincipalSelect = (event) => {
        console.log("principal", event.target.value);
        this.setState({
            principal: event.target.value
        })
    };


    _handleStatusUpdate = (principal = APPLICATION_PRINCIPAL_OPERATOR) => {
        const { basePath, dispatchApplicationStatus, decision, mySelf, record, resource, handleSubmit, status, handleSubmitWithRedirect} = this.props;
        return handleSubmit(values => {

            if (!record.etc) {
                record.etc = {};
            }

            let userMsgs;
            if(APPLICATION_NEW_GOV_USER_MSGS_ENABLED) {
                userMsgs = defaultTo(
                    get(record, 'userMsgs'),
                    []
                );

                userMsgs.push({
                    timestamp: unixTimeNow(),
                    //save principal only for verify status
                    ...(status === CaaApplicationStatuses.VERIFY && {principal}),
                    status
                });

            }
            else {
                record.etc.principal = principal;
            }


            dispatchApplicationStatus({
                uid: values.uid,
                status: status,
                resource,
                redirect: basePath,
                //hacking for madrid;
                etc: record.etc,
                userMsgs
            })
        });
    };

    _handleDialogConfirm = () => {
        //because whe inject PrincipalRadioGroup to MyInputDialog we have to get value from our state as it is controlled from here
        const {principal} = this.state;
        this._handleStatusUpdate(principal)();
        this._handleDialogClose();
    };

    _handleDialogClose = () => {
        this.setState({
            principalDialogOpen: false,
        })
    };

    _handleClick = () => {
        const {record, status} = this.props;

        if(status === CaaApplicationStatuses.HOLD_MEETING_REJECTED) {
            this.setState({
                rejectMeetingDialogOpen: true,
            });
            return ()=>()=>{};
        }
        //in v1.2 userMsgs feature principal should be in the last verify usermsg
        //if status is else than verify skip principal dialog
        else if(APPLICATION_NEW_GOV_USER_MSGS_ENABLED ) {

            // const principal = APPLICATION_NEW_GOV_USER_MSGS_ENABLED ?
            //     [...get(record, 'userMsgs', [])].reverse().find(msg=>msg.status === CaaApplicationStatuses.VERIFY).principal
            //     :
            //     get(record, 'etc.principal');

            return this._handleStatusUpdate(APPLICATION_PRINCIPAL_OPERATOR);
        }
        else {
            this.setState({
                principalDialogOpen: false,
            });
            return ()=>()=>{};
        }
    };


    _handleRejectMeetingDialogClose = () => {
        this.setState({
            rejectMeetingDialogOpen: false,
        })
    };

    _handleRejectMeetingDialogConfirm = () => {
        const {rejectMeetingText, rejectMeetingTime0, rejectMeetingTime1, rejectMeetingTime2} = this.state;
        const submit = this._handleMeetingDialogConfirm(rejectMeetingText, rejectMeetingTime0, rejectMeetingTime1, rejectMeetingTime2);
        submit();
        this._handleRejectMeetingDialogClose();
    };

    _handleMeetingDialogConfirm = (rejectMeetingText, rejectMeetingTime0, rejectMeetingTime1, rejectMeetingTime2) => {
        const { basePath, dispatchApplicationStatus, decision, mySelf, record, resource, handleSubmit, status, handleSubmitWithRedirect} = this.props;
        //TODO when refacoring move logic to saga
        return handleSubmit(values => {

            if (!record.etc) {
                record.etc = {};
            }

            let userMsgs;
            if(APPLICATION_NEW_GOV_USER_MSGS_ENABLED) {
                userMsgs = defaultTo(
                    get(record, 'userMsgs'),
                    []
                );

                const meetingTimes = [rejectMeetingTime0, rejectMeetingTime1, rejectMeetingTime2];
                const meetings = [];
                meetingTimes.forEach(time => time ? meetings.push(time) : undefined);

                userMsgs.push({
                    timestamp: unixTimeNow(),
                    msg: rejectMeetingText,
                    meetings,
                    status
                });
            }
            else {
                record.etc.rejectedMsg = rejectMeetingText;
                record.etc.rejectedMeetingTime0 = rejectMeetingTime0;
                record.etc.rejectedMeetingTime1 = rejectMeetingTime1;
                record.etc.rejectedMeetingTime2 = rejectMeetingTime2;
            }




            dispatchApplicationStatus({
                etc: record.etc,
                uid: values.uid,
                status: status,
                resource,
                redirect: basePath,
                ...(APPLICATION_NEW_GOV_USER_MSGS_ENABLED && {userMsgs})
            });
        });
    };


    render() {
        const {principal, rejectMeetingText, rejectMeetingTime0, rejectMeetingTime1, rejectMeetingTime2}= this.state;
        const { handleSubmitWithRedirect, updateVerify, dispatchApplicationStatus, dispatchApplicationStatusWithValuesUpdate, userState, classes, translate, ...props } = this.props;
        console.log("update a", this.props);

        const rejectMeetingContent = () =>
            <>
                <TextField
                    error
                    id="outlined-error"
                    label={translate('resources.applications.rejectMeetingDialogLabel')}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    multiline
                    value={rejectMeetingText}
                    onChange={(event) => this.setState({
                        rejectMeetingText: event.target.value,
                    })}
                    required
                />
                <Labeled label={'resources.applications.rejectMeetingDates'} className={classes.textField}>
                    <>
                        <DateTimePickerLocale source="rejectMeetingTime0"
                                              label={translate('resources.applications.rejectMeetingDate', {number: 1})}
                                              onChange={(time) => this.setState({
                                                  rejectMeetingTime0: moment(time).utc().format(),
                                              })}
                                              className={classes.textField}
                                              validate={[required()]}
                        />
                        <DateTimePickerLocale source="rejectMeetingTime1"
                                              label={translate('resources.applications.rejectMeetingDate', {number: 2})}
                                              onChange={(time) => this.setState({
                                                  rejectMeetingTime1: moment(time).utc().format(),
                                              })}
                                              className={classes.textField}
                        />
                        <DateTimePickerLocale source="rejectMeetingTime2"
                                              label={translate('resources.applications.rejectMeetingDate', {number: 3})}
                                              onChange={(time) => this.setState({
                                                  rejectMeetingTime2: moment(time).utc().format(),
                                              })}
                                              className={classes.textField}
                        />
                    </>
                </Labeled>
            </>;

        return (
            <>
                <SaveButton
                    handleSubmitWithRedirect={this._handleClick}
                    className={classes.root}
                    {...props}
                />
                
                <MyInputDialog title={'resources.applications.rejectMeetingDialogTitle'}
                               confirm={'myroot.action.send'}
                               cancel={'ra.action.cancel'}
                               onConfirm={this._handleRejectMeetingDialogConfirm}
                               onClose={this._handleRejectMeetingDialogClose}
                               isOpen={this.state.rejectMeetingDialogOpen}
                               confirmColor={'warning'}
                               content={rejectMeetingContent}
                               confirmDisabled={!this.state.rejectMeetingTime0 || !rejectMeetingText}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch =>
    (
        {
            dispatchApplicationStatus : (e) => {
                console.log('dispatchin applications status', e);
                dispatch(saveApplicationStatus(e))
            },
            dispatchApplicationStatusWithValuesUpdate : (e) => {
                console.log('dispatchin applications status', e);
                dispatch(saveApplicationStatusWithUpdate(e))
            },
        }
    );


const StatusChangeButton = compose(
    translate,
    withStyles(statusChangeButtonStyles),
    connect(
        state=>({userState: state.mySelf.userState}),
        mapDispatchToProps
    )
)(StatusChangeButtonView);


class SaveButtonWithStatusCheckView extends Component {

    state = {
        verificationDialogOpen: false,
    };

    _getRecordDifferences = (values) => {
        const {record} = this.props;
        const diffReport = objectDiff.diff(record, values);

        if(diffReport.changed === 'equal') return false;
        const diffs = {};

        for (let [key, value] of Object.entries(diffReport.value)) {
            if(value.changed !== 'equal') {
                diffs[key] = value;
                console.log(key, value);
            }
        }
        console.log("Diffs", diffs);
        return diffs;

    };

    _handleSaveWithCreatedStatus = () => {
        const {handleSubmit, dispatchApplicationStatusWithValuesUpdate, resource, basePath} = this.props;

        const getUserMsgsWithLostVerificationChecked = (userMsgs, govMsgs) => {
            const _userMsgs = [...userMsgs];

            //if created status comes again after some caa verificartion (lost verification issue) then add this status to userMsgs
            const hasBeenAccepted = govMsgs.some(msg=>msg.status === CaaApplicationStatuses.ACCEPTED);
            console.log('hasBeenAccepted', hasBeenAccepted);
            if(!hasBeenAccepted) return _userMsgs;

            //check if last msg is the created status one
            //new array creation due to muttable characteristic of reverse() fn
            const lostVerificationAlreadySaved = get([...userMsgs].reverse()[0], 'status') === CaaApplicationStatuses.CREATED;
            console.log('lostVerificationAlreadySaved', lostVerificationAlreadySaved);
            if(lostVerificationAlreadySaved) return _userMsgs;

            _userMsgs.push({
                timestamp: unixTimeNow(),
                status: CaaApplicationStatuses.CREATED
            });

            return _userMsgs;
    };

        const submit = handleSubmit((values) => {

            let _values = values;

            if(APPLICATION_NEW_GOV_USER_MSGS_ENABLED) {
                const userMsgs = defaultTo(
                    get(values, 'userMsgs'),
                    []
                );

                const govMsgs = defaultTo(
                    get(values, 'govMsgs'),
                    []
                );

                const _userMsgs = getUserMsgsWithLostVerificationChecked(userMsgs, govMsgs);

                _values = {
                    ...values,
                    userMsgs: _userMsgs
                };
            }


            dispatchApplicationStatusWithValuesUpdate({
                uid: values.uid,
                status: CaaApplicationStatuses.CREATED,
                values: _values,
                resource,
                redirect: basePath
            });
        });
        submit();
        this._handleDialogClose();
    };

    _handleDialogClose = () => {
        this.setState({
            verificationDialogOpen: false,
        })
    };

    _handleClick = () => {
         const { basePath, handleSubmit, redirect, resource, record, dispatchApplicationStatus, dispatchApplicationStatusWithValuesUpdate, handleSubmitWithRedirect } = this.props;

        if(record.status === CaaApplicationStatuses.ACCEPTED) {
            this.setState({
                verificationDialogOpen: true,
            });
            return ()=>()=>{};
        }
        else if(record.status === CaaApplicationStatuses.REJECTED || record.status === CaaApplicationStatuses.MODIFIED) {

            return handleSubmit((values) => {
                // leaving differences check on as it locks saving when no changes
                // TODO add dirty check to button
                const recordDiffs = this._getRecordDifferences(values);
                if(!recordDiffs) return null; //add notice to user that nothing changed
                dispatchApplicationStatusWithValuesUpdate({
                    uid: values.uid,
                    status: CaaApplicationStatuses.MODIFIED,
                    values: values,
                    // issue #405 not supported now - disabling for optimization
                    // ...(recordDiffs && {diffs: recordDiffs}),
                    resource,
                    redirect: basePath
                });
            })
        }
        else return this._handleSaveWithCreatedStatus;
    };

    render() {
        const { handleSubmitWithRedirect, updateVerify, dispatchApplicationStatus, dispatchApplicationStatusWithValuesUpdate, formInitialValues, ...props } = this.props;
        console.log("update a", this.props);

        return (
            <>
                <SaveButton
                    handleSubmitWithRedirect={this._handleClick}
                    {...props}
                />
                <MyInputDialog title={'resources.applications.verificationDialogTitle'}
                               label={'resources.applications.verificationDialogLabel'}
                               confirm={'myroot.auth.confirm'}
                               cancel={'ra.action.cancel'}
                               onConfirm={this._handleSaveWithCreatedStatus}
                               onClose={this._handleDialogClose}
                               isOpen={this.state.verificationDialogOpen}
                               confirmColor={'warning'}
                               noInput
                />
            </>
        );
    }
}

const SaveButtonWithStatusCheck = connect(
    (state) => ({formInitialValues: getFormInitialValues('record-form')(state),}),
    mapDispatchToProps
)(SaveButtonWithStatusCheckView);


const caaButtonStyles = theme => ({
    root: {
        "&:not(:first-child)": {
            marginLeft: theme.spacing.unit
        }
    },
    accepted: {
        backgroundColor: _getStatusColor('accepted'),
    },
    rejected: {
        backgroundColor: _getStatusColor('rejected')
    },
    textField: {
        width: "100%",
        marginRight: "0",
    },
});

class CaaDecisionButtonView extends Component {

    state = {
        rejectDialogOpen: false,
        meetingDialogOpen: false,
        meetingText: "",
        meetingTime: "",
    };

    _handleClick = () => {
        const {decision} = this.props;
        if(decision === CaaApplicationStatuses.REJECTED) {
            this.setState({
                rejectDialogOpen: true,
            });
            return ()=>()=>{};
        }
        else if(decision === CaaApplicationStatuses.HOLD_MEETING_REQUEST) {
            this.setState({
                meetingDialogOpen: true,
            });
            return ()=>()=>{};
        }
        else return this._handleDecision();
    };

    _handleRejectDialogClose = () => {
        this.setState({
            rejectDialogOpen: false,
        })
    };

    _handleMeetingDialogClose = () => {
        this.setState({
            meetingDialogOpen: false,
        })
    };

    _handleRejectDialogConfirm = (message) => {
        const submit = this._handleDecision(message);
        submit();
        this._handleRejectDialogClose();
    };


    _handleMeetingDialogConfirm = () => {
        const {meetingText, meetingTime} = this.state;
        const submit = this._handleDecision(meetingText, meetingTime);
        submit();
        this._handleMeetingDialogClose();
    };

    _handleDecision = (message = null, time = null) => {
        const {dispatchApplicationStatus, decision, mySelf, record, resource, handleSubmit, handleSubmitWithRedirect} = this.props;

        return handleSubmit(values => {

            if (!record.etc) {
                record.etc = {};
            }

            let govMsgs;
            if(APPLICATION_NEW_GOV_USER_MSGS_ENABLED) {
                govMsgs = defaultTo(
                    get(record, 'govMsgs'),
                    []
                );

                govMsgs.push({
                    timestamp: unixTimeNow(),
                    ...(message && {msg: message}),
                    ...(time && {meetings: [time]}),
                    issuerEmail: mySelf.email,
                    // issuerUnitId: defaultUnit.uid, // TODO do we need it?
                    // issuerUnitName: defaultUnit.name // TODO do we need it?
                    status: decision
                });
            }
            else {
                record.etc.caaStatusIssuerEmail = mySelf.email;
                record.etc.msg = message;
                record.etc.meeting = time;
            }



            const defaultUnit = mySelf.units[0]; // TODO fix it user can have multi units


            console.log('==== CaaDecisionButtonView, record', record);

            dispatchApplicationStatus({
                etc: record.etc, //hacking missing public govs data (email)
                govid: mySelf.uid,
                uid: record.uid,
                status: decision,
                resource,
                redirect: '/',
                govMsgs
            });
        });
    };

    render() {
        const { handleSubmitWithRedirect, updateVerify, dispatchApplicationStatus, dispatchApplicationStatusWithValuesUpdate, classes, decision, mySelf, translate, ...props } = this.props;
        console.log("update a", this.props);
        const {meetingText, meetingTime} = this.state;

        const meetingContent = () =>
            <>
                <TextField
                    error
                    id="outlined-error"
                    label={translate('resources.applications.meetingDialogLabel')}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    placeholder={translate('resources.applications.meetingDialogDefaultText')}
                    multiline
                    value={meetingText}
                    onChange={(event) => this.setState({
                        meetingText: event.target.value,
                    })}
                    required
                />
                <DateTimePickerLocale source="start"
                                      label={'resources.applications.meetingDate'} //required for time picker
                                      onChange={(time) => this.setState({
                                          meetingTime: moment(time).utc().format(),
                                      })}
                                      className={classes.textField}
                                      validate={[required()]}
                />
            </>;

        return (
            <>
                <SaveButton
                    className={classnames(classes.root, {
                        [classes.accepted]: decision === CaaApplicationStatuses.ACCEPTED,
                        [classes.rejected]: decision === CaaApplicationStatuses.REJECTED,
                    })}
                    handleSubmitWithRedirect={this._handleClick}
                    {...props}
                />
                <MyInputDialog title={'resources.applications.rejectDialogTitle'}
                               placeholder={'resources.applications.rejectDialogDefaultText'}
                               label={'resources.applications.rejectDialogLabel'}
                               confirm={'myroot.action.send'}
                               cancel={'ra.action.cancel'}
                               onConfirm={this._handleRejectDialogConfirm}
                               onClose={this._handleRejectDialogClose}
                               isOpen={this.state.rejectDialogOpen}
                               confirmColor={'warning'}/>
                <MyInputDialog title={'resources.applications.meetingDialogTitle'}
                               label={''}
                               confirm={'myroot.action.send'}
                               cancel={'ra.action.cancel'}
                               onConfirm={this._handleMeetingDialogConfirm}
                               onClose={this._handleMeetingDialogClose}
                               isOpen={this.state.meetingDialogOpen}
                               confirmColor={'warning'}
                               content={meetingContent}
                               confirmDisabled={!meetingText || !meetingTime}
                >

                </MyInputDialog>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        //isLoading: state.admin.loading > 0,
        //myAuth: state.myAuth,
        mySelf: state.mySelf,
    }
};


const CaaDecisionButton = compose(
    translate,
    withStyles(caaButtonStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(CaaDecisionButtonView);
