import React, {Component} from 'react';
import { AutocompleteInput, Filter, Responsive, SimpleList, Edit, Create, Datagrid, ReferenceField, DateInput, TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, BooleanInput, RadioButtonGroupInput , required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
//import { DependentInput } from 'aor-dependent-input';
import {connect} from 'react-redux'

import {find} from 'lodash';
import { bindActionCreators } from 'redux'
import { reduxForm, change } from 'redux-form';
import BasicListActions from "../MyBasicListActions"
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";

export const InsurancesList = ({permissions, ...props}) => (

    <List {...props}
          title={'resources.insurances.menu'}
          bulkActions={false}
          actions={<BasicListActions />}
          pagination={<MyPagination/>}

    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="country_code" />
            <TextField source="insurance" />
            <EditButton />

        </Datagrid>
    </List>
);

export const InsurancesEdit = ({permissions, ...props}) => (
    <Edit {...props}
          undoable={false}
    >
        <SimpleForm //form={'insurance-edit-form'} // must be default
                    defaultValue={{}}
        >
            fixme DependentInput

            <DisabledInput source="id"
                           fullWidth />

            <DisabledInput source="country_code"
                           validate={[ required, minLength(2), maxLength(2) ]} />
            <DisabledInput source="insurance"
                           validate={[ required, minLength(2), maxLength(15) ]}/>
            {/*
            <DependentInput dependsOn="ID">
                <TextInput source="ID"/>
            </DependentInput>
            <DependentInput dependsOn="validThru">
                <TextInput source="validThru"/>
            </DependentInput>
            */}
        </SimpleForm>
    </Edit>
);


//another approach to values update..
class InsurancesCreateWithStore extends Component {
    constructor(props) {
        super(props);
        this.resolveVisibility = this.resolveVisibility.bind(this);
    }

    //test for fields render
    resolveVisibility(field) {
        console.log('resolveVisibility', field, this.props.selectedInsuranceType);
        if (this.props.selectedInsuranceType.fields) {
            console.log('f', this.props.selectedInsuranceType.fields[field])
        }

        const ret= (this.props.selectedInsuranceType
            && this.props.selectedInsuranceType.fields
            && this.props.selectedInsuranceType.fields[field])
            ? this.props.selectedInsuranceType.fields[field]
            : false;
        console.log('ret', ret);
        return ret;
    }

    //since onchange sucks, this seems to be the best place to pass form filling
    componentDidUpdate(prevProps) {

        if (prevProps.selectedInsuranceType.insurance_code !== this.props.selectedInsuranceType.insurance_code) {
            console.warn('filling new selection FIXME', this.props.selectedInsuranceType);

            //this.props.change('record-form','country_code',this.props.selectedInsuranceType.country_code);

        }

    }

    render() {
        console.log('insurance render', this.props, this.state);
        //this.fillCountryCode();
        return (
            <Create {...this.props}
                    /* undoable={false} -  no undo in create anyway */
                    title={'resources.drones.create'}
            >
                fixme DependentInput
                <SimpleForm //form={'insurance-create-form'} // must be default
                            defaultValue={{}}
                >
                    <ReferenceInput label="Insurance Models"
                                    source="insurance"
                                    reference="insuranceTypes"
                                    onChange={(e) => console.log('ref change',e)}
                                    allowEmpty>

                        <SelectInput source="code_id"
                                     optionText="insurance_code"
                                     optionValue="insurance_code"
                        />

                    </ReferenceInput>
                    <DisabledInput source="country_code"
                                   validate={[required(), minLength(2), maxLength(2)]}
                    />
                    {/*
                    <DependentInput resolve={() => this.resolveVisibility('hasID') }>
                        <TextInput source="ID"/>
                    </DependentInput>
                    <DependentInput resolve={() => this.resolveVisibility('hasValidThru') }>
                        <DateInput source="validThru" options={{
                            container : "dialog",
                            openToYearSelection : true
                        }} />
                    </DependentInput>
                    */}

                </SimpleForm>
            </Create>
        )
    };
}

//fakap with dynamic choices list
//<DependentInput resolve={() => this.resolveVisibility('hasClass') }>
//<RadioButtonGroupInput source="class" choices={choices} optionText={optionRenderer} />
//</DependentInput>*/

const mapStateToProps = state => {
    let selectedInsuranceType;

    const insuranceTypes =  state.admin.resources.insuranceTypes;
    const form = state.form["record-form"];
    console.log('qqq mapStateToProps', state, insuranceTypes, form);

    if (form && form.values && form.values.insurance) {
        selectedInsuranceType = find(insuranceTypes.data, {'insurance_code': form.values.insurance});
        console.log('--- yyy has sel model', selectedInsuranceType);

    } else {
        selectedInsuranceType = {country_code:''};
    }

    return {
        //insuranceTypes : state.admin.resources.insuranceTypes,
        form : state.form["record-form"],
        selectedInsuranceType : selectedInsuranceType,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({change}, dispatch);
};

export const InsurancesCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(InsurancesCreateWithStore);

//props defs todo

