import EditorMap from "./NewEditorMap";
import {EditorMapHeightBox, makeHeightBundleForEditor, VieverMapHeightBox} from "../MyHeightBoxes";
import {MissionZoneLegsCntFormatter} from "../../../dr_ra2/components/resources/Missions";
import React from "react";
import Button from '@material-ui/core/Button';
import {RndDrawToolbar} from "../DeckGLDrawToolbar";
import {invertColorToCSSHexString} from "../../../dr_ra2/utils/cssColorInverter";
import {withStyles} from "@material-ui/core";

import compose from 'recompose/compose';
import {connect} from "react-redux";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

import MyVerticalSlider from "../../../mui_test_suite/customComponents/MyVerticalSlider";
import {storeUiZonesV2Show} from "../../../LogicV1Redux/actions/MapUxConfigActions"
import {EditorMapStateConstructor} from "../../glSetups/newCommon/mapStateConstructors";
import BtnSwitchAllZonesV2 from './BtnSwitchAllZonesV2';
import BtnSwitchSateliteMap from './BtnSwitchSateliteMap';
import MapOptionsToolbar from "./MapOptionsToolbar";
import MyGeomInput from "../../../dr_ra2/components/MyGeomInput";
import MyGeomOutput from "../../../dr_ra2/components/MyGeomOutput";
import {isSuperAdmin} from "../../../dr_ra2/utils/tempCommon";

const styles = theme => ({
    slider: {
        position: "absolute",
        borderRadius: "5px",
        padding: "10px 10px",
        width: "50px",
        top: "5px",
        bottom: "40px",
        right: "10px",
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.75),
        display: "flex",
        flexDirection: "column",
    },
    amslBox: {
        position: "absolute",
        borderRadius: "5px",
        padding: "10px 10px",
        // width: "50px",
        // top: "40px",
        bottom: "40px",
        left: "10px",
        fontSize: "smaller",
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.75),
    },
    //draft!
    toggleButtons: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    }
});

//currently lighter version of mission editor ui..
//when finished (after sprints), check if components can be unified

class ZoneEditorMapUI extends React.Component {

    constructor() {
        super();
        this.state = {useSatelliteMap:false};

        this.onSatelliteToggle = this.onSatelliteToggle.bind(this);
        this.onZonesToggle = this.onZonesToggle.bind(this);

        this.onMinMaxChange = this.onMinMaxChange.bind(this);


    };

    //old approach.. as fast fix
    componentDidMount() {
        //console.log('=====btn subscribin ')
        this.pubsubOnMissionsLegsDirty = PubSub.subscribe(PubSubSignals.SET_DRAW_EDITOR_DIRTY, (msg, data) => {
            //console.log('=======on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
            //this.setState({legsDirty:true});
            this.forceUpdate()

        });

    }

    componentWillUnmount() {
        //console.log('=====btn unmounting ')

        PubSub.unsubscribe(this.pubsubOnMissionsLegsDirty);
    }


    onSatelliteToggle() {
        console.log('switch map here');
        this.setState({useSatelliteMap: !this.state.useSatelliteMap});

    }

    onZonesToggle() {
        console.log('switch zones here');
        this.props.storeUiZonesV2Show(!this.props.uiZonesV2Show);
    }

    //should use feets?
    //temporarily disabling slider anyway for zone edit
    onMinMaxChange = (value) => {
        console.warn("oac disabled temporarily", value);
        // //aglMinMax = value;
        // if (this.props.currentEditablePrimitive)
        //     this.props.currentEditablePrimitive.aglMinMax = value;
        //
        // PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);
        // //ugh
        // this.forceUpdate()
    };

    render() {

        console.log('=======ZoneEditorMapUI', this.props);
        const {wrapperStyle, bBox, currentEditablePrimitive, editablePrimitives, classes, uiZonesV2Show} = this.props;

        const {useSatelliteMap} = this.state;

        const heightBundle = makeHeightBundleForEditor(currentEditablePrimitive, editablePrimitives);

        return (
            <>
            <div style={wrapperStyle}>

                <EditorMap

                    //primitives={primitives}
                    //editables={editablePrimitives}
                    //autofocus={legsPrimitives}
                    //menuStore={{showWarnings: true, showZonesV2: true}} //FIXME!
                    //menuComponent={null}
                    bBox = {bBox}
                    useSatelliteMap = {useSatelliteMap}
                />

                <div className={classes.toggleButtons}>
                    <MapOptionsToolbar {...{
                        useSatelliteMap,
                        onSatelliteToggle: this.onSatelliteToggle,
                        uiZonesV2Show,
                        onZonesToggle: this.onZonesToggle}}
                    />
                </div>

                <RndDrawToolbar/>

                {/*temporarily disabling slider in zone.. should be in feet?*/}
                {/*<div className={classes.slider}>*/}

                    {/*<MyVerticalSlider min={0} max={300}*/}
                        {/*//defaultValue={[0, 150]}*/}
                                      {/*value={heightBundle.aglMinMax} //ugh?*/}
                                      {/*marks={{100: '100m', 200: '200m', 300: '300m'}}*/}
                                      {/*step={10}*/}
                                      {/*onChange={this.onMinMaxChange}*/}
                        {/*//onAfterChange={onAfterMinMaxChange}*/}
                    {/*/>*/}
                {/*</div>*/}

                {/*<EditorMapHeightBox showBox={heightBundle.showAmslBox}  heightValuesBundle={heightBundle}/>*/}


                </div>
                {(isSuperAdmin()) //temp override (again) for non superadmin user, till input errors are better supported
                    ? <MyGeomInput bBox={bBox} currentEditablePrimitive={currentEditablePrimitive}/>
                    : <MyGeomOutput record={this.props.record}/>
                }
            </>
        )
    }

}

//hmmm, used by amsl box AND vertical slider....
const mapStateToProps = state => {

    const drawEditorData = state.drawEditorData;
    const currentEditablePrimitive = drawEditorData.currentPrimitive;
    const currPrimitiveCentroid = (currentEditablePrimitive)?currentEditablePrimitive.centroid:null;

    return {
        //mySelf : state.mySelf,
        //myAppExtras: state.myAppExtras,
        //inspectData: state.inspectData,
        editablePrimitives : drawEditorData.editablePrimitives,
        currentEditablePrimitive,
        currPrimitiveCentroid, //required to update dirtiness .. not working on move, fixme
        uiZonesV2Show: state.mapUxConfig.uiZonesV2Show,
    }
};

const enhance = compose(
    withStyles(styles),
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            storeUiZonesV2Show,
        },
    )
);

export default enhance(ZoneEditorMapUI);
