import {AutocompleteInput, ReferenceInput} from "ra-ui-materialui";
import React from "react";


export default ({inputStyles, ...props}) =>
    <ReferenceInput
        {...props}
        sort={{field: 'email', order: 'ASC'}}
        // filter={{ "lastName": { "neq": null } }}
        filterToQuery={searchText => (
            {
                or: [
                    {email: {ilike: searchText + '%'}},
                    {firstName: {ilike: searchText + '%'}},
                    {lastName: {ilike: searchText + '%'}},
                    {username: {ilike: searchText + '%'}},
                    {nickName: {ilike: searchText + '%'}}
                ]
            }
        )
        }
    >
        <AutocompleteInput
            optionText={choice => {
                const {email, firstName, lastName, username, nickName} = choice;
                return `${email ? email : ''}${firstName ? ', ' + firstName : ''}${lastName ? ' ' + lastName : ''}${username ? ', ' + username : ''}${nickName ? ', ' + nickName : ''}`;

            }}
            options={{
                style: inputStyles ? inputStyles : {width: '305px'},
                inputProps: {
                    onKeyDown:(e)=>{
                        const {key} = e;
                        if(key === ' ') e.preventDefault();
                    }
                }
            }}
            //never match automatically due to fetch can be in progress
            inputValueMatcher={()=>false}
        />
    </ReferenceInput>
;