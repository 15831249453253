export const RED_LIGHT_RPA = '#FFBDBD';
export const YELLOW_LIGHT_RPA = '#FFFFBD';
export const GREEN_LIGHT_RPA = '#BDFFBD';
export const BLUE_LIGHT_RPA = '#BDBDFF';

export const RPA_LIGHT_COLORS = {
    red: RED_LIGHT_RPA,
    yellow: YELLOW_LIGHT_RPA,
    green: GREEN_LIGHT_RPA,
    blue: BLUE_LIGHT_RPA
};

export const colorListStyles = {
    rowCell: {
        '&:nth-child(1n+7)': {
            width: '140px',
            textAlign: 'center',
        },
        '&:nth-child(6)': {
            backgroundColor: RED_LIGHT_RPA,
            width: '10%'
        },
        '&:nth-child(5)': {
            backgroundColor: YELLOW_LIGHT_RPA,
            width: '10%'
        },
        '&:nth-child(4)': {
            backgroundColor: GREEN_LIGHT_RPA,
            width: '10%'
        },
        '&:nth-child(3)': {
            backgroundColor: BLUE_LIGHT_RPA,
            width: '10%'
        },
    }
};