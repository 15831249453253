import {Toolbar} from "ra-ui-materialui";
import React from "react";
import PoiSaveActionButton from "./PoiSaveActionButton";

//currently the same as ZoneSaveToolbar, but for poi


const PoiSaveToolbar = props => (

    <Toolbar  >
        <PoiSaveActionButton
            //submitOnEnter={true}
            {...props}
            label={'ra.action.save'}

        />
    </Toolbar>
);

export default PoiSaveToolbar;
