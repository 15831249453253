import {OperatorMsgBlockField} from "./OperatorMsgBlockField";
import React from "react";
import {CaaMsgBlockField} from "./CaaMsgBlockField";
import {Divider, withStyles} from "@material-ui/core";
import Labeled from "ra-ui-materialui/lib/input/Labeled";
import {Field} from "redux-form";
import {translateCaaStatusToTypo, translatePrincipalToTypo} from "../../../utils/applicationsHelpers";
import has from "lodash.has";
import TranslatableTypography from "../../TranslatableTypography";
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {compose} from "redux";
import {CaaConversationField} from "./CaaConversationField";
import classnames from "classnames"
import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";

const APPLICATION_NEW_GOV_USER_MSGS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.APPLICATION_NEW_GOV_USER_MSGS);

const styles = (theme) => ({
    table: {
        width: "100%"
    },
    paper: {
        backgroundColor: theme.palette.colorCircle.second,
    },
    body: {
        verticalAlign: 'initial'
    },
    row: {
        marginBottom: "1rem"
    },
    column: {
        padding: "4px 4%",
        width: "50%"
    },
    innerColumn: {
        minWidth: "4rem",
        alignSelf: "center",
        textAlign: "center",
    },
    f_20: {
        flexBasis: '100%',
        flex: 2
    },
    textRotate: {
        transform: "rotate(90deg)"
    },
    pushColumn: {
        flexBasis: '100%',
        flex: "auto"
    },
    tableOverlow: {
        overflowX: "auto",
    }
});

export const CaaMessagesBlockField = compose(
    withStyles(styles),
    withWidth()
)(
    ({record, width, classes, notInForm}) => {

        const verificationStatus = 
            <Labeled label={'resources.applications.verificationStatus'}>
                {
                    notInForm ?
                        translateCaaStatusToTypo(record.status)
                        :
                        (<Field name='status' component={() => translateCaaStatusToTypo(record.status)}/>)
                }
            </Labeled>;

        const principal = 
            <Labeled label={'resources.applications.principal'}>
                {
                    notInForm ?
                        translatePrincipalToTypo(record.etc.principal)
                        :
                        (<Field name='etc.principal' component={() => translatePrincipalToTypo(record.etc.principal)}/>)
                }
            </Labeled>;

        return isWidthUp('md', width) ?
            (
                <>
                    <Divider/>
                    <div className={classnames(classes.tableOverlow, classes.paper)}>
                    <table className={classes.table}>
                        <tbody className={classes.body}>
                        <tr className={classes.row}>
                            <td className={classes.column}>
                                <TranslatableTypography content={'resources.applications.verification'}
                                                        variant="title"/>
                            </td>
                        </tr>
                        <tr className={classes.row}>
                            <td className={classes.column}>
                                {verificationStatus}
                            </td>
                            {
                                has(record, 'etc.principal') &&
                                <td className={classes.column}>
                                    {principal}
                                </td>
                            }
                        </tr>

                        <tr className={classes.row}>
                            <td className={classes.column}>
                                <CaaMsgBlockField record={record} notInForm={notInForm}/>
                            </td>
                            <td className={classes.column}>
                                <OperatorMsgBlockField record={record} notInForm={notInForm}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                        {
                            APPLICATION_NEW_GOV_USER_MSGS_ENABLED &&
                            <CaaConversationField record={record} notInForm={notInForm} className={classes.paper}/>
                        }
                    </div>
                    <Divider/>
                </>
            )
            :
            (
                <>
                    <Divider/>
                    <div className={classes.paper}>
                        <div className={classes.row}>
                            <div className={classes.column}>
                                <TranslatableTypography content={'resources.applications.verification'}
                                                        variant="title"/>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.column}>
                                {verificationStatus}
                            </div>

                            <div className={classes.column}/>
                            {
                                has(record, 'etc.principal') &&
                                <div className={classes.column}>
                                    {principal}
                                </div>
                            }
                        </div>

                        <div className={classes.row}>
                            <div className={classes.column}>
                                <CaaMsgBlockField record={record} notInForm={notInForm}/>
                            </div>
                            <div className={classes.column}>
                                <OperatorMsgBlockField record={record} notInForm={notInForm}/>
                            </div>
                        </div>
                        {
                            APPLICATION_NEW_GOV_USER_MSGS_ENABLED &&
                            <div className={classes.row}>
                                <CaaConversationField record={record} notInForm={notInForm}/>
                            </div>
                        }
                    </div>
                    <Divider/>
                </>
            )
    });