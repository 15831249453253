
import React from "react";
import ZoneEditorMapUI from '../../../../gl_map/Components/Maps/ZoneEditorMapUI';
import Configs from "../../../../Configs";
//import MissionConflictsSwitch from './MissionConflictsSwitch';
//1.1 editor (no full screen link)
//refactoring WIP...

//right now could be moved to zonefield
//or contrary -> unify editor setup here

const ZoneEditor = ({record, conflicts, primitives, legsPrimitives, myAppExtras, bBox, ...rest}) => {
    const wrapperStyle = {position: "relative", height: `${Configs.MAP_SIZE}px`};


    //currently zone editor!
    console.log('ZoneEditor, legsPrimitives', legsPrimitives);
    return (
        <div>
            {/*<MissionConflictsSwitch*/}
                {/*conflicts={conflicts}*/}

                {/*//record={record}*/}
                {/*{...rest}*/}
            {/*/>*/}

            <ZoneEditorMapUI
                bBox = {bBox}
                //heightBundle = {heightBundle}
                wrapperStyle = {wrapperStyle}
                record = {record}
                //inspectData = {inspectData}
            />
        </div>
    )
};

export default ZoneEditor;