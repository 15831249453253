import {
    TOGGLE_SETTINGS_VIEW,
    TOGGLE_PROFILE_VIEW,
    TOGGLE_NOTIFIACTION_VIEW,
    TOGGLE_VIEW_VIEW,
    TOGGLE_ABOUT_VIEW,
    TOGGLE_COMMERCIAL_SERVICES_VIEW, TOGGLE_NICKNAME_DIALOG
} from "./MySettingsActions";

const initialState = {
    settingsView: {
        visible: false,
        eventTarget: {},
    },
    profileView: {
        visible: false,
    },
    notificationView: {
        visible: false,
    },
    viewView: {
        visible: false
    },
    aboutView: {
        visible: false
    },
    commercialServicesView: {
        visible: false
    },
    nickNameDialog: {
        visible: false,
        nicknames: []
    }
};

export default (state = initialState, {type, eventTarget, show, nicknames}) => {
    switch (type) {
        case TOGGLE_SETTINGS_VIEW:
            return {
                ...state,
                //prevent change when closing css transition is on route - components onClose() listener still active
                settingsView: {
                    visible: !!eventTarget ? !state.settingsView.visible : false,
                    eventTarget: eventTarget
                },
            };
        case TOGGLE_PROFILE_VIEW:
            return {
                ...state,
                profileView: {
                    visible: show !== undefined ? show : !state.profileView.visible,
                }
            };
        case TOGGLE_NOTIFIACTION_VIEW:
            return {
                ...state,
                notificationView: {
                    visible: show !== undefined ? show : !state.notificationView.visible,
                }
            };
        case TOGGLE_VIEW_VIEW:
            return {
                ...state,
                viewView: {
                    visible: show !== undefined ? show : !state.viewView.visible,
                }
            };
        case TOGGLE_ABOUT_VIEW:
            return {
                ...state,
                aboutView: {
                    visible: show !== undefined ? show : !state.aboutView.visible,
                }
            };
        case TOGGLE_COMMERCIAL_SERVICES_VIEW:
            return {
                ...state,
                commercialServicesView: {
                    visible: show !== undefined ? show : !state.commercialServicesView.visible,
                }
            };
        case TOGGLE_NICKNAME_DIALOG:
            return {
                ...state,
                nickNameDialog: {
                    visible: show !== undefined ? show : !state.nickNameDialog.visible,
                    nicknames
                }
            };
        default:
            return state;
    }
}