import { GET_ZONECONFLICTS,  RESOLVE_ZONECONFLICT} from "../constants/ZoneLegsV2Constants";

import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

//old early approach moved during refactoring.. create custom saga?

export const getZoneConflictsAction = ({id, data, basePath}) => ({
    type: GET_ZONECONFLICTS,
    payload: {id}, //, data: {...data, is_approved: true}},
    meta: {
        resource: 'zones',
        fetch: API_VERBS.GET_ONE,
        // onSuccess: {
        //     notification: {
        //         body: 'resources.comments.notification.approved_success',
        //         level: 'info',
        //     },
        //     //redirectTo: '/comments',
        //     //basePath,
        // },
        onFailure: {
            notification: {
                body: 'ra.notification.http_error',
                level: 'warning',
            },
        },
    },
});

export const resolveZoneConflictAction = ({id, data, basePath}) => ({
    type: RESOLVE_ZONECONFLICT,
    payload: {id, data}, //, data: {...data, is_approved: true}},
    meta: {
        resource: 'zoneLegs',
        fetch: API_VERBS.UPDATE,
        onSuccess: {
            refresh:true
            // notification: {
            //     body: 'resources.comments.notification.approved_success',
            //     level: 'info',
            // },
            //redirectTo: '/comments',
            //basePath,
        },
        onFailure: {
            notification: {
                body: 'ra.notification.http_error',
                level: 'warning',
            },
        },
    },
});
