
import React from "react";

import ZoneViewerMapUI from '../../../../gl_map/Components/Maps/ZoneViewerMapUI';
import Configs from "../../../../Configs";
//1.1 react admin viewer (no full screen link)
//refactoring WIP...


const ZoneViewer = (props) => {

    const {
        record,
        helpers,
        conflicts,
        showGeometry,

        bBox,
        ...rest
    } = props;


    const wrapperStyle = {position: "relative", height: `${Configs.MAP_SIZE}px`};

    return (
        <ZoneViewerMapUI
            bBox = {bBox}
            //inspectData = {inspectData}
            wrapperStyle = {wrapperStyle}
            record = {record}
            showGeometry = {showGeometry}
        />
    );
};

export default ZoneViewer;
