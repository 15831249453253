import {ActivityPeriodMode, ActivityRepeatMode} from "../../LogicV1Redux/constants/NotamsConstants";
import moment from "moment";

const LOGS_ENABLED = true;

const logger = {
    log: function (...args) {
        if (LOGS_ENABLED) console.log('%cNotam Diurnal Composer >>>>', 'color: green', ...args);
    },
    groupBegin: function () {
        try {
            console.group()
        } catch (e) {
            console.log('no console.group() supported')
        }
    },
    groupEnd: function () {
        try {
            console.groupEnd()
        } catch (e) {
            console.log('no console.groupEnd() supported')
        }
    },

};


export const notamMoment = (time) => moment.utc(time).locale('en-notam');

export const getDiurnal = (validStart, validStop, activityDef) => {

    if(!moment.isMoment(validStart) || !moment.isMoment(validStop)) {
        throw new Error(`Notam diurnal composer - validStart or validStop time is not a moment.js object. Got: ${validStart}, ${validStop}`);
    }

    if(!isPeriod({start: validStart, stop: validStop})) {
        throw new Error(`Notam diurnal composer - validity period error. Period: ${validStart}-${validStop}} is not a period! validStop should be later than validStart!`)
    }

    if(!activityDef) {
        throw new Error(`Notam diurnal composer - wrong activityDef arg! Got ${activityDef}`)
    }

    const {periodMode} = activityDef;

    switch (periodMode) {
        case ActivityPeriodMode.SINGLE:
            const {begin, end} = activityDef;
            if(!begin || !end) {
                throw new Error(`Notam diurnal composer - no begin or end time in ${ActivityPeriodMode.SINGLE} mode. Got: ${begin}, ${end}`);
            }
            return _getDiurnalSingleMode({validStart, validStop, begin, end});

        case ActivityPeriodMode.REPEAT:
            //hourSlots default H24
            const {months, days, weekdays, hourSlots = [{}], repeatMode} = activityDef;
            if(!repeatMode) {
                throw new Error(`Notam diurnal composer - no repeatMode in ${ActivityPeriodMode.REPEAT} mode. Got: ${repeatMode}`);
            }

            return _getDiurnalRepeatMode({validStart, validStop, months, days, weekdays, hourSlots, repeatMode});

        default:
            throw new Error(`Notam diurnal composer - invalid activity period mode. Got: ${periodMode}!`);
    }
};

const _normalizeHourSlots = ({hourSlots}) => {
    const nSlots = [];
    for(const hourSlot of hourSlots) {
        const {begin, end} = hourSlot;
        if(begin && end) {
            nSlots.push({begin, end})
        }
    }
    return nSlots;
};

const _getDiurnalSingleMode = ({validStart, validStop, begin, end}) => {
    if(!moment.isMoment(begin) || !moment.isMoment(end)) {
        throw new Error(`Notam diurnal composer - begin or end time in ${ActivityPeriodMode.SINGLE} mode is not a moment.js object. Got: ${begin}, ${end}`);
    }
    if(!isPeriodInPeriod({start: validStart, stop: validStop}, {start: begin, stop: end})) {
        throw new Error(`Notam diurnal composer - Activity period exceeds Notam validity period!`);
    }


    const isSameBeginEndMonth = notamMoment(begin).isSame(end, 'month');
    const isSameBeginEndDay = notamMoment(begin).isSame(end, 'day');

    const isSameValidStartStopMonth = notamMoment(validStart).isSame(validStop, 'month');
    const isSameValidStartStopDay = notamMoment(validStart).isSame(validStop, 'day');

    const beginTxtArr = notamMoment(begin).format('MMM D HHmm').split(" ");
    const endTxtArr = notamMoment(end).format('MMM D HHmm').split(" ");
    /**TODO convert to recurency**/

    const beginTxt = isSameValidStartStopMonth ?
        isSameValidStartStopDay ?
            beginTxtArr[2]
            :
            beginTxtArr[1] + " " + beginTxtArr[2]
        :
        beginTxtArr[0] + " " + beginTxtArr[1] + " " + beginTxtArr[2];

    const endTxt = isSameBeginEndMonth ?
        isSameBeginEndDay ?
            endTxtArr[2]
            :
            endTxtArr[1] + " " + endTxtArr[2]
        :
        endTxtArr[0] + " " + endTxtArr[1] + " " + endTxtArr[2];
    const activities = getActivities({validStart, validStop, actDef: [{begin, end, periodMode: ActivityPeriodMode.SINGLE}]});
    return {
        text: `${beginTxt}-${endTxt}`,
        activities
    };
};

const _getDiurnalRepeatMode = ({validStart, validStop, months = [], days, weekdays, hourSlots, repeatMode}) => {


    let rDays = [];
    let rMonths = [];

    if(months.length > 0) {
        rMonths = months.sort(
            (a,b) => {
                return a-b;
            });
    }
    if(!!weekdays && weekdays.length > 0) {
        rDays = weekdays.sort(
            (a,b) => {
                return a-b;
            });
    }
    if(!!days && days.length > 0) {
        rDays = days.sort(
            (a,b) => {
                return a-b;
            });
    }



    const slotsTxt = [];

    for(const slot of hourSlots) {
        const {begin, end} = slot;

        //XNOR operator http://www.howtocreate.co.uk/xor.html
        if(!(begin ? !end : end)) {

            let slotTxt = '';
            //H24 mode
            if(!begin && !end) {

                slotTxt = 'H24';
            }
            else {
                if (!moment.isMoment(begin) || !moment.isMoment(end)) {
                    throw new Error(`Notam diurnal composer - begin or end time in ${ActivityPeriodMode.REPEAT} mode is not a moment.js object. Got: ${begin}, ${end}`);
                }

                slotTxt = `${notamMoment(begin).format('HHmm')}-${notamMoment(end).format('HHmm')}`;
            }
            slotsTxt.push(slotTxt);
        }
        else {
            return {
                text: '',
                activities: [],
            };
        }
    }

    const activities = getActivities({validStart, validStop, actDef: [{validStart, validStop, months, days, weekdays, hourSlots, repeatMode, periodMode: ActivityPeriodMode.REPEAT}]});


    const rWeekdays = [];
    if(repeatMode === ActivityRepeatMode.WEEKDAYS) {

        rDays.forEach(weekday => rWeekdays.push(notamMoment().isoWeekday(weekday).format('ddd')));
    }

    logger.log('rmonths', rMonths);
    let txt = '';
    const getRMonth = () => {
        let output = '';
        if(rMonths.length > 0) {
            logger.log('rmonths in', rMonths);
            for(const month of rMonths) {
                logger.log('rmonths in in', month);
                output += notamMoment().month(month).format('MMM') + " ";
            }
        }
        logger.log("rmonths out", output);
        return output;
    };

    const rMonth = getRMonth();

    logger.log("rmonth", rMonth);


    const tDays = repeatMode === ActivityRepeatMode.WEEKDAYS ?  rWeekdays.join(" ") : rDays.join(" ");
    txt += rMonth + tDays;
    txt += txt ? " " + slotsTxt.join(" ") : slotsTxt.join(" ");
    return {
        text: txt,
        activities
    };

};


const _checkHourSlots = ({validStart, validStop, months, days, weekdays, begin, end}) => {
    return getPeriodsFromPeriod(
        {
            start: validStart,
            stop: validStop,
            months,
            days,
            weekdays,
            hourSlots: {
                begin,
                end
            }
        }
    ).length > 0;
};

const _isSlotInPeriod = ({validStart, validStop, beginHour, endHour, day, month, year}) => {

    const monthString = month;
    const beginString = `${year}-${monthString}-${day}T${beginHour}Z`;
    const endString = `${year}-${monthString}-${day}T${endHour}Z`;
    logger.log("check hour slot", beginString, endString);

    const validityPeriod = {start: validStart, stop: validStop};
    const activityPeriod = {start: notamMoment(beginString), stop: notamMoment(endString)};

    logger.log("check hour slot", validStart, validStop, notamMoment(beginString), notamMoment(endString));
    return isPeriodInPeriod(validityPeriod, activityPeriod);
};

export const isPeriodInPeriod = (outerPeriod, innerPeriod) => {
    if(!isPeriod(innerPeriod)) {
        throw new Error(`Notam diurnal composer - Period in period check error. Inner period: ${innerPeriod.start}-${innerPeriod.stop} is not a period! Stop should be later than start!`);
    }
    if(!isPeriod(outerPeriod)) {
        throw new Error(`Notam diurnal composer - Period in period check. Outer period: ${outerPeriod.start}-${outerPeriod.stop} is not a period! Stop should be later than start!`);
    }

    const {start, stop} = outerPeriod;

    const startI = innerPeriod.start;
    const stopI = innerPeriod.stop;

    return notamMoment(stopI).isAfter(startI) && notamMoment(startI).isBetween(start, stop, "minute", '[]') && notamMoment(stopI).isBetween(start, stop, "minute", '[]');
};

export const isPeriod = ({start, stop}) => {
    if(!moment.isMoment(start) || !moment.isMoment(stop)) {
        throw new Error(`Notam diurnal composer - isPeriod check error. Start or stop is not a moment.js object. Got: ${start}, ${stop}`);
    }
    return notamMoment(stop).isAfter(start);
};

export const getActivities = ({validStart, validStop, actDef}) => {
    if(!moment.isMoment(validStart) || !moment.isMoment(validStop)) {
        throw new Error(`Notam diurnal composer activities getter error - validStart or validStop time is not a moment.js object. Got: ${validStart}, ${validStop}`);
    }

    if(!isPeriod({start: validStart, stop: validStop})) {
        throw new Error(`Notam diurnal composer - validity period in getActivities error. Period: ${validStart}-${validStop}} is not a period! Stop should be later than start!`)
    }

    if(!Array.isArray(actDef)) {
        throw new Error(`Notam diurnal composer - activities getter error. actDef argument is not array! Got ${actDef}`)
    }

    let activities = [];
    for(const activityDef of actDef) {
        const {periodMode} = activityDef;

        switch (periodMode) {
            case ActivityPeriodMode.SINGLE:
                const {begin, end} = activityDef;
                if(!begin || !end) {
                    throw new Error(`Notam diurnal composer - activities getter error. No begin or end time in ${ActivityPeriodMode.SINGLE} mode. Got: ${begin}, ${end}`);
                }

                activities.push({start: moment(begin).startOf('minute').format(), stop: moment(end).startOf('minute').format()});
                break;
            case ActivityPeriodMode.REPEAT:
                const {months, days, weekdays, hourSlots} = activityDef;
                activities.push(...getPeriodsFromPeriod(
                    {
                        start: validStart,
                        stop: validStop,
                        months,
                        days,
                        weekdays,
                        hourSlots
                    }
                ));
                break;

            default:
                throw new Error(`Notam diurnal composer - activities getter error. Invalid activity period mode. Got: ${periodMode}!`);
        }
    }
    return activities;

};


export function getPeriodsFromPeriod ({start, stop, years, months, days, weekdays, hourSlots}={}) {
    if(!start || !stop) {
        throw new Error(`Notam diurnal composer - getPeriodFromPeriod error. No required start or stop argument. Got ${start}, ${stop}`)
    }
    if(!moment.isMoment(start) || !moment.isMoment(stop)) {
        throw new Error(`Notam diurnal composer - getPeriodFromPeriod error. Invalid start or stop argument - is not a moment js object. Got ${start}, ${stop}`)
    }

    const nHourSlots = _normalizeHourSlots({hourSlots});

    const hasYears = Array.isArray(years) && years.length > 0;
    const hasMonths = Array.isArray(months) && months.length > 0;
    const hasDays = Array.isArray(days) && days.length > 0;
    const hasWeekdays = Array.isArray(weekdays) && weekdays.length > 0;
    const hasHoursSlots = Array.isArray(nHourSlots) && nHourSlots.length > 0;

    let mYears = [];
    let mMonths = [];
    let mDays = [];
    let mHourSlots = [];


    const stopYear = notamMoment(stop).year();

    if(hasYears) {
        const startYear = notamMoment(start).year();
        for(let i = 0; notamMoment(start).year(startYear+i).startOf('year').isSameOrBefore(stop); i++) {
            if(years.includes(notamMoment(start).year(startYear+i).startOf('year').year())) {
                mYears.push(notamMoment(start).year(startYear + i).year());
            }
        }
    }
    else {
        const startYear = notamMoment(start).year();
        for(let i = 0; notamMoment(start).year(startYear+i).startOf('year').isSameOrBefore(stop); i++) {
            mYears.push(notamMoment(start).year(startYear+i).year());
        }
    }


    if(hasMonths) {
        const startMonth = notamMoment(start).month();
        for(let i = 0; notamMoment(start).month(startMonth+i).startOf('month').isSameOrBefore(stop); i++) {
            if(
                months.includes(notamMoment(start).month(startMonth+i).month()) &&
                mYears.includes(notamMoment(start).month(startMonth+i).year())
            ) {
                mMonths.push(notamMoment(start).month(startMonth+i).startOf('month').month());
            }

        }
    }
    else {
        const startMonth = notamMoment(start).month();
        for(let i = 0; notamMoment(start).month(startMonth+i).startOf('month').isSameOrBefore(stop); i++) {
            if(
                mYears.includes(notamMoment(start).month(startMonth+i).year())
            ) {
                mMonths.push(notamMoment(start).month(startMonth+i).startOf('month').month());
            }
        }
    }

    if(hasWeekdays) {
        const startDay = notamMoment(start).day();
        for(let i = 0; notamMoment(start).day(startDay+i).startOf('day').isSameOrBefore(stop); i++) {
            if(
                weekdays.includes(notamMoment(start).day(startDay+i).isoWeekday()) &&
                mMonths.includes(notamMoment(start).day(startDay+i).month())
            ) {
                mDays.push(notamMoment(start).day(startDay+i).startOf('day'));
            }
        }
    }
    else if(hasDays) {
        const startDay = notamMoment(start).day();
        for(let i = 0; notamMoment(start).day(startDay+i).startOf('day').isSameOrBefore(stop); i++) {
            if(
                days.includes(notamMoment(start).day(startDay+i).date()) &&
                mMonths.includes(notamMoment(start).day(startDay+i).month())
            ) {
                mDays.push(notamMoment(start).day(startDay+i).startOf('day'));
            }
        }
    }
    else {
        const startDay = notamMoment(start).day();
        for(let i = 0; notamMoment(start).day(startDay+i).startOf('day').isSameOrBefore(stop); i++) {
            if(
                mMonths.includes(notamMoment(start).day(startDay+i).month())
            ) {
                mDays.push(notamMoment(start).day(startDay+i).startOf('day'));
            }
        }
    }
    logger.groupBegin();
    if(hasHoursSlots) {
        let startTmp, stopTmp;
        let hourSlotsSort = nHourSlots.sort(
            (a,b) => {
                if(notamMoment(a.begin).isAfter(b.begin, 'minute')) return 1;
                else if(notamMoment(a.begin).isBefore(b.begin, 'minute')) return -1;
                else return 0;
            }
        );
        for(let i = 0; i < mDays.length; i++) {
            for(let j = 0; j < hourSlotsSort.length; j++) {

                //This day with good hour and minutes
                const slotBegin = notamMoment(mDays[i])
                    .hour(
                        notamMoment(hourSlotsSort[j].begin).hour()
                    )
                    .minutes(
                        notamMoment(hourSlotsSort[j].begin).minutes()
                    )
                    .startOf('minute');

                let slotEnd = notamMoment(mDays[i])
                    .hour(
                        notamMoment(hourSlotsSort[j].end).hour()
                    )
                    .minutes(
                        notamMoment(hourSlotsSort[j].end).minutes()
                    )
                    .startOf('minute');

                //if hour slot passes midnight
                if(notamMoment(slotEnd).isBefore(slotBegin)) {

                    slotEnd = notamMoment(mDays[i])
                        .add(1, 'day')
                        .hour(
                            notamMoment(hourSlotsSort[j].end).hour()
                        )
                        .minutes(
                            notamMoment(hourSlotsSort[j].end).minutes()
                        )
                        .startOf('minute');
                    logger.log("Slot end is before slot begin", slotEnd)
                }


                logger.log("slot ============================", notamMoment(slotBegin).utc().format(), notamMoment(slotEnd).utc().format(), notamMoment(start).utc().format(), notamMoment(stop).utc().format());

                //If first day is same as first day in period
                if(notamMoment(start).isSame(mDays[i], 'day')) {
                    //if slot end before start
                    if(notamMoment(slotEnd).isBefore(start)) {
                        logger.log("slot skip end is before start", slotEnd, start);
                        continue;
                    }
                    //if slot begins before start set to start time
                    else if(notamMoment(slotBegin).isBefore(start, 'minutes')) {
                        startTmp = notamMoment(start);
                        stopTmp = notamMoment(slotEnd);
                        logger.log("slot begins before start set to start time",startTmp,stopTmp);
                    }
                    //if slot begins after set to slot time
                    else {
                        startTmp = notamMoment(slotBegin);
                        stopTmp = notamMoment(slotEnd);
                        logger.log("slot begins after set to slot time",startTmp,stopTmp);
                    }
                }
                // If first day but not start day
                // else if(i === 0) {
                //     startTmp = notamMoment(slotBegin)
                // }
                //if last day is stop day
                else if(notamMoment(stop).isSame(mDays[i], 'day')) {
                    //if slot begin is after stop continue
                    if(notamMoment(slotBegin).isAfter(stop)) {
                        logger.log("slot skip begin is after stop", slotBegin, stop);
                        continue;
                    }
                    //if slot end is after stop set to stop
                    else if(notamMoment(slotEnd).isAfter(stop)) {
                        startTmp = notamMoment(slotBegin);
                        stopTmp = notamMoment(stop);
                        logger.log("slot end is after stop set to stop",startTmp,stopTmp);
                    }
                    else {
                        startTmp = notamMoment(slotBegin);
                        stopTmp = notamMoment(slotEnd)    ;
                        logger.log("last day is stop day set slot",startTmp,stopTmp);
                    }
                }
                else {
                    startTmp = notamMoment(slotBegin);
                    stopTmp = notamMoment(slotEnd)    ;
                    logger.log("else",startTmp,stopTmp);
                }
                mHourSlots.push({start: startTmp, stop: stopTmp})
            }
        }

    }
    else {
        let startTmp;
        for(let i = 0; i < mDays.length; i++) {

            const day = mDays[i];
            const prevDay = mDays[i-1];
            const nextDay = mDays[i+1];

            //If first day is same as first day in period
            if(notamMoment(start).isSame(day, 'day')) {
                startTmp = notamMoment(start);
                logger.log("first day is same as first day in period, set:", startTmp);
            }
            //If is first day in iteration
            else if(i === 0) {
                startTmp = notamMoment(day).startOf('day');
                logger.log("first day in iteration, set:", startTmp);
            }

            else if(Math.abs(notamMoment(day).diff(notamMoment(prevDay), 'days', true)) > 1) {
                startTmp = notamMoment(day).startOf('day');
                logger.log("diff count between days bigger than one, set:", startTmp.format(), day.format(), prevDay.format(), 'day diff in start conditions:', notamMoment(day).diff(notamMoment(prevDay), 'days', true));
            }
            else {
                logger.log("startTmp not modified or defined day:", day, startTmp);
            }


            //If last day is stop day
            if(notamMoment(stop).isSame(day, 'day')) {
                mHourSlots.push(
                    {
                        start: startTmp,
                        stop: notamMoment(stop)
                    }
                );
                logger.log("last day is stop day, push", startTmp, notamMoment(stop));
            }
            // If last day in iteration
            else if(i === mDays.length-1) {
                mHourSlots.push(
                    {
                        start: startTmp,
                        stop: notamMoment(day).endOf('day')
                    }
                );
                logger.log("last day in iteration, push", startTmp, notamMoment(day).endOf('day'));
            }
            //If is not continous between days push
            else if(notamMoment(nextDay).diff(notamMoment(day), 'days', null) > 1){
                mHourSlots.push(
                    {
                        start: startTmp,
                        stop: notamMoment(day).endOf('day')
                    }
                );
                logger.log("diff between nextday and day is > 1, push:", startTmp, notamMoment(day).endOf('day'), day, nextDay, 'day diff in stop conditions:', notamMoment(nextDay).diff(notamMoment(day), 'days', null));
            }
            else {
                logger.log("not pushed anything", day, startTmp, nextDay);
            }
        }
    }
    logger.groupEnd();

    logger.log("mYears", mYears);
    logger.log("mMonths", mMonths);
    logger.log("mDays", mDays);
    logger.log("mHourSlots", mHourSlots);

    const activities = [];
    mHourSlots.forEach(act =>
        activities.push({
                start: notamMoment(act.start).format(),
                stop: notamMoment(act.stop).format(),
            }
        ));
    return activities;
}
