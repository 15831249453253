import {MissionV2Statuses} from "../../../LogicV1Redux/constants/MissionsV2Enums";
import {CaaApplicationStatuses} from "../../../LogicV1Redux/constants/CaaApplicationEnums";

export function mapStatusToEntryGroupName(status) {

    switch(status) {
        case MissionV2Statuses.ACCEPTED:
        case CaaApplicationStatuses.ACCEPTED:
            return 'accepted';
        case MissionV2Statuses.PLANNED:
        case CaaApplicationStatuses.VERIFY:
            return 'pending';
        case MissionV2Statuses.REJECTED:
        case CaaApplicationStatuses.REJECTED:
        case CaaApplicationStatuses.MODIFIED:
            return 'rejected';
        case MissionV2Statuses.CREATED:
        case CaaApplicationStatuses.CREATED:
            return 'created';
        case CaaApplicationStatuses.VERIFY_AGAIN:
            return 'pendingAgain';
        case CaaApplicationStatuses.HOLD_MEETING_REQUEST:
            return 'meetingsRequest';
        case CaaApplicationStatuses.HOLD_MEETING_ACCEPTED:
            return 'meetingsAccepted';
        case CaaApplicationStatuses.HOLD_MEETING_REJECTED:
            return 'meetingsRejected';
        default:
            return 'unknown'
    }
}