import React from "react";
import {CaaApplicationStatuses} from "../../../../LogicV1Redux/constants/CaaApplicationEnums";
import {OldStatusIssuer, OldStatusMsg} from "../MissionCustomComponents/AtcMsgBlock";
import has from "lodash.has";
import moment from "moment";
import {isCaaApplMeetingHoldStatus} from "../../../utils/applicationsHelpers";
import {Fields} from "redux-form";
import TranslatableTypography from "../../TranslatableTypography";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";

const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);

function status2label(status) {

    if(isCaaApplMeetingHoldStatus(status)) return 'resources.applications.meetingDialogLabel';

    switch (status) {
        case CaaApplicationStatuses.ACCEPTED:
            return 'resources.applications.acceptDialogLabel';
        default:
            return 'resources.applications.rejectDialogLabel';
    }
}

//TODO refactor to separate component - exists also as AtcMsgBlock
export const CaaMsgBlockField = ({record, classes = {}, notInForm, style, className, ...rest}) => {

    if(!has(record, 'etc.msg') || !has(record, 'etc.meeting')) return null;

    const caaMsg = record.etc.msg;
    const caaIssuer = record.etc.caaStatusIssuerEmail;
    const caaMeetingTime = record.etc.meeting;
    const status = record.status;

    //For now always true. CAA cant add comment for accepted applications
    const header = status2label(status);
    const msgStyle = status === CaaApplicationStatuses.REJECTED ? {color:"tomato", whiteSpace: "pre-wrap"} : {whiteSpace: "pre-wrap"};

    const Component = () =>
        <div className={className} style={style} >
            {NEW_ALERTBOX_ENABLED && <TranslatableTypography content='resources.applications.messagesCaa' variant='body2'/>}
            {caaMeetingTime && <OldStatusMsg classes={classes} header={'resources.applications.meetingDate'} msg={moment(caaMeetingTime).format('YY-MM-DD HH:mm')} style={msgStyle}/>}
            <OldStatusMsg classes={classes} header={header} msg={caaMsg} style={msgStyle}/>
            {/*remember that operator don't have access to gov db data. */}
            <OldStatusIssuer classes={classes} issuerEmail={caaIssuer}/>
        </div>;

    return notInForm ?
        <Component/>
        :
        <Fields names={['etc.msg', 'etc.caaStatusIssuerEmail', 'etc.meeting', 'status']} component={Component}/>
};