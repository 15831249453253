import {canUpdate} from "../../../logic/MyRestConfig";
import EditButton from "ra-ui-materialui/esm/button/EditButton";
import ShowButton from "ra-ui-materialui/esm/button/ShowButton";
import ArchiveToggleButton from "../../MyArchiveToggleButton";
import React from "react";

export const CertificateActionButtons = (props) => {
    const {mySelf, record, permissions, ...rest} = props;

    const isOperator = permissions && permissions.roles && permissions.roles.indexOf('operator') !== -1;

    const canEdit = canUpdate(props.resource, permissions);

    return (
        <div style={{display: "flex"}}>
            {
                canEdit
                    ? <EditButton record={record} {...rest} />
                    : <ShowButton record={record} {...rest}/>
            }
            {
                isOperator
                    ? <ArchiveToggleButton record={record} {...rest} />
                    : null
            }
        </div>
    )
};