import {RESOURCES} from "../logic/MyRestConfig";
import {GOV_EVENTS, OPERATOR_EVENTS} from "../../LogicV1Redux/constants/EventsConstatants";

export function getSubjectResourceByAction(action, callingResource) {
    switch(callingResource) {
        case RESOURCES.EVENT_OPERATORS: return getOperatorSubjectResourceByAction(action);
        case RESOURCES.EVENT_GOVS: return getGovSubjectResourceByAction(action);
        default: return null;
    }
}

const createComparator = (constant) => (compared) => compared === constant;

function getGovSubjectResourceByAction(action) {
    const $ = createComparator(action);

    switch (true) {
        case $(GOV_EVENTS.REGISTER):
        case $(GOV_EVENTS.PHONE_VERIFY):
        case $(GOV_EVENTS.PHONE_CHANGE):
        case $(GOV_EVENTS.PASSWORD_RESET):
        case $(GOV_EVENTS.LOGIN):
        case $(GOV_EVENTS.LOGIN_FAILED):
        case $(GOV_EVENTS.LOGOUT):
        case $(GOV_EVENTS.ACCOUNT_UPDATE):
        case $(GOV_EVENTS.EMAIL_VERIFY):
            return RESOURCES.GOVS;

        case $(GOV_EVENTS.BAN_OPERATOR):
            return RESOURCES.OPERATORS;

        case $(GOV_EVENTS.CERT_READ):
        case $(GOV_EVENTS.CERT_VALIDATED):
        case $(GOV_EVENTS.CERT_REJECTED):
        case action.startsWith('CERT'):
            return RESOURCES.CERTS;

        case $(GOV_EVENTS.DRONE_VALIDATED):
        case $(GOV_EVENTS.DRONE_REJECTED):
        case action.startsWith('DRONE'):
            return RESOURCES.DRONES;

        case $(GOV_EVENTS.MISSION_APPROVE):
        case $(GOV_EVENTS.MISSION_REJECT):
        case action.startsWith('MISSION'):
            return RESOURCES.MISSIONS;

        case $(GOV_EVENTS.OPERATION_UPDATE):
        case $(GOV_EVENTS.VALIDATION_ERROR):
        default:
            return null;
    }
}

function getOperatorSubjectResourceByAction(action) {
    const $ = createComparator(action);

    switch (true) {
        case $(OPERATOR_EVENTS.REGISTER):
        case $(OPERATOR_EVENTS.ANONYMOUS_REGISTER):
        case $(OPERATOR_EVENTS.EMAIL_MALFORMED):
        case $(OPERATOR_EVENTS.EMAIL_EXISTS):
        case $(OPERATOR_EVENTS.USER_EXISTS):
        case $(OPERATOR_EVENTS.EMAIL_VERIFY):
        case $(OPERATOR_EVENTS.EMAIL_CONFIRM):
        case $(OPERATOR_EVENTS.INVALID_TOKEN):
        case $(OPERATOR_EVENTS.USER_NOT_FOUND):
        case $(OPERATOR_EVENTS.EMAIL_CHANGE):
        case $(OPERATOR_EVENTS.EMAIL_CHANGE_EMAIL_EXISTS):
        case $(OPERATOR_EVENTS.EMAIL_CHANGE_SENT):
        case $(OPERATOR_EVENTS.EMAIL_CHANGE_SENT_ERROR):
        case $(OPERATOR_EVENTS.PHONE_VERIFY):
        case $(OPERATOR_EVENTS.PHONE_VERIFY_UID_NOT_FOUND):
        case $(OPERATOR_EVENTS.PHONE_NUMBER_EXISTS):
        case $(OPERATOR_EVENTS.PHONE_NUMBER_INVALID):
        case $(OPERATOR_EVENTS.PHONE_NUMBER_MALFORMED):
        case $(OPERATOR_EVENTS.TWILIO_ERROR):
        case $(OPERATOR_EVENTS.PHONE_VERIFIED):
        case $(OPERATOR_EVENTS.VERIFY_CODE_CODE_INVALID):
        case $(OPERATOR_EVENTS.VERIFY_CODE_UID_INVALID):
        case $(OPERATOR_EVENTS.PASSWORD_RESET):
        case $(OPERATOR_EVENTS.LOGIN):
        case $(OPERATOR_EVENTS.LOGIN_FAILED):
        case $(OPERATOR_EVENTS.LOGOUT):
        case $(OPERATOR_EVENTS.ACCOUNT_UPDATE):
        case $(OPERATOR_EVENTS.ACCOUNT_DISABLE):
        case $(OPERATOR_EVENTS.NICKNAME_CHANGE):
        case $(OPERATOR_EVENTS.ACCOUNT_UPDATE_ATTEMPT):
        case $(OPERATOR_EVENTS.MERGE_ACCOUNT):
            return RESOURCES.OPERATORS;

        case $(OPERATOR_EVENTS.CERT_CREATE):
        case $(OPERATOR_EVENTS.CERT_DEACTIVATED):
        case $(OPERATOR_EVENTS.CERT_UPDATE):
        case $(OPERATOR_EVENTS.CERT_VALIDATED):
        case action.startsWith('CERT'):
            return RESOURCES.CERTS;

        case $(OPERATOR_EVENTS.DRONE_CREATE):
        case $(OPERATOR_EVENTS.DRONE_UPDATE):
        case $(OPERATOR_EVENTS.DRONE_DEACTIVATED):
        case $(OPERATOR_EVENTS.DRONE_VALIDATED):
        case action.startsWith('DRONE'):
            return RESOURCES.DRONES;

        case $(OPERATOR_EVENTS.MISSION_CREATE):
        case $(OPERATOR_EVENTS.MISSION_UPDATE):
        case $(OPERATOR_EVENTS.MISSION_ARCHIVE):

        case action.startsWith('MISSION'):
            return RESOURCES.MISSIONS;


        case $(OPERATOR_EVENTS.LEG_CREATE):
        case $(OPERATOR_EVENTS.LEG_UPDATE):
        case $(OPERATOR_EVENTS.LEG_DELETE):

        case $(OPERATOR_EVENTS.ORG_CREATE):
        case $(OPERATOR_EVENTS.ORG_UPDATE):
        case $(OPERATOR_EVENTS.ORG_ARCHIVE):

        case $(OPERATOR_EVENTS.OPERATION_UPDATE):
        case $(OPERATOR_EVENTS.VALIDATION_ERROR):
        case $(OPERATOR_EVENTS.CHECK_IN):
        default:
            return null;

    }
}

export const getUserSource = (resource) => {
    switch (resource) {
        case RESOURCES.EVENT_GOVS:
            return 'govid';
        case RESOURCES.EVENT_OPERATORS:
            return 'operatorid';
        default:
            return ''
    }
};
export const getUserResource = (resource) => {
    switch (resource) {
        case RESOURCES.EVENT_GOVS:
            return RESOURCES.GOVS;
        case RESOURCES.EVENT_OPERATORS:
            return RESOURCES.OPERATORS;
        default:
            return ''
    }
};