import React, {Component} from 'react';
//import { DependentInput } from 'aor-dependent-input';
import {
    Create,
    Datagrid,
    DisabledInput,
    Edit,
    Filter,
    FormDataConsumer,
    maxLength,
    minLength,
    ReferenceInput,
    required,
    Responsive,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {connect} from 'react-redux';

import compose from 'recompose/compose';

import {find} from 'lodash';
import {bindActionCreators} from 'redux'
import {
    change, Field,
    formValueSelector,
    getFormInitialValues,
    getFormMeta,
    getFormSyncErrors,
    isPristine,
    isValid,
    reset
} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';

import MyFileField from '../MyFileField';
import MyFileInput from '../MyFileInput';
import MyFilesField from '../MyFilesField';

import MyTextField from '../MyTextField';

import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";

import {
    adjustTimeFormatterToScreen,
    dateFormatter,
    dateTimeFormatter,
    dateTimeWeekDayFormatter
} from '../../utils/momentHelpers'
import {isOperator} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicShowActions from "../MyBasicShowActions";
import BasicListActions from "../MyBasicListActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import MyHorizontalStepper from "../MyHorizontalStepper";
import MyStep from "../MyStep";
import StoreProvider from "../../MyStoreProvider";
import {DatePickerLocale} from "../TimePickersLocaleProxy";
import {MyRecordTitle} from "../MyTitle";
import {CaaVerifyToolbar, SaveVerifyToolbar} from "../MyBasicEditToolbar";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import {
    isCaaApplMeetingHoldStatus,
    translateCaaStatusToTypo,
    translatePrincipalToTypo
} from "../../utils/applicationsHelpers";
import Labeled from "ra-ui-materialui/esm/input/Labeled";
import MyOperatorsSearch from "./MyOperatorsSearch";
import {CaaMsgBlockField} from "./ApplicationsCustomComponents/CaaMsgBlockField";
import StatusField from "../StatusField";
import {getStatusIconColored} from "../../utils/Dashboard/statusIcon";
import has from "lodash.has";
import {InfoText} from "../InfoText";
import TranslatableTypography from "../TranslatableTypography";
import {OperatorMsgBlockField} from "./ApplicationsCustomComponents/OperatorMsgBlockField";
import {
    CaaApplicationStatuses,
    CaaApplicationStatusesSelectInputArray
} from "../../../LogicV1Redux/constants/CaaApplicationEnums";
import MyIlikeSearch from "./MyIlikeSearch";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import {CertificateActionButtons} from "./CertificateCustomComponents/CertificateActionButtons";
import {CertificateSummaryCards} from "./CertificateCustomComponents/CertificateSummaryCards";
import ApplicationCreateToolbar from "./ApplicationsCustomComponents/ApplicationCreateToolbar";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../Configs";
import {CaaMessagesBlockField} from "./ApplicationsCustomComponents/CaaMessagesBlockField";
// size limit z jaska dla pol jsonowych
// "json": {
//     "strict": false,
//         -      "limit": "100kb"
//     +      "limit": "10mb"
// },


/*
  {
    "uid": "string",
    "name": "string",
    "country": "string",
    "created": "2018-02-16T05:36:00.655Z",
    "expire": "2018-02-16T05:36:00.655Z",
    "etc": {},
    "operatorid": "string"
  }
*/

//PUT /Govs/certs/{id}	Update attributes of a Cert.
//'expire', 'etc'

const APPLICATION_PRINCIPAL_IN_SHOW_VIEW_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.APPLICATION_SHOW_VIEW_WITH_PRINCIPAL);
const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);


const minDate = new Date();
minDate.setFullYear(minDate.getFullYear());
minDate.setHours(0, 0, 0, 0);

const simpleRequired = [required()];
const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];


export const CertificatesList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureCertificatesList {...props}/>
    </MyTransitionDecorator>
);

export const CertificatesArchiveList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureCertificatesList asArchiveView={true} {...props}/>
    </MyTransitionDecorator>
);

//issue with empty status filter
//https://github.com/marmelab/react-admin/pull/2780/commits/e529326858a813d0ea63f34d8ce9a9bb56802686
//probably faster will be hack on restclient
const statusChoices = [
    { id: 'accepted', name: 'Accepted', val:'accepted' },
    { id: 'verify', name: 'Verify', val:'verify' },
    { id: 'rejected', name: 'Rejected', val:'rejected' },
    { id: '', name: 'In Edit', val: 'null'}, //not working
];

//custom filters to add. null status issue to solve
const CertificatesFilter = (props) => {
    //console.log('====CertificatesFilter, props', props);
    if (!props.permissions) return null;
    return (
    <Filter {...props}>
        <MyIlikeSearch source="uid"
                       label='resources.missions.fields.uid'
                       inlineStyle={{minWidth: "305px"}}/>
       {/*<SelectInput*/}
            {/*source="status"*/}
            {/*choices={statusChoices}*/}
            {/*optionText="name" optionValue="id"*/}
            {/*//allowEmpty={false}*/}
        {/*/>*/}
        { (isOperator(props.permissions.roles))
            ?null
            :
            <MyOperatorsSearch source="operatorid"
                               label={'resources.drones.fields.operator'}
            />
        }
        <ReferenceInput source="name"
                        label={'resources.certTypes.fields.name'}
                        reference="certTypes"
                        allowEmpty={false}
        >
            <SelectInput source="code_id"
                         optionText="cert_code"
            />
        </ReferenceInput>
        <SelectInput source="status"
                     label='resources.applications.verification'
                     choices={CaaApplicationStatusesSelectInputArray}
                     optionText={
                         choice => props.translate(choice.name, {smart_count: 2})
                     }
                     translateChoice={false}
                     allowEmpty={false}
        />
    </Filter>
)};

const PureCertificatesList = withWidth()(({permissions, asArchiveView = false, width, ...props}) => {
    if (!permissions) return null;
    //console.log('PureDronesList', permissions);
    //const _isOperator = isOperator(permissions.roles);

    const isOp = isOperator(permissions.roles);

    const ActionsList =  (isOp)
        ?<BasicListActions
            archiveLink={(asArchiveView)?"/certs":"/certsArchive"}
            archiveLabel={(asArchiveView)?'resources.archives.activeLabel':'resources.archives.archiveLabel'}
        />
        :<BasicListActions />;

    return (
        <List {...props}
              title={'resources.certs.menu'}
              //Check to avoid using GET as filter method not GET_MANY that passes created and null statuses to gov
              filterDefaultValues={isOp ? { active: !asArchiveView } : undefined}
              filters={<CertificatesFilter permissions={permissions}/>}
              sort={{field: 'created', order: 'DESC'}}
              bulkActions={false}
              actions={ActionsList}
              pagination={<MyPagination/>}
              undoable={undefined}

        >
            <Responsive
                small={
                    <CertificateSummaryCards resource={props.resource}
                                       {...{isOp, permissions, width}}/>
                }
                medium={

                    <Datagrid>
                        {/*atc columns reviewed in #96/98 issues, but (re?) enabling modified*/}

                        <MyTextField source="modified"
                                     format={adjustTimeFormatterToScreen({date: true}, width)}
                                     tooltip={isWidthDown('md', width)}
                                     tooltipFormatter={dateTimeWeekDayFormatter}
                        />

                        <TextField source="name"
                                   label={'resources.certTypes.fields.name'}/>

                        <TextField source="country"
                                   label={'resources.certTypes.fields.country'}/>

                        <MyTextField source="expire"
                                     label={'resources.certTypes.fields.expire'}
                                     format={dateFormatter}/>

                        <StatusField source="status"
                                     tooltip={true}
                                     statusMappingFn={translateCaaStatusToTypo}
                                     iconGetter={getStatusIconColored}
                                     label={'resources.applications.verification'}
                        />

                        {isOperator(permissions.roles)
                            ? null
                            :
                            <ReferenceField source="operatorid"
                                            reference="operators"
                                            linkType="show"
                                            addLabel={true}
                                            sortable={false}
                                            label={'resources.certs.fields.operator'}
                            >
                                <TextField source="lastName"/>
                            </ReferenceField>
                        }

                        <CertificateActionButtons resource={props.resource}
                                                  permissions={permissions}
                                                  record="record"
                        />

                    </Datagrid>
                }
            />
        </List>
    )
});


export const CertificatesShow = ({permissions, ...props}) => {

    if (!permissions) return null;
    return (

        <Show {...props}
              actions={<BasicShowActions/>}
              title={<MyRecordTitle resource={'certs'}/>}
        >
            <SimpleShowLayout>

                <MyTextField source="uid"/>
                {NEW_ALERTBOX_ENABLED && <CaaMessagesBlockField notInForm/>}
                {isOperator(permissions.roles)
                    ? null
                    :
                    <ReferenceField source="operatorid"
                                    reference="operators"
                                    linkType="show"
                                    addLabel={true}
                                    label={'resources.drones.fields.operator'}
                    >
                        {/*fixme to full name renderer*/}
                        <TextField source="lastName"/>
                    </ReferenceField>
                }

                <MyTextField source="name"
                             label={'resources.certTypes.fields.name'} />
                {
                    !NEW_ALERTBOX_ENABLED &&
                    <FormDataConsumer>
                        {({formData}) =>
                            <>
                                <Labeled label={'resources.applications.verification'}>
                                    {translateCaaStatusToTypo(formData.status)}
                                </Labeled>

                                {
                                    APPLICATION_PRINCIPAL_IN_SHOW_VIEW_ENABLED &&
                                    has(formData, 'etc.principal') &&
                                    <Labeled label={'resources.applications.principal'}>
                                        {translatePrincipalToTypo(formData.etc.principal)}
                                    </Labeled>
                                }
                            </>
                        }
                    </FormDataConsumer>
                }
                {!NEW_ALERTBOX_ENABLED && <CaaMsgBlockField notInForm/>}
                {!NEW_ALERTBOX_ENABLED && <OperatorMsgBlockField notInForm/>}
                <MyTextField source="country"
                             label={'resources.certTypes.fields.country'}/>

                <MyTextField source="etc.certId"
                             label={'resources.certTypes.fields.etc_certId'} />

                <MyTextField source="expire"
                             label={'resources.certTypes.fields.expire'}
                             format={dateTimeFormatter} />

                <MyTextField source="created"
                             format={dateTimeFormatter}/>

                <MyFilesField source="attn"
                              label={'resources.files.dropboxLabel'}/>

            </SimpleShowLayout>
        </Show>
    );
};

//files setup see notes
const CertificatesEditBase = ({permissions, myAppExtras, dispatch, status, initialValues, ...props}) => {
    if (!permissions) return null;
    const isOp = isOperator(permissions.roles);

    const disableEdit =
        isCaaApplMeetingHoldStatus(status) ||
        status === CaaApplicationStatuses.VERIFY ||
        status === CaaApplicationStatuses.VERIFY_AGAIN;

    const pickerOrInfo =
        isOp && !disableEdit ?
            <DatePickerLocale source="expire"
                              label={'resources.certTypes.fields.expire'}
                              validate={simpleRequired}
            />
            :
            <DisabledInput source="expire"
                           label={'resources.certTypes.fields.expire'}
                           format={dateTimeFormatter}
            />;

    return (
        <Edit {...props}
              undoable={false}
              actions={<BasicEditActions/>}
              title={<MyRecordTitle resource={'certs'}/>}
        >
            <SimpleForm //form={'certificate-edit-form'} // must be default
                defaultValue={{}}
                toolbar={isOp ? <SaveVerifyToolbar/> : <CaaVerifyToolbar/>}
            >
                <MyTextField source="uid"/>
                {NEW_ALERTBOX_ENABLED && <CaaMessagesBlockField/>}
                {isOp
                    ? null
                    :
                    <ReferenceField source="operatorid"
                                    reference="operators"
                                    linkType="show"
                                    addLabel={true}
                                    label={'resources.drones.fields.operator'}
                    >
                        {/*fixme to full name renderer*/}
                        <TextField source="lastName"/>
                    </ReferenceField>
                }

                <DisabledInput source="name"
                               label={'resources.certTypes.fields.name'}
                />
                {
                    !NEW_ALERTBOX_ENABLED &&
                    <FormDataConsumer>
                        {({formData}) =>
                            <>
                                <Labeled label={'resources.applications.verification'}>
                                    <Field name='status' component={() => translateCaaStatusToTypo(formData.status)}/>
                                </Labeled>

                                {has(formData, 'etc.principal') &&
                                <Labeled label={'resources.applications.principal'}>
                                    <Field name='etc.principal'
                                           component={() => translatePrincipalToTypo(formData.etc.principal)}/>
                                </Labeled>
                                }

                            </>
                        }
                    </FormDataConsumer>
                }
                {!NEW_ALERTBOX_ENABLED && <CaaMsgBlockField/>}
                {!NEW_ALERTBOX_ENABLED && <OperatorMsgBlockField/>}
                <DisabledInput source="country"
                               label={'resources.certTypes.fields.country'}/>
                {/*
                <DependentInput dependsOn="etc.certId">
                    <DisabledInput label={'resources.certTypes.certId'}
                                   source="etc.certId"/>
                </DependentInput>
                <DependentInput dependsOn="expire">
                    <DateInput source="expire"
                                 options={{
                                     container : "dialog",
                                     openToYearSelection : true,
                                     minDate : minDate,
                                     //cancelLabel : translateCancelLabel()
                                 }}
                    />
                </DependentInput>
                */}
                {
                    has(initialValues, 'etc.certId') &&

                    //let it be editable.. now we have lost validation flow, so i guess ok.
                    isOp && !disableEdit ?
                        <TextInput source="etc.certId"
                                   label={'resources.certTypes.fields.etc_certId'}
                                   validate={simpleRequired}
                        />
                        :
                        <DisabledInput source="etc.certId"
                                       label={'resources.certTypes.fields.etc_certId'}
                                       fullWidth
                        />
                }
                {
                    has(initialValues, 'expire') && pickerOrInfo
                }
                <DisabledInput source="created"
                               label={'resources.certs.fields.created'}
                               format={dateTimeFormatter}
                />
                {isOp ?
                    <MyFileInput
                        source="attn"
                        multiple={true}
                        label={'resources.files.dropboxLabel'}
                        labelMultiple={'resources.files.dropboxPlaceholder'}
                        accept="image/jpeg,image/png,application/pdf"
                        maxSize={myAppExtras.app.maxAttnSize}
                        disabled={disableEdit}
                    >
                        <MyFileField
                            source="src"
                            title="title"/>
                    </MyFileInput>
                    :
                    <MyFilesField
                        source="attn"
                        label={'resources.files.attachments'} addLabel={true}
                    />
                }
            </SimpleForm>
        </Edit>
    )
};


const mapStateToProps_edit = state => ({
    myAppExtras: state.myAppExtras,
    status: formValueSelector('record-form')(state, 'status'),
    initialValues: getFormInitialValues('record-form')(state)
});


export const CertificatesEdit = compose(
    //withStyles(styles),
    connect(
        mapStateToProps_edit,
        null
    ))(CertificatesEditBase);


const certificateCreateFormName = 'record-form'; //have to be default

const styles = theme => ({
    createForm: {
        margin: "0 10% 0",
        [theme.breakpoints.down('sm')]: {
            margin: "0"
        }
    },
    createPaper: {
        marginTop: "1rem"
    }
});

//another approach to values update..
class CertificateCreateBase extends Component {

    state = {
        lastStep: false,
    };

    constructor(props) {
        super(props);
        this.resolveVisibility = this.resolveVisibility.bind(this);
    }


    _resetForm = (formName) => {
        StoreProvider.getStore().dispatch(reset(formName));
        this.setState({
            lastStep: false
        })
    };

    _setLastStep() {
        if (this.state.lastStep === false)
            this.setState({
                lastStep: true
            })
    }


    //test for fields render
    resolveVisibility(field) {
        if (this.props.selectedCertificateType) {
            console.log('resolveVisibility', field, this.props.selectedCertificateType);
            if (this.props.selectedCertificateType.fields) {
                console.log('f', this.props.selectedCertificateType.fields[field])
            }
        }

        const ret= (this.props.selectedCertificateType
            && this.props.selectedCertificateType.fields
            && this.props.selectedCertificateType.fields[field])
            ? this.props.selectedCertificateType.fields[field]
            : false;
        console.log('ret', ret);
        return ret;
    }

    //since onchange sucks, this seems to be the best place to pass form filling
    componentDidUpdate(prevProps) {

        if (prevProps.selectedCertificateType &&
            prevProps.selectedCertificateType.cert_code !== this.props.selectedCertificateType.cert_code) {
            console.log('filling new selection', this.props.selectedCertificateType);

            this.props.change(certificateCreateFormName,'country',this.props.selectedCertificateType.country);
            this.props.change(certificateCreateFormName,'country',this.props.selectedCertificateType.country);

        }

    }

    render() {
        console.log('Certificate render', this.props, this.state);
        //this.fillCountryCode();

        //sanitizing react props
        const { selectedCertificateType, change, formSyncErrors, isFormValid, classes, formMeta, dispatch, myAppExtras,
            isFormPristine, ...rest } = this.props;
        const {lastStep} = this.state;
        return (
            <Create {...rest}
                    /* undoable={false} -  no undo in create anyway */
                    title={'resources.certs.create'}
                    className={classes.createPaper}
            >
                <SimpleForm //form={certificateCreateFormName} // must be default
                            defaultValue={{}}
                            className={classes.createForm}
                            toolbar={isFormValid && lastStep ? <ApplicationCreateToolbar/> : null}
                >
                    <MyHorizontalStepper labelWhenOptional={'myroot.validation.optional'}
                                         title={'resources.certs.create'}
                                         onLastEditableStepEntered={() => this._setLastStep()}
                                         onReset={() => this._resetForm(certificateCreateFormName)}
                                         lastStepIsEditableOne
                    >

                        <MyStep label={'resources.certTypes.fields.name'} key={0}
                                isValid={!formSyncErrors.name && !isFormPristine}
                        >
                            <ReferenceInput source="name"
                                            label={'resources.certs.typesSelectionLabel'}
                                            reference="certTypes"
                                            validate={simpleRequired}
                                            sort={{field: '__id', order: 'ASC'}}
                                //onChange={({...e}) => console.log('ref change',e)}
                            >

                                <SelectInput source="code_id"
                                             optionText="cert_code"
                                    //optionValue="id"
                                />

                            </ReferenceInput>
                            <DisabledInput source="country"
                                           label={'resources.certTypes.fields.country'}/>
                        </MyStep>
                        {
                            this.resolveVisibility("hasID")
                                ?
                                <MyStep label={'resources.certTypes.fields.etc_certId'} key={1}
                                        isValid={formSyncErrors.etc === undefined ? true : !formSyncErrors.etc.certId}
                                >
                                    <TextInput source="etc.certId"
                                               label={'resources.certTypes.fields.etc_certId'}
                                               validate={simpleRequired}

                                    />
                                </MyStep>
                                :null
                        }

                        {
                            this.resolveVisibility("hasValidThru")
                                ?

                                <MyStep label={'resources.certTypes.fields.validity'} key={2}
                                        isValid={!formSyncErrors.expire}
                                >
                                    <DatePickerLocale source="expire"
                                                      label={'resources.certTypes.fields.expire'}
                                                      validate={simpleRequired}
                                    />
                                </MyStep>
                                :null
                        }

                        {/*
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                console.log('FormDataConsumer', formData, rest);
                                return (
                                    this.resolveVisibility("hasID") &&
                                    <TextInput source="etc.certId"/>
                                )}
                            }
                        </FormDataConsumer>

                        <DependentInput label={'resources.certTypes.expire'}
                                        resolve={() => this.resolveVisibility('hasValidThru') }>
                            <DateInput source="expire"
                                       options={{
                                           container : "dialog",
                                           openToYearSelection : true,
                                           minDate : minDate,
                                           //cancelLabel : translateCancelLabel()
                                       }}
                            />
                        </DependentInput>
                        */}
                        <MyStep label={'resources.files.dropboxLabel'} key={3} optional>
                            <InfoText content={<TranslatableTypography content="resources.certs.attnInfo"/>} />
                            <MyFileInput source="attn"
                                         multiple={true}
                                         label={'resources.files.dropboxLabel'}
                                         labelMultiple={'resources.files.dropboxPlaceholder'}
                                         accept="image/jpeg,image/png,application/pdf"
                                         maxSize={myAppExtras.app.maxAttnSize} >
                                <MyFileField source="src" title="title" />
                            </MyFileInput>
                        </MyStep>
                    </MyHorizontalStepper>
                </SimpleForm>
            </Create>
        )
    };
}

//fakap with dynamic choices list
//<DependentInput resolve={() => this.resolveVisibility('hasClass') }>
//<RadioButtonGroupInput source="class" choices={choices} optionText={optionRenderer} />
//</DependentInput>*/

const mapStateToProps_create = state => {
    let selectedCertificateType;

    const certificateTypes =  state.admin.resources.certTypes;
    const form = state.form["record-form"];
    console.log('qqq mapStateToProps', state, certificateTypes, form);

    if (form && form.values && form.values.name) {
        selectedCertificateType = find(certificateTypes.data, {'cert_code': form.values.name});
        console.log('--- yyy has sel model', selectedCertificateType);

    } else {
        selectedCertificateType = {country:''};
    }

    return {
        //CertificateTypes : state.admin.resources.CertificateTypes,
        myAppExtras: state.myAppExtras,

        form,
        selectedCertificateType,
        //global validation
        isFormValid: isValid(certificateCreateFormName)(state),
        //touched or blured
        formMeta: getFormMeta(certificateCreateFormName)(state),
        //partial validation
        formSyncErrors: getFormSyncErrors(certificateCreateFormName)(state),
        isFormPristine: isPristine(certificateCreateFormName)(state),
    }
};

const mapDispatchToProps_create = (dispatch) => {
    return bindActionCreators({change}, dispatch);
};

export const CertificatesCreate = compose(
    withStyles(styles),
    connect(
    mapStateToProps_create,
    mapDispatchToProps_create
))(CertificateCreateBase);

// export const CertificatesCreate = compose(
//     //_translate,
//     //muiThemeable(), // force redraw on theme change
//     connect(
//         mapStateToProps,
//         mapDispatchToProps
//     )
// )(CertificateCreateWithStore);

//props defs todo

//export const CertificatesCreate = CertificateCreateWithStore;
