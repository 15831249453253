
// in src/customRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import MyLoginPage from '../components/auth/MyNewLoginPage';
import LiveMap from '../../gl_map/old2review/FullScreenLiveMap';
import ZoneEditor from '../../gl_map/old2review/FullScreenZoneEditor'
//import MissionEditor from '../../gl_map/FullScrenMissionEditor'
import {MyZoneConflictsResolver} from '../components/resources/MyZoneConflictsResolver'


import { GovHelpPage, GovFaqPage } from '../../docs';

//to test
//https://github.com/ReactTraining/react-router/issues/4056
export const Refresh = ({ path = '/' }) => (
    <Route
        path={path}
        component={({ history, location, match }) => {
            history.replace({
                ...location,
                pathname:location.pathname.substring(match.path.length)
            });
            return null;
        }}
    />

);

//import MapWrapper from "../display_app/SimpleMapWrapper";
//login related stuff somehow skips nolaout
export default [
    <Route path="/Xlogin" component={MyLoginPage} noLayout />,
    <Route path="/Xregister" component={MyLoginPage} noLayout />,
    <Route path="/Xreset-pass-rq" component={MyLoginPage} noLayout />,
    <Route path="/map" component={LiveMap} noLayout />,
    <Route path="/docs/faq" component={GovFaqPage} />,
    <Route path="/docs/help" component={GovHelpPage} />,
    //{/*'old' experimental routes: */},
    //{/*<Route path="/zone-editor/:modelId" component={ZoneEditor} noLayout />,*/}
    //{/*<Route path="/zone-conflict-resolver/:modelId" component={MyZoneConflictsResolver} />,*/}

];
{/*<Route path="/refresh" component={Refresh} />*/}

{/*<Route exact path="/bar" component={Bar} />,*/}
{/*<Route exact path="/baz" component={Foo} noLayout />,*/}
