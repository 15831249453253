
import React from "react";

import PoiGeomViewerMapUI from '../../../../gl_map/Components/Maps/PoiGeomViewerMapUI';

//currently the same as ZoneViewer,
// but
// will be extended to support multiple poi types
// currently uses zone viewer ui
// probably should switch here by type.

const PoiGeomViewer = (props) => {

    const {
        record,
        helpers,
        conflicts,

        bBox,
        ...rest
    } = props;


    const wrapperStyle = {position: "relative", height: "500px"};

    return (
        <PoiGeomViewerMapUI
            bBox = {bBox}
            //inspectData = {inspectData}
            wrapperStyle = {wrapperStyle}
            record = {record}
        />
    );
};

export default PoiGeomViewer;
