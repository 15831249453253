//see simplified map notes

//https://github.com/marmelab/react-admin/blob/master/docs/Fields.md#writing-your-own-field-component


//field for loopback zone
//btw.. in fact can be merged into Zone resource .jsx .. at least at the moment,, so keeping separate for now ż..

import React from 'react';
//import PropTypes from 'prop-types';
import get from 'lodash/get';
import ViewerMap from '../../../../gl_map/Components/Maps/ViewerMap';
import {deserializeZoneData, isLockedSourceZone} from '../../../../LogicV1Redux/oldLogic/ZonesListV2';
import sizeMe from "react-sizeme";

import {deserializeLegData} from "../../../../LogicV1Redux/oldLogic/MyMissionPlanner";


//old zone field. keeping for reference during refactoring
//especially old approach to showConflicts... (removed in newer version)
const MyOldZoneField = ({ record = {}, size, showEditLink = false, showConflicts=false }) => {
    console.error('deserializeZoneData fixme passed data');
    const zoneData = deserializeZoneData(record);
    console.log('==================MyZoneV2', zoneData);
    console.log('MyZoneField', { record , size, showEditLink , showConflicts });

    const zonePrimitives = (zoneData && zoneData.primitive)?[zoneData.primitive]:[];
    const { width, height } = size;

    let conflictsPrimitives = [];
    if ((showConflicts && zoneData && zoneData.zoneRecord && zoneData.zoneRecord.filteredZoneLegs)) {
        zoneData.zoneRecord.filteredZoneLegs.forEach((zoneLeg) => {

            //huh.. maybe in other place?
            console.log('==================MyZoneV2zoneLeg, ', zoneLeg);
            const {primitive} = deserializeLegData(zoneLeg.leg);

            if (primitive)
                conflictsPrimitives.push(primitive)
            }
        )
    }

    console.log(zonePrimitives, conflictsPrimitives);

    const primitives = [...zonePrimitives, ...conflictsPrimitives];

    console.log(primitives);

    const showPreview = (primitives.length > 0);
    const wrapperStyle = (showPreview)?{position: "relative", height: "500px"}:{};
    return (
        <div style={{}}>

            {/*<div>primitivesCnt:{primitives.length}</div>*/}
            {/*<div>My size is {width} px x {height}px</div>*/}

            {/*... rather not ... MissionZoneLegsCntFormatter legs={record.legs} />*/}
            <div style={wrapperStyle}>
                { showPreview
                    ? <ViewerMap
                        height={500}
                        width={width}
                        primitives={primitives}
                        autofocus={zonePrimitives}
                    />
                    : null
                }
                {/*<ZoneEditorFullScreenButton showEditLink={showEditLink} record={record} />*/}

            </div>

        </div>
    )
};


// TextField.propTypes = {
//     label: PropTypes.string,
//     record: PropTypes.object,
//     source: PropTypes.string.isRequired,
// };

//export default MyZoneField;
export default sizeMe()(MyOldZoneField)
