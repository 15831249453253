import {all, put, takeEvery, take} from 'redux-saga/effects';
import {FETCH_END, FETCH_START, showNotification, refreshView} from 'react-admin';
import DataProvider from "../../dr_ra2/MyDataProvider";
import {LOADING_END, LOADING_START, toggleAcmTermsCreatorDialog} from "../../dr_ra2/logic/ui/MyUiActions";
import {
    ACM_RULES_TERMS_DIALOG_CALLBACK,
    CREATE_ACM_RULE,
    DEFAULT_ACM_RULES_COUNT,
    LOAD_ACM_RULES_CTR,
    SAVE_ACM_RULES_RPA
} from "../constants/AcmRulesConstants";
import {RESOURCES} from "../../dr_ra2/logic/MyRestConfig";
import {push} from 'react-router-redux';
import {notamMoment} from "../../dr_ra2/utils/notamDiurnal";
import {API_VERBS} from '../../dr_ra2/MyApiVerbs'

export function getAcmCountPromise(designator) {
    const dataProvider = DataProvider.getDataProvider();
    return dataProvider(API_VERBS.COUNT, RESOURCES.ACM_RULES, {filter: {and: [{designator: designator}, {parentid: null}]}});
}

function* getDefaultRulesInfo(designator) {
    const promise = getAcmCountPromise(designator);
    const response = yield promise;
    const defCount = response.data.count;

    return {
        hasAll: defCount === DEFAULT_ACM_RULES_COUNT,
        count: defCount
    }
}


function* handleLoadAcmRulesCTR({ type, payload }) {
    const dataProvider = DataProvider.getDataProvider();

    yield put({type: FETCH_START});

    try {
        const {designator} = payload;
        const defaultRules = yield* getDefaultRulesInfo(designator);

        if(!defaultRules.hasAll) {
            yield put(toggleAcmTermsCreatorDialog({show: true}));
            const {response, etc} = yield take(ACM_RULES_TERMS_DIALOG_CALLBACK);

            if(!response) throw Error('no terms payload');

            yield put({type: LOADING_START, primaryMessage: `resources.${RESOURCES.ACM_RULES}.notifications.feedingAcm`});
            yield* feedAcmRulesWithDefaults({designator, defCount: defaultRules.count, etc});
            yield put({type: LOADING_END});
        }
        yield put(push({
            pathname: '/acmRules',
            state: {
                designator
            }
        }));
        yield put({type: FETCH_END});
    } catch (e) {
        yield put(showNotification(
            `resources.${RESOURCES.ACM_RULES}.notifications.errorOnLoading`,
            'warning',
            {messageArgs: {status: e.message}}
        ));
        console.warn('Error on loading acm rules', e);
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});
    }
}

function* handleSaveAcmRulesRPA({ type, payload }) {

    yield put({type: FETCH_START});
    yield put({type: LOADING_START, primaryMessage: `resources.${RESOURCES.ACM_RULES}.notifications.savingAcmRpa`});

    try {
        const {uid, etc} = payload;
        const dataProvider = DataProvider.getDataProvider();
        yield dataProvider(API_VERBS.UPDATE, RESOURCES.ZONES, {id: uid, data: {etc}});
        yield put({type: LOADING_END});
        yield put({type: FETCH_END});
    } catch (e) {
        yield put(showNotification(
            `resources.${RESOURCES.ACM_RULES}.notifications.errorOnSaving`,
            'warning',
            {messageArgs: {status: e.message}}
        ));
        console.warn('Error on saving acm rpa rules', e);
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});
    }

}

function* handleCreateAcmRule({ type, payload }) {
    console.log("handlecreate acm", payload);
    yield put({type: FETCH_START});

    try {
        const dataProvider = DataProvider.getDataProvider();

        const {record, redirect, resource} = payload;
        const {start, designator} = record;
        const hour = start.hour();
        const parentStartValue = notamMoment(`1970-01-01 ${hour}:00Z`).toISOString();
        const parentWhereFilter = {filter: {and: [{start: parentStartValue}, {parentid: null}, {designator}]}};

        const response = yield dataProvider(API_VERBS.GET_LIST, resource, parentWhereFilter);

        if(response.data.length !== 1) throw new Error('Acm rule create error, default list length is not equal 1');

        const parentid = response.data[0].uid;

        const data = {
            ...record,
            parentid
        };

        yield dataProvider(API_VERBS.CREATE, resource, {data});

        yield put({type: FETCH_END});
        yield put(showNotification('ra.notification.created', 'info'));
        yield put(push(redirect));
        yield put(refreshView());

    } catch (e) {
        yield put(showNotification(
            `resources.${RESOURCES.ACM_RULES}.notifications.errorOnSaving`,
            'warning',
            {messageArgs: {status: e.message}}
        ));
        console.warn('Error on saving acm rpa rule', e);
        yield put({type: FETCH_END});
    }
}

/**
 * @throws Can throw REST errors, must be surrounded with try/catch block
 */
function* feedAcmRulesWithDefaults({designator, defCount, etc}) {

    const dataProvider = DataProvider.getDataProvider();

    let data;

    if (!defCount || defCount === 0) {
        data = createDefaultAcmRules({designator, etc});
    } else {
        const response = yield dataProvider(API_VERBS.GET_LIST, RESOURCES.ACM_RULES, {filter: {and: [{designator: designator}, {parentid: null}]}});
        const blacklist = getAcmRulesStartMomentsArray(response.data);
        data = createDefaultAcmRules({designator, blacklist, etc});
    }
    yield dataProvider(API_VERBS.CREATE, RESOURCES.ACM_RULES, {data})
}

function getAcmRulesStartMomentsArray(acmRules) {
    const times = [];
    acmRules.map(
        rule => times.push(rule.start)
    );
    return times;
}

function createDefaultAcmRules({designator, blacklist, etc}) {
    const defaultRules = [];
    for(let i = 0; i < DEFAULT_ACM_RULES_COUNT; i++) {
        const start = notamMoment(`1970-01-01 ${i<10 ? 0+i.toString() : i }:00Z`).toISOString();

        if(!!blacklist && blacklist.includes(start)) continue;

        const rule = {
            designator,
            start: start,
            red: 0,
            yellow: 0,
            green: 0,
            blue: 0,
            etc
        };
        defaultRules.push(rule);
    }
    return defaultRules;
}

export default function* () {

    yield all([
        takeEvery(LOAD_ACM_RULES_CTR, handleLoadAcmRulesCTR),
        takeEvery(SAVE_ACM_RULES_RPA, handleSaveAcmRulesRPA),
        takeEvery(CREATE_ACM_RULE, handleCreateAcmRule)
    ]);
}
