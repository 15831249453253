import MyInputDialog from "../../MyInputDialog";
import React from "react";
import {translate, SimpleForm} from 'react-admin'
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import {toggleAcmTermsCreatorDialog} from "../../../logic/ui/MyUiActions";
import has from "lodash.has";
import {
    callbackFromAcmTermsDialog,
} from "../../../../LogicV1Redux/actions/AcmRulesActions";
import {
    formValueSelector,
    reset,
    destroy,
    FormSection,
    getFormSyncErrors,
    initialize,
    isValid,
} from 'redux-form';
import {RESOURCES} from "../../../logic/MyRestConfig";
import TranslatableTypography from "../../TranslatableTypography";
import MyHorizontalStepper from "../../MyHorizontalStepper";
import MyStep from "../../MyStep";
import {AcmTermsLangInput} from "./AcmTermsLangInput";
import {isEmpty} from "lodash";

const ACM_TERMS_INPUT_FORM = 'acm-rpa-terms-form';


const defaultTermsStruct = {
    etc: {
        terms: {
            red: {
                en: null,
                pl: null,
            },
            yellow: {
                en: null,
                pl: null,
            },
            green: {
                en: null,
                pl: null
            },
            blue: {
                en: null,
                pl: null
            }
        }
    }
};

class AcmTermsCreatorDialog extends React.Component {

    state = {
        isLastStepTouched: false
    };

    _setLastStepTouched = () => {
        this.setState({
            isLastStepTouched: true
        })
    };

    _resetForm = () => {
        this.props.initializeForm();
        // does not trigger updateFormSyncErrors - https://github.com/redux-form/redux-form/issues/2838
        //this.props.resetForm();
    };

    render() {
        const {
            acmTermsCreatorDialog, toggleAcmTermsCreatorDialog, ctr, etcOutput, callbackFromAcmTermsDialog,
            destroyForm, formSyncErrors
        } = this.props;
        const {isLastStepTouched} = this.state;

        return (
            <MyInputDialog title={`resources.${RESOURCES.ACM_RULES}.acmTermsCreatorDialogTitle`}
                           label={[`resources.${RESOURCES.ACM_RULES}.acmTermsCreatorDialogLabel`, {ctr}]}
                           confirm={'ra.action.save'}
                           cancel={'ra.action.cancel'}
                           onConfirm={() => {
                               toggleAcmTermsCreatorDialog({show: false});
                               callbackFromAcmTermsDialog({response: true, etc: etcOutput});
                               destroyForm();
                           }}
                           onClose={() => {
                               toggleAcmTermsCreatorDialog({show: false});
                               callbackFromAcmTermsDialog({response: false})
                           }}
                           isOpen={acmTermsCreatorDialog.visible}
                           confirmColor={'warning'}
                           confirmDisabled={!isEmpty(formSyncErrors) || !isLastStepTouched}
                           maxWidth={'md'}
                           content={
                               () =>
                                   <>
                                       <SimpleForm form={ACM_TERMS_INPUT_FORM}
                                                   redirect={false}
                                                   toolbar={null}
                                                   defaultValue={defaultTermsStruct}
                                       >
                                           <FormSection name={'etc.terms'}>
                                               <MyHorizontalStepper labelWhenOptional={'myroot.validation.optional'}
                                                                    subheader={
                                                                        <TranslatableTypography component='p'
                                                                                                variant='caption'
                                                                                                content={`resources.${RESOURCES.ACM_RULES}.acmTermsCreatorDialogSubheader`}
                                                                                                options={{ctr}}
                                                                        />
                                                                    }
                                                                    onLastEditableStepEntered={() => this._setLastStepTouched()}
                                                                    onReset={this._resetForm}
                                                                    lastStepIsEditableOne
                                                                    isEmbedded
                                               >
                                                   {
                                                       ['def', 'red', 'yellow', 'green', 'blue'].map(
                                                           termsType =>
                                                               <MyStep
                                                                   label={`resources.${RESOURCES.ACM_RULES}.fields.etc.terms.${termsType}`}
                                                                   key={termsType}
                                                                   isValid={!has(formSyncErrors, `etc.terms.${termsType}`)}
                                                               >
                                                                   <AcmTermsLangInput source={termsType}/>
                                                               </MyStep>
                                                       )
                                                   }
                                               </MyHorizontalStepper>
                                           </FormSection>
                                       </SimpleForm>
                                   </>
                           }


            />
        )
    }
}

const mapStateToProps = state => {
    return {
        acmTermsCreatorDialog: state.myUi.acmTermsCreatorDialog,
        etcOutput: formValueSelector(ACM_TERMS_INPUT_FORM)(state, 'etc'),
        isValid: isValid(ACM_TERMS_INPUT_FORM)(state),
        formSyncErrors: getFormSyncErrors(ACM_TERMS_INPUT_FORM)(state)
    }
};

const mapDispatchToProps = dispatch =>
    (
        {
            toggleAcmTermsCreatorDialog: (e) => {
                dispatch(toggleAcmTermsCreatorDialog(e));
            },
            resetForm: () => dispatch(reset(ACM_TERMS_INPUT_FORM)),
            destroyForm: () => dispatch(destroy(ACM_TERMS_INPUT_FORM)),
            initializeForm: () => dispatch(initialize(ACM_TERMS_INPUT_FORM, defaultTermsStruct)),
            // clearFields: (...fields) => {
            //     fields.forEach(field =>
            //         dispatch(change(ACM_TERMS_INPUT_FORM, field, null))
            //     );
            // },
            callbackFromAcmTermsDialog: (e) => dispatch(callbackFromAcmTermsDialog(e))
        }
    );

export default compose(
    translate,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(AcmTermsCreatorDialog);
