import React from 'react';
import {
    AutocompleteInput,
    Filter,
    Responsive,
    SimpleList,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    DisabledInput,
    LongTextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    RadioButtonGroupInput,
    SelectArrayInput,
    TextInput,
    EmailField,
    EmailInput,
    BooleanInput,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Show, SimpleShowLayout, FunctionField, NumberInput, ShowButton
} from 'react-admin';
import {dateFormatter, dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';

import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";

import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import MyPoiGeomField from "./PoisCustomComponents/MyPoiGeomField";
import {JSONInput, nullableJSONObjectValidation} from "../MyJSONInput";
import PoiSaveToolbar from "./PoisCustomComponents/PoiSaveToolbar";
import {MyRecordTitle} from "../MyTitle";
import {defaultCountry} from "../../../Configs";
import {canUpdate} from "../../logic/MyRestConfig";
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";

/*
CREATE TABLE public.poi
(

  uid uuid NOT NULL DEFAULT gen_random_uuid(),
  activity text,
  category text,
  contact text,
  country text,
  created timestamp with time zone DEFAULT now(),
  description text,
  etc jsonb,
  geojson jsonb,
  geometry geometry,
  govid uuid,
  input text NOT NULL,
  name text NOT NULL,
  othername text,
  permission text,
  type text,
  min integer NOT NULL,
  max integer NOT NULL,
  modified timestamp with time zone,
  start timestamp with time zone,
  stop timestamp with time zone,
  source text,
  url text,
  restriction text,
  unitid uuid NOT NULL,
  CONSTRAINT unit_fk FOREIGN KEY (unitid)
      REFERENCES public.unit (uid) MATCH SIMPLE
      ON UPDATE NO ACTION ON DELETE NO ACTION,
  CONSTRAINT poi_name_unique UNIQUE (name),
  CONSTRAINT min_max CHECK (min < max)
)
*/

//based on zones, will be extended... (WIP)

const PoisListBase = ({permissions, ...props}) => (
    <List {...props}
          title="!All! Pois"
          bulkActions={false}
          //filters={<FaasFilter/>}
          //sort={{ field: 'retrieved', order: 'DESC' }}

          actions={<BasicListActions />}
          pagination={<MyPagination/>}

    >
        <Datagrid>
            <TextField source="name" />
            <TextField source="type" />

            <ReferenceField label={'resources.zones.fields.unitName'}
                            source="unitid"
                            reference="unitsAll"
                            linkType="show"
            >
                <TextField source="name" />
            </ReferenceField>

            {canUpdate(props.resource, permissions) && <EditButton />}
            {!canUpdate(props.resource, permissions) && <ShowButton />}

        </Datagrid>
    </List>
);



const mapStateToProps = state => {
    return {
        mySelf : state.mySelf, //forEdit
        myAppExtras : state.myAppExtras, //forShow

    }
};

const enhance = compose(
    //translate,
    connect(
        mapStateToProps,
    ),
    //withStyles(styles)
);

export const PoisList = enhance(PoisListBase);


const PoisShowBase = ({permissions, myAppExtras, mySelf, dispatch, ...props}) => {
    const isOperator = (myAppExtras.appMode === 'OPERATOR');

    return (
        <Show {...props}
              actions={<BasicShowActions/>}
        >
            <SimpleShowLayout>
                {/* some fields disabled for now */}
                <TextField source="country"/>
                <TextField source="name"/>
                {/*<TextField source="othername"/>*/}

                <TextField source="type"/>
                <TextField source="min"/>
                <TextField source="max"/>

                {/*<TextField source="activity"/>*/}
                {/*<TextField source="source"/>*/}

                {/*<TextField source="contact"/>*/}
                {/*<TextField source="description"/>*/}
                {/*<TextField source="restriction"/>*/}

                {/*<MyTextField source="start"*/}
                             {/*format={dateTimeFormatter}/>*/}

                {/*<MyTextField source="stop"*/}
                             {/*format={dateTimeFormatter}/>*/}

                {isOperator //operator don't have straight access to units now, so using rest filter
                    ? <TextField source="unit.name"
                                 label={'resources.zones.fields.unitName'}
                    />
                    :
                    <ReferenceField source="unitid"
                                    label={'resources.zones.fields.unitName'}
                                    reference="unitsAll"
                                    linkType="show"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                }

                <MyPoiGeomField enableEdit={false}/>

            </SimpleShowLayout>
        </Show>
    );
};

export const PoisShow = enhance(PoisShowBase);


const simplyRequired = [required()];
const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];

const poiDefaultValue = { country: defaultCountry.key };

export const PoisCreate = ({permissions, ...props}) => (
    <Create {...props}
        /* undoable={false} -  no undo in create anyway */
            title={'resources.pois.create'}
    >
        <SimpleForm
            defaultValue={poiDefaultValue}
        >
            <DisabledInput source="country" defaultValue={defaultCountry.key}/>
            <DisabledInput source="input" defaultValue={"DR2EDITOR"}/>
            <DisabledInput source="type" defaultValue={"HOLDING_V0"}/>

            <ReferenceInput label={'resources.units.menu'}
                            source="unitid"
                            reference="units"
                            validate={simplyRequired}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <NumberInput source="min" validate={simplyRequired}/>
            <NumberInput source="max" validate={simplyRequired}/>

            <TextInput source="name" validate={defaultRequired}/>
            {/*<TextInput source="othername" validate={defaultNonRequired}/>*/}
        </SimpleForm>
    </Create>
);

const geomJSONValidation = [
    nullableJSONObjectValidation,
];

export const PoisEdit = ({permissions, ...props}) => (
    <Edit {...props}
          undoable={false}
          actions={<BasicEditActions />}
          title={<MyRecordTitle resource={'pois'}/>}
    >
        <SimpleForm
            defaultValue={{}}
            toolbar={<PoiSaveToolbar />}
        >
            <DisabledInput source="country"/>
            <DisabledInput source="input"/>
            <DisabledInput source="type"/>

            <ReferenceInput label={'resources.units.menu'}
                            source="unitid"
                            reference="units"
                            validate={simplyRequired}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="name" validate={defaultRequired}/>
            {/*<TextInput source="othername" validate={defaultNonRequired}/>*/}

            <NumberInput source="min" validate={simplyRequired}/>
            <NumberInput source="max" validate={simplyRequired}/>

            <MyPoiGeomField enableEdit={true}/>

            {/*< JSONInput source="geometry"*/}
                        {/*validate={geomJSONValidation}*/}
            {/*/>*/}

        </SimpleForm>
    </Edit>
);


