import React from 'react';
import {CardActions, ListButton} from 'react-admin';
import BackButton from "../../../../mui_test_suite/customComponents/BackButton";
import {withStyles} from "@material-ui/core/styles";
import AcmButton from "../AcmRulesCustomComponents/AcmButton";
import Configs, {FEATURES_V1_2_ENUMS, VERSION_ENUMS} from '../../../../Configs'

import compose from 'recompose/compose';
import {isAtcMaster} from "../../../logic/MyRestConfig";

const ACM_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACM);

const styles = {
    box: {
        display: "contents"
    },
    push: {
        marginLeft: "auto"
    }
};

//TODO refactor/combine with MyBasicShowActions
const ZoneShowActions = ({classes, basePath, data, permissions}) => {

    const isAtc = permissions ? isAtcMaster(permissions.roles) : false;

    return (
        <CardActions>
            <div className={classes.box}>
                <BackButton/>
                <div className={classes.push}/>
                {
                    ACM_ENABLED && isAtc && !!data && <AcmButton zoneType={data.type} designator={data.name} uid={data.uid} etc={data.etc}/>
                }
                <ListButton basePath={basePath}/>
            </div>
        </CardActions>
    )
};

export default compose(
    withStyles(styles),
)(ZoneShowActions);
