
import {deserializeZoneData, deserializeZonesV2, processZonesV2ToDeck} from "./ZonesListV2";
import {InspectTypes} from "../../modules2lib/common";


//currently wraps zones processing,
export function processPoisV2ToDeck({rawData = [], bottomCutoff = Number.MIN_SAFE_INTEGER, topCutoff = Number.MAX_SAFE_INTEGER}) {
    return processZonesV2ToDeck({rawData, bottomCutoff, topCutoff})
}

//currently wraps zones processing,
export function deserializePoisV2(poisDBarr) {
    return deserializeZonesV2(poisDBarr)
}

//currently wraps zones processing,
export function deserializePoiData(poiRecord, zoneTypesV2) {
    //fixme!!! replace inspectType when tested flow update

    if (!poiRecord.type) {
        poiRecord.type = '_TEST_';
    }

    return deserializeZoneData(poiRecord,  zoneTypesV2, '_TEST_');
}
