import {CRUD_DELETE, CRUD_UPDATE} from 'react-admin';
//import {DELETE, UPDATE} from 'react-admin';

import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

//custom override of crudDelete, with changed msg, AND forced refresh
export function myCrudArchive(resource, id, previousData, basePath) {
    var redirectTo = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'list';
    return {
        type: CRUD_DELETE, //yes we use delete rest call,
        payload: { id: id, previousData: previousData },
        meta: {
            resource: resource,
            fetch: API_VERBS.DELETE, //yes we use delete rest call,
            onSuccess: {
                notification: {
                    body: 'resources.archives.notifications.archived',
                    level: 'info',
                    messageArgs: {
                        smart_count: 1
                    }
                },
                redirectTo: redirectTo,
                refresh: true,
                basePath: basePath
            },
            onFailure: {
                notification: {
                    body: 'ra.notification.http_error',
                    level: 'warning'
                }
            }
        }
    };
};


export function myCrudUnarchive(resource, id, previousData, basePath) {
    var redirectTo = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'list';
    return {
        type: CRUD_UPDATE,
        payload: { id: id, data:{active: true} },
        meta: {
            resource: resource,
            fetch: API_VERBS.UPDATE,
            onSuccess: {
                notification: {
                    body: 'resources.archives.notifications.unarchived',
                    level: 'info',
                    messageArgs: {
                        smart_count: 1
                    }
                },
                redirectTo: redirectTo,
                refresh: true,
                basePath: basePath
            },
            onFailure: {
                notification: {
                    body: 'ra.notification.http_error',
                    level: 'warning'
                }
            }
        }
    };
};
