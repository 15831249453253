//hmm probably should be replaced with MyZoneInput, (with added missing features AND full screen mode),
//but for today, let's make it independent view based on old approach

import React, {createElement} from 'react';
//import PropTypes from 'prop-types';

import { put } from 'redux-saga/effects';

import {connect} from 'react-redux'
import { Notification, defaultTheme } from 'react-admin';
import {
    showNotification,
    hideNotification,
} from 'react-admin';


import { withRouter } from 'react-router-dom';
import { Authenticated } from 'react-admin';

import Button from '@material-ui/core/Button';

//import { translate } from 'ra-core';
import compose from 'recompose/compose';

import sizeMe from "react-sizeme";
import DataProvider from '../../dr_ra2/MyDataProvider'
import PubSub from "pubsub-js";
import get from 'lodash/get';

import {LiveMapMenu} from "../Components/_MyMenus";

import {deserializeZoneData} from '../../LogicV1Redux/oldLogic/ZonesListV2';
import EditorMap from "../Components/Maps/OldEditorMap";
import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

//in v1.1 we are removing zone edit, but later should at least start like field mission editor, so probably fullscreen will got outdated


const record2primitives = (record) => {
    console.error('deserializeZoneData fixme passed data');

    const zoneData = deserializeZoneData(record);
    PubSub.publish(PubSubSignals.NEW_ZONE_LOADED, zoneData);
    console.log('MyZoneV2', zoneData);
    return (zoneData && zoneData.primitive)?[zoneData.primitive]:[];
};

//fixme notifications/error support

class FSZoneEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editables: [] };
    }

    componentDidMount() {
        console.error('FSZoneEditor... basically was working, but temporary leaving as (not used in 1.1)');

        console.log('FSZoneEditor', this.props);
        console.log('FSZoneEditor', this.state);
        //put(showNotification('myroot.httperror', 'warning', {autoHideDuration: 10000}));
        //hmm what about parallel editing...
        if (this.props.location.record) {
            //fixme
            console.log('fixme record passed')
        } else {
            const dataProvider = DataProvider.getDataProvider();
            dataProvider(API_VERBS.GET_ONE, 'zones', { id: this.props.match.params.modelId })
                .then((req) => {
                    console.log(API_VERBS.GET_ONE, 'result', req );
                    //showNotification('myroot.auth.phoneVerified');
                    // push('/myaccount');
                    this.setState({editables:record2primitives(req.data) });

                })
                .catch((e) => {
                    console.error(e);
                    //showNotification('myroot.httperror', 'warning');
                });
        }
    }

    render() {
        const { width, height } = this.props.size;
        const { location, notification, showNotification } = this.props;

        const zoneId = this.props.match.params.modelId;
        const {editables} = this.state;
        return (
            <Authenticated authParams={{ foo: 'bar' }} location={location}>

            <div style={{position:"absolute", top:0, bottom:0, left:0, right:0}}>
                <div >
                    <EditorMap
                        height={height}
                        width={width}
                        editables={editables}
                        autofocus={editables}
                        menuStore={{zoneEditor:true, zoneId}}
                        menuComponent={LiveMapMenu}
                    />
                </div>
                <div style={{position:"absolute", top:0, height:10, right:0}}>My size is {width} px x {height}px</div>

                <div style={{position:"absolute", top:100, height:10, right:0}}>
                    <Button variant="outlined" color="primary"
                        //className={classes.button}
                            onClick={() => { console.log('onClick');showNotification('clicked!', 'info'); }}
                    >
                        ClickMe
                    </Button>
                </div>

                <div style={{position:"absolute", bottom:0, left:0}}>
                    {createElement(notification)}
                </div>
            </div>

            </Authenticated>
        )
    }
}


FSZoneEditor.defaultProps = {
    //appBar: AppBar,
    //menu: Menu,
    notification: Notification,
};

const mapStateToProps = state => {
    //console.log('FSZoneEditor mapStateToProps', state);
    return {
        isLoading: state.admin.loading > 0,
        myAuth: state.myAuth,
    }
};
//
const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            showNotification,
            //userLogout: userLogoutAction,
        }
    )
);

//export default sizeMe({ monitorHeight: true })(enhance(FSZoneEditor));

export default enhance(FSZoneEditor);

//export default sizeMe({ monitorHeight: true })(FSZoneEditor);
