import {Component} from "react";
import {SaveButton} from "ra-ui-materialui";
import {connect} from "react-redux";
import React from "react";
import {createApplication} from "../../../../LogicV1Redux/actions/ApplicationsActions";

class ApplicationCreateActionsButtonBase extends Component {

    _handleClick = () => {


        const {
            basePath,
            handleSubmit,
            submitOnEnter,
            handleSubmitWithRedirect,
            resource,
            dispatchApplicationCreate,
            showNotification,
            redirect,
        } = this.props;

        return handleSubmit(record => {

            const args = {
                resource,
                record,
            };

            dispatchApplicationCreate(args);
        });

    };

    render() {

        const {dispatchApplicationCreate, handleSubmit, handleSubmitWithRedirect, submitOnEnter, ...props} = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this._handleClick}
                {...props}
            />
        )
    }
}

export const mapDispatchToProps = dispatch =>
    (
        {
            dispatchApplicationCreate: (e) => {
                console.log('dispatchin application create', e);
                dispatch(createApplication(e))
            },
        }
    );

export default connect(
    null,
    mapDispatchToProps
)(ApplicationCreateActionsButtonBase);
