//special component for conflicts -> aka zonelegs resolution..
// but uses zone as resource and zonelegs as related list ... so hmm..
//maybe should be moved to zoneLegs.jsx. or zones?

import React, {Component} from 'react';

import { ArrayField, AutocompleteInput, Filter, List, Responsive, SimpleList, Edit, Create, Datagrid,
    ReferenceField, TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, RadioButtonGroupInput, SelectArrayInput, TextInput, EmailField, EmailInput, BooleanInput,  SimpleShowLayout, Show, ShowButton, FunctionField, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';

import {withStyles} from '@material-ui/core/styles';
import { translate } from 'ra-core';
import {connect} from 'react-redux';

import compose from 'recompose/compose';

import Button from '@material-ui/core/Button';

import { Link } from 'react-admin';
import { stringify } from 'query-string';

import MyZoneField from "./ZoneCustomComponents/MyOldZoneField";
import {zoneLegsCntFormatter, ZoneLegsCntFormatter} from "./ZoneLegs"

import {dateFormatter, dateTimeFormatter, formatDate} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';

import {zoneFixJoinLeg2LegZone} from '../../../LogicV1Redux/oldLogic/ZonesListV2'

import BasicShowActions from "../MyBasicShowActions";
import MyInputDialog from "../MyInputDialog";
import {MissionV2Statuses} from '../../../LogicV1Redux/constants/MissionsV2Enums'
import {ZoneLegsV2Statuses} from '../../../LogicV1Redux/constants/ZoneLegsV2Enums'

import {resolveZoneConflictAction} from '../../../LogicV1Redux/actions/ZoneLegsV2Actions'

class acceptButton extends Component {
    handleClick = () => {
        const { resolveZoneConflict, record } = this.props;
        console.log('accept', record);
        resolveZoneConflict({id:record.uid, data:{status:ZoneLegsV2Statuses.REJECTED}});
    };

    render() {
        return <Button onClick={this.handleClick}>Accept</Button>;
    }
}

class rejectButton extends Component {

    state = {
        rejectDialogOpen: false,
    };

    handleDialogClose = () => {
        this.setState({
            rejectDialogOpen: false,
        })
    };

    handleReject = (additionalText) => {
        const { resolveZoneConflict, record } = this.props;
        console.log('reject', record, additionalText);
        resolveZoneConflict({id:record.uid, data:{status:ZoneLegsV2Statuses.REJECTED}});
        this.handleDialogClose();
    };

    handleClick = () => {
        this.setState({
            rejectDialogOpen: true,
        })
    };

    render() {
        return (
            <div>
                <Button onClick={this.handleClick}>Reject</Button>
                <MyInputDialog title={'myroot.zoneConflictResolver.rejectDialogTitle'}
                                                               defaultText={'myroot.zoneConflictResolver.rejectDialogDefaultText'}
                                                               label={'myroot.zoneConflictResolver.rejectDialogLabel'}
                                                               confirm={'myroot.action.send'}
                                                               cancel={'ra.action.cancel'}
                                                               onConfirm={this.handleReject}
                                                               onClose={this.handleDialogClose}
                                                               isOpen={this.state.rejectDialogOpen }
                                                               confirmColor={'warning'}
                />
            </div>
        );
    }
}

export const AcceptButton = connect(null, {
    resolveZoneConflict: resolveZoneConflictAction,
})(acceptButton);

export const RejectButton = connect(null, {
    resolveZoneConflict: resolveZoneConflictAction,
})(rejectButton);


const ZoneConflictResolverForm = (props) => {
    console.log('ZoneConflictResolverForm props', props);
    const record = zoneFixJoinLeg2LegZone(props.record);

    record.filteredZoneLegs = record.zoneLeg.filter(zoneLeg => !zoneLeg.status);
    //hmm sorting disabled now
    return (
        <SimpleShowLayout {...props}
            record={record}
        >

            <TextField label='id'
                       source="id"/>
            {/* FIXME? move to zonefield? like missionfield? */}
            <ArrayField label='' source="filteredZoneLegs">
                <Datagrid>
                    <MyTextField label={'resources.zoneLegs.fields.created'}
                                 sortable={false}
                                 format={dateTimeFormatter}
                                 source="created" />
                    <TextField sortable={false} source="status" />
                    <AcceptButton/>
                    <RejectButton/>

                </Datagrid>
            </ArrayField>
            {/*
            <MyTextField label={'resources.zones.zoneLegsCnt'}
                         format={zoneLegsCntFormatter}
                         source="zoneLeg" />
            */}
            <MyZoneField showConflicts={true}/>
        </SimpleShowLayout>
    )
};


//hmm.. rewrite to component?
const myZoneConflictsResolver = (props) => {
    console.log('myZoneConflictsResolver props', props);
    const basePath = '';
    console.warn('setting empty basePath hmm? fixme?', basePath);
    //props.getZoneConflictsAction({id: pops.match.params.modelId});
    const id = props.match.params.modelId;
    //return <div>fixme</div>
    return (
        <Show {...props}
              id={id}
              basePath={basePath}
              resource={'zones'}
              actions={<BasicShowActions/>}
        >
            <ZoneConflictResolverForm/>
        </Show>
    )
};



const mapStateToProps = state => {
    //lastDroneModels = state.admin.resources.droneModels;
    console.log('myZoneConflictsResolver mapStateToProps', state);
    return {
        mySelf : state.mySelf,
    }
};


// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({change}, dispatch);
// };

const mapDispatchToProps = dispatch =>
    (
        {
            // getZoneConflictsAction : (e) => {
            //     console.log('dispatchin getZoneConflicts', e);
            //     dispatch(getZoneConflicts(e))
            // }
        }
    );

const enhance = compose(
    translate,
    //withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);


export const MyZoneConflictsResolver = enhance(myZoneConflictsResolver);
//.... and custom button link


const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
};


const zoneConflictResolverButtonLink = ({
                        basePath = '',
                        label = 'ra.action.edit',
                        record = {},
                        ...rest
                    }) => {
    let link = `/zone-conflict-resolver/${encodeURIComponent(record.id)}`;
    console.log('ZoneConflictResolverButtonLink', record);
    return (
    <Button
        variant="outlined"
        //color="primary"
        component={Link}
        to={link}
        //className={styles.link}
    >
        <ZoneLegsCntFormatter record={record}/>
    </Button>
)};


// const EditButton = ({
//                         basePath = '',
//                         label = 'ra.action.edit',
//                         record = {},
//                         ...rest
//                     }) => (
//     <Button
//         component={Link}
//         to={linkToRecord(basePath, record.id)}
//         label={label}
//         {...rest}
//     >
//         <ContentCreate />
//     </Button>
// );

const enhanceBtn = compose(
    withStyles(styles),
    translate
);

export const ZoneConflictResolverButtonLink = enhanceBtn(zoneConflictResolverButtonLink);