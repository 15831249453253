import {CHANGE_TO_NEXT_THEME} from "./MyThemeActions";


//i think this is rather nasty place for theme by host initilization
// ...but otherwise i would have to refactor theme initilization / app init  (LGZ)

function getDefaultThemeByHost() {
    const host = window.location.hostname;
    //console.log(host);

    // if (host.startsWith('local')) {
    //     return 2;
    // }

    if (host.startsWith('mil.')) {
        return 2;
    }

    else if (host.startsWith('lau.')) {
        return 3;
    }

    else if (host.startsWith('caa.')) {
        return 3;
    }

    else return 1;

}

const initialState = {
    themeNo: getDefaultThemeByHost()
};

//TODO refactor - move to MyUiReducer
export default (state = initialState, {type}) => {
    switch (type) {
        case CHANGE_TO_NEXT_THEME:
            let {themeNo} = state;
            console.log('----- self reducer state CHANGE_TO_NEXT_THEME:', type, themeNo);
            themeNo = themeNo+1 > 4 ? 0 : themeNo+1;
            console.log('----- self reducer after CHANGE_TO_NEXT_THEME:', type, themeNo);
            return {...state, themeNo: themeNo};
        default:
            return state;
    }
}