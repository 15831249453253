import React from 'react';
import {
    Filter,
    Responsive,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    DisabledInput,
    LongTextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    SimpleShowLayout,
    Show,
    ShowButton,
    required,
    minLength,
    maxLength,
    NumberInput,
    FormDataConsumer,
    BooleanField,
    translate
} from 'react-admin';
import { dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';
//import DateTimeInput from 'aor-datetime-input';
import {TextInput as DateTimeInput} from 'react-admin';  //FIXME
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {canCreate, canUpdate, isSuperAdmin, RESOURCES} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import Labeled from "ra-ui-materialui/lib/input/Labeled";

import MyZoneField from "./ZoneCustomComponents/MyZoneField";
import ZoneSaveToolbar from "./ZoneCustomComponents/ZoneSaveToolbar";


//to review removal
//import MyZoneInput from "../../display_app/MyZoneInput_Test";
//import {zoneLegsCntFormatter, ZoneLegsCntFormatter} from "./ZoneLegs"
//import {ZoneConflictResolverButtonLink} from "./MyZoneConflictsResolver"


import MyTransitionDecorator from "../MyTransitionDecorator";
import {isLockedSourceZone} from "../../../LogicV1Redux/oldLogic/ZonesListV2";
import {MyRecordTitle, MyResourceListTitle} from "../MyTitle";
import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import Configs, {defaultCountry, FEATURES_V1_2_ENUMS} from "../../../Configs";
import {formValueSelector} from "redux-form";
import MyIlikeSearch from "./MyIlikeSearch";
import {ZoneDescriptionInput} from "./ZoneCustomComponents/ZoneDescriptionInput";

import ZoneShowActions from "./ZoneCustomComponents/ZoneShowActions";
import AcmGrid from "./AcmRulesCustomComponents/AcmGrid";
import {has} from "lodash";
import Typography from "@material-ui/core/Typography";
import ZoneDeleteButton from "./ZoneCustomComponents/ZoneDeleteButton";


//import {validEditableGeometry} from "../MyGeomInput"

/* Zone 08.11.18
    uid uuid NOT NULL DEFAULT gen_random_uuid(),
    name text NOT NULL,
    type text,
    min text,
    max text,
    activity text,
    source text,
    contact text,
    description text,
    restriction text,
    geometry geometry,
    etc jsonb,
    unitid uuid,
    othername text,
    country text,
    created timestamp with time zone DEFAULT now(),
    govid uuid,
    start timestamp with time zone,
    stop timestamp with time zone,
    CONSTRAINT zone_pkey PRIMARY KEY (uid),
    CONSTRAINT unit_fk FOREIGN KEY (unitid)
        REFERENCES public.unit (uid) MATCH SIMPLE
        ON UPDATE NO ACTION ON DELETE NO ACTION,
    CONSTRAINT zone_name_unique UNIQUE (name)
*/


const createZoneForm = 'record-form'; //WRRR! must be default
const ZONE_DELETE_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ZONE_DELETE);

const ZoneFilter = (props) => (
    <Filter {...props}>
        <MyIlikeSearch source="name"
                       label='resources.zones.fields.name'/>
        <MyIlikeSearch source="type"
                       label='resources.zones.fields.type'/>

        <ReferenceInput label="Unit" source="unitid" reference="units">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);


const ZoneActionButtons = (props) => {
    const {mySelf, record, permissions, ...rest} = props;
    //console.log('ZoneActionButton', mySelf, record, permissions);

    if (!permissions) return null;

    //fixme -> is only viewer in unit is not supported yet
    const isOwned = !!(mySelf.units.find(unit => unit.uid === record.unitid));
    const isOwnedOrSuper = isOwned || isSuperAdmin(permissions.roles);
    const canEdit = !isLockedSourceZone(record) && canUpdate(props.resource, permissions);

    return (
        <div style={{display: 'flex'}}>
            {
                (isOwnedOrSuper && canEdit)
                    ? <EditButton record={record} {...rest} />
                    : <ShowButton record={record} {...rest}/>
            }
            {
                ZONE_DELETE_ENABLED
                &&
                canCreate(RESOURCES.ZONE_ARCHIVE, permissions)
                &&
                //TODO Now no support for SU zone delete on backend? Should be?
                <ZoneDeleteButton disabled={!isOwned} record={record} {...rest}/>
            }
        </div>
    )
};

const ZonesListBase = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureZonesListBase {...props}/>
    </MyTransitionDecorator>
);

const PureZonesListBase = (props) => {
    console.log('can', props);
    const {permissions, mySelf, myAppExtras, dispatch, ...rest } = props;
    return (
        <List {...rest}
              filters={<ZoneFilter/>}
              sort={{ field: 'created', order: 'DESC' }}
              bulkActions={false}
              actions={<BasicListActions />}
              title={<MyResourceListTitle resource={'zones'}/>}
              pagination={<MyPagination/>}

            //params={{ is_published: true }}
        >
            <Responsive
                // small={
                //     <SimpleList
                //         primaryText={record => record.name}
                //         secondaryText={record =>
                //             permissions === 'test' ? record.status : null}
                //     />
                // }
                small={
                    <Datagrid>
                        {/*atc columns reviewed in #96/98 issues*/}

                        {/*<MyTextField source="created"*/}
                                     {/*format={dateTimeFormatter}/>*/}
                        <TextField source="name" />
                        <TextField source="type" />

                        <ReferenceField label={'resources.zones.fields.unitName'}
                                        source="unitid"
                                        reference="unitsAll"
                                        linkType="show"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        {/* reenable for non editable?
                    <MyTextField sortable={false}
                                 format={zoneLegsCntFormatter}
                                 source="zoneLeg" />
                    */}
                        <ZoneActionButtons resource={props.resource}
                                          permissions={permissions}
                                          mySelf = {mySelf}
                                          record="record" />
                        {/*{canEditZone({props, permissions}) && <EditButton />}*/}
                        {/*{!canEditZone({props, permissions}) && <ShowButton />}*/}

                        {/*commenting out temporarily, as conflicts counter returns wrong results due to backend issue (z-filtering)*/}

                        {/*<ZoneConflicts2ResolverLink />*/}

                    </Datagrid>
                }
            />
        </List>
    );
};

const mapStateToProps = state => {
    return {
        mySelf : state.mySelf, //forEdit
        myAppExtras : state.myAppExtras, //forShow
        locale: state.i18n.locale,
    }
};

const enhance = compose(
    //translate,
    connect(
        mapStateToProps,
    ),
    //withStyles(styles)
);

export const ZonesList = enhance(ZonesListBase);

export const ZonesShowBase = ({permissions, myAppExtras, mySelf, dispatch, locale, translate, ...props}) => {
    const isOperator = (myAppExtras.appMode === 'OPERATOR');
    const isSuper = permissions && isSuperAdmin(permissions.roles);

    return (
        <Show {...props}
              actions={<ZoneShowActions {...{permissions}}/>}
              title={<MyRecordTitle resource={'zones'}/>}
        >
            <SimpleShowLayout>

                <TextField source="country" label={'resources.zones.fields.country'}/>
                <TextField source="name" label={'resources.zones.fields.name'}/>
                <TextField source="othername" label={'resources.zones.fields.othername'}/>

                <TextField source="type" label={'resources.zones.fields.type'}/>
                {/*0ft AMSL as GND*/}
                <TextField source="min" label={'resources.zones.fields.min'}/>
                <TextField source="max" label={'resources.zones.fields.max'}/>

                <TextField source="activity" label={'resources.zones.fields.activity'}/>
                <TextField source="source" label={'resources.zones.fields.source'}/>

                <TextField source="contact" label={'resources.zones.fields.contact'}/>
                <TextField source={`description.${locale.toLowerCase()}`}/>
                <TextField source="restriction" label={'resources.zones.fields.restriction'}/>

                <MyTextField source="start"
                             label={'resources.zones.fields.start'}
                             format={dateTimeFormatter}/>

                <MyTextField source="stop"
                             label={'resources.zones.fields.stop'}
                             format={dateTimeFormatter}/>
                {isOperator //operator don't have straight access to units now, so using rest filter
                    ? <TextField source="unit.name"
                                 label={'resources.zones.fields.unitName'}
                    />
                    :
                    <ReferenceField source="unitid"
                                    label={'resources.zones.fields.unitName'}
                                    reference="unitsAll"
                                    linkType="show"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                }

                <MyZoneField showEditLink={false} showGeometry={!isOperator}/>
                <br/>
                {
                    isSuper &&
                    <FormDataConsumer>
                        {
                            ({formData, dispatch, ...rest}) => {
                                const isCtr = formData.type === 'CTR';
                                const isRpa =  formData.type === 'RPA';
                                const acmAsm = has(formData, 'etc.acm.asm') && formData.etc.acm.asm;
                                const acmTwr =  has(formData, 'etc.acm.twr') && formData.etc.acm.twr;
                                const isRpaAcmEnabled = isRpa && acmAsm || acmTwr;
                                return  (
                                    <>
                                        {
                                            (isCtr || isRpa) &&
                                            <Typography variant={'body2'}>
                                                {translate(`resources.${RESOURCES.ACM_RULES}.name`, {smart_count: 2})}
                                            </Typography>
                                        }
                                        {isRpa &&
                                        <Labeled label={`resources.${RESOURCES.ACM_RULES}.acmAsmToggle`}>
                                            <BooleanField source='etc.acm.asm' record={formData}/>
                                        </Labeled>
                                        }
                                        {isRpa &&
                                        <Labeled label={`resources.${RESOURCES.ACM_RULES}.acmTwrToggle`}>
                                            <BooleanField source='etc.acm.twr' record={formData}/>
                                        </Labeled>
                                        }
                                        {(isCtr || isRpaAcmEnabled) && <AcmGrid designator={isCtr ? formData.name : formData.etc.master}/>}
                                    </>
                                )
                            }
                        }
                    </FormDataConsumer>
                }

            </SimpleShowLayout>
        </Show>
    );
};


export const ZonesShow = compose(
    translate,
    connect(mapStateToProps),
    //withStyles(styles)
) (ZonesShowBase);


const simplyRequired = [required()];
const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];

const zoneDefaultValue = { country: defaultCountry.key };

const draTypes = [
    {id: 'DRAI', name: 'DRAI'},
    {id: 'DRAP', name: 'DRAP'},
    {id: 'DRAR', name: 'DRAR'},
];



//fixme -> min, max, activity, zone name generator
class ZonesCreateBase extends React.Component {

    render() {
        const {permission, type, dispatch, ...props} = this.props;

        return (
            <Create {...props}
                    title={'resources.zones.create'}
                /* undoable={false} -  no undo in create anyway */
            >
                <SimpleForm // form={'zone-create-form'} // must be default
                    defaultValue={zoneDefaultValue}
                    toolbar={<ZoneSaveToolbar />}

                >

                    <DisabledInput source="input" defaultValue={"DR2EDITOR"}/>

                    {/*only type creatable DRAI, DRAP, DRAR*/}
                    {/*<DisabledInput source="type" defaultValue={"DRAI"}/>*/}

                    <SelectInput source="type"
                                 label="resources.notams.fields.zonetype"
                                 choices={draTypes}
                                 allowEmpty={false}
                                 validate={simplyRequired}
                                 translateChoice={false}
                    />

                    <DisabledInput source="country" defaultValue={defaultCountry.key}/>

                    <ReferenceInput label={'resources.units.menu'}
                                    source="unitid"
                                    reference="units"
                                    validate={simplyRequired}

                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <NumberInput source="min" validate={simplyRequired}/>
                    <NumberInput source="max" validate={simplyRequired}/>

                    <TextInput source="name" validate={defaultRequired}/>
                    <TextInput source="othername" validate={defaultNonRequired}/>

                    <ZoneDescriptionInput formName={createZoneForm}
                                          zoneTypeName={type}
                                          forceTemplate
                    />

                    <FormDataConsumer>
                        {
                            ({formData}) => {
                                console.log('formData', formData);
                                return (<MyZoneField enableEdit={true} unitUid={formData.unitid}/>);
                            }
                        }
                    </FormDataConsumer>


                </SimpleForm>
            </Create>
        )
    }
}

const enhanceZoneType = compose(
    connect(
        state => ({
            type: formValueSelector(createZoneForm)(state, 'type'),
        }),
        null
    )
);
export const ZonesCreate = enhanceZoneType(ZonesCreateBase);


const ZonesEditBase = ({permissions, type, dispatch, ...props}) => {
    console.log('ZonesEdit', props);

    return (
        <Edit {...props}
              undoable={false}
              actions={<BasicEditActions />}
              title={<MyRecordTitle resource={'zones'}/>}
        >
            <SimpleForm // form={'zone-edit-form'} // must be default
                        defaultValue={{}}
                        toolbar={<ZoneSaveToolbar />}

            >
                {/*commenting out temporarily, as conflicts counter returns wrong results due to backend issue (z-filtering)*/}

                {/*<ZoneConflictResolverButtonLink />*/}

                <DisabledInput source="country"/>
                <DisabledInput source="input"/>

                <ReferenceInput label={'resources.units.menu'}
                                source="unitid"
                                reference="units"
                                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <TextInput source="name" validate={defaultRequired}/>
                <TextInput source="othername" validate={defaultNonRequired}/>

                {/*only type creatable DRAI, DRAP, DRAR*/}
                {/*<DisabledInput source="type" defaultValue={"DRAI"}/>*/}

                <SelectInput source="type"
                             label="resources.notams.fields.zonetype"
                             choices={draTypes}
                             allowEmpty={false}
                             validate={simplyRequired}
                             translateChoice={false}
                />

                <NumberInput source="min" validate={simplyRequired}/>
                <NumberInput source="max" validate={simplyRequired}/>

                <DateTimeInput source="start"
                               options={{
                                   //container : "dialog", //container='dialog' for DatePicker is hard-code option.
                                   //minDate : minDate,
                                   //openToYearSelection : true
                               }}
                />
                <DateTimeInput source="stop"
                               options={{
                                   //container : "dialog", //container='dialog' for DatePicker is hard-code option.
                                   //minDate : minDate,
                                   //openToYearSelection : true
                               }}
                />

                <TextInput source="activity"/>

                <TextInput source="source"/>
                <LongTextInput source="contact"/>

                <ZoneDescriptionInput formName={createZoneForm}
                                      zoneTypeName={type}
                                      forceTemplate
                                      isEdit
                />

                <LongTextInput source="restriction"/>

                <FormDataConsumer>
                    {
                        (props) => {
                            const {formData, record} = props;
                            console.log('formData props', props);
                            return (
                                <MyZoneField enableEdit={true}
                                             unitUid={formData.unitid}
                                             record={record}
                                />);
                        }
                    }
                </FormDataConsumer>

                {/*<MyZoneField enableEdit={true}/>*/}

                {/* not working yet.
                <Field //damn, hidden field see geomInput, fast
                    name="_geomInputError"
                    //val={locationAccessToken}
                    component={({ input, val }) =>
                    {
                        input.onChange(val);
                        return null
                    }}
                />
                */}
            </SimpleForm>
        </Edit>
    );
};

export const ZonesEdit = enhanceZoneType(ZonesEditBase);
