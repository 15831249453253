//was moved.. latest changes should be reaplied to correct version

//v0.1
//simplified version for display in react admin as inner record view
//to unify with AppDeckGl (params) .. but later..

//extended version of simplified map, but without live data


import React, {Component} from "react";
import { findDOMNode } from 'react-dom';

import 'mapbox-gl/dist/mapbox-gl.css';

import PubSub from 'pubsub-js';
import sizeMe from 'react-sizeme';

import "../../simplified.css";

import MapGL from 'react-map-gl'


import isEqual from "lodash.isequal";
import get from 'lodash.get';

import { processRedraws as processTestRedraws, getTestIco, getTestPath, getTestPoly, getTestPoints, getTestCursor} from "../../glSetups/oldCommon/testDeckInits";
import {isPosEqual} from "../../glSetups/oldCommon/commonTurf";



import {
    ICON_MAPPING,
    LIGHT_SETTINGS,
    //processRedraws,
    invalidateZones,
    invalidateAlerts,

} from "../../glSetups/oldCommon/deckInits";

import DeckGLOverlay from '../../Components/DeckGLOverlay';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {mapboxOpts, myAppDefaults} from "../../ConfigurableOpts";

import {
    primitives2newViewport,
    primitives2polys
} from "../../glSetups/oldCommon/_CommonStuff";

import {RndDrawToolbar} from "../../Components/DeckGLDrawToolbar"

import {MyMenu} from "../../Components/_MyMenus";
import {updateDrawLayers} from "../../glSetups/deckDraw/deckEditor";

import "../../simplified.css";

//import {_updateDrawLayers} from "../gl_map/glSetups/deckDraw"

import ZonesListV2 from "../../../LogicV1Redux/oldLogic/ZonesListV2";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";
import AdsbSources from "../../../LogicV1Redux/oldLogic/RtdmSources";

import MyVerticalSlider from "../../../mui_test_suite/customComponents/MyVerticalSlider";
import {
    makeHeightBundleForEditor,
    EditorMapHeightBox
} from "../MyHeightBoxes";

import {invertColorToCSSHexString} from "../../../dr_ra2/utils/cssColorInverter";
import {withStyles} from '@material-ui/core/styles';

import {MAX_LEG_AREA_KM_SQ} from "../../../LogicV1Redux/constants/LegsV2Constants"

import {EditorMapStateConstructor, initializeLayersObject} from "../../glSetups/newCommon/mapStateConstructors";
import {EditorMapLayersRedraw} from "../../glSetups/newCommon/mapStateLayersRedraws";
import {clearAllEditablePrimitives, setEditablePrimitives} from "../../glSetups/deckDraw/deckDrawStoreBridge";

let map;

const handleClick = (event) => {
    console.log(event.lngLat);
};

let tick = 0;

const tickInterval = myAppDefaults.tick; //back to main tick as 1 sec

const defaultMenuOffset = 30; //hmm


const styles = theme => ({
    slider: {
        position: "absolute",
        borderRadius: "5px",
        padding: "10px 10px",
        width: "50px",
        top: "5px",
        bottom: "40px",
        right: "10px",
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.75),
        display: "flex",
        flexDirection: "column",
    },
    amslBox: {
        position: "absolute",
        borderRadius: "5px",
        padding: "10px 10px",
        // width: "50px",
        // top: "40px",
        bottom: "40px",
        left: "10px",
        fontSize: "smaller",
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.75),
    }
});

class EditorMap extends Component {

    constructor() {
        super();
        this.state = EditorMapStateConstructor();

        this.zonesDirty = true; //hmm
        //logger.debug('MyCheckInFSMView', this.state);
        //this.onTick = this.onTick.bind(this);
        this.forceRedraw = this.forceRedraw.bind(this);
        this.onMinMaxChange = this.onMinMaxChange.bind(this);
        //this.onMenuChange = this.onMenuChange.bind(this);
        //this.toggleTickUpdate = this.toggleTickUpdate.bind(this);

    };

    forceRedraw = ({onTick} = {}) => {

        //console.log(newState);
        this.setState(
            EditorMapLayersRedraw({
                onTick,
                prevStatePolys: this.state.polys,
                props: this.props,
                alertsDirty: this.alertsDirty,
                zonesDirty: this.zonesDirty
            })
        );

        //disabling tick publish
        //if (!onTIck)
        //PubSub.publish(PubSubSignals.TICK, tick);


    };

    onMenuChange = menuStore => {
        console.log('EditorMap menu store change', menuStore);

        //dang! previous state needed!
        if (this.lastMenushowZonesV2 !== menuStore.showZonesV2) {
            invalidateZones();
            this.zonesDirty = true;
            this.lastMenushowZonesV2 = menuStore.showZonesV2;

        }

        this.setState({menuStore});

        this.forceRedraw();
    };

    //fixme to redux
    componentDidMount() {

        //no tick update in editoe
        // if (this.state.menuStore.tickUpdate) {
        //     this.interval = setInterval(this.onTick, tickInterval);
        // }

        // this.pubsubZonesRulesChange = PubSub.subscribe(PubSubSignals.ON_ZONES_RULES_CHANGE, (msg, data) => {
        //     invalidateZones();
        //     this.zonesDirty = true;
        //     this.forceRedraw();
        //
        // });

        //yikes. either we should have editor/draw store or pass dispatch callback to draw module (unconnected btw)...
        //for now - sprint as usually - pubsub calls redux IN context
        // this.pubsubDrawEnd = PubSub.subscribe(PubSubSignals.DRAW_UPDATED, (msg, data) => {
        //     // console.log('pubsub', msg, data);
        //     // this.setState({primitive:data});
        //
        //     console.log('onDrawEnd editor');
        //     console.log('========pubsub2reduxWrapCb', msg, data);
        //     if (this.props.)
        //     let updatedPrimitive = data;
        //
        // });
    }

    componentWillUnmount() {
        clearAllEditablePrimitives();
        clearInterval(this.interval);
        //window.removeEventListener('resize', this.resize.bind(this));
        PubSub.unsubscribe(this.pubsubZonesRulesChange);
        //PubSub.unsubscribe(this.pubsubDrawEnd);

    };

    componentWillReceiveProps(nextProps) {
        console.log('editormap componentWillReceiveProps', nextProps);
        if (this.props !== nextProps) {
            console.log('editormap new props received', this.props);

            //FIXME
            console.error('fix primitives to viewport mechanics IN DECK.GL!')
            //const opts = {viewportData:prevViewport, primitives:nextProps.autofocus};
            //const {viewportData} = primitives2newViewport(opts);

            //console.error('fixme to redux! editormap')

            const polys = primitives2polys(nextProps.primitives);

            console.log('polys');

            this.setState({polys});
        }
    }

    onMinMaxChange = (value) => {
        console.log("oac", value);
        //aglMinMax = value;
        if (this.props.currentEditablePrimitive)
            this.props.currentEditablePrimitive.aglMinMax = value;

        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);
        //ugh
        this.forceUpdate()
    };

    render() {
        // HACK: Wait for setting relative to parent element.
        //if (!this.state.isMounted) return <div />;
        updateDrawLayers("render editor map"); //hmm


        //console.log('editormap state' ,this.state);
        //console.log('editormap props' ,this.props);


        //TODO optimize... later

        //err ..
        const {icos, points, paths, cursor, polys, helperPolys } = this.state;

        console.log('polys', polys)

        const {menuComponent, menuStore, currentEditablePrimitive, editablePrimitives, classes} = this.props;

        let displayedPolys = []; // (menuStore.showZonesV2)?helperPolys:polys;

        //hmm.. states got messy since we are using zones duplicates from GET_ONE with zonelegs AND zones..
        if (menuStore.showZonesV2) {

            displayedPolys = helperPolys;

        } else if (menuStore.showWarnings) {

            displayedPolys = polys;

        }

        const showDrawToolbar = true; //hmm

        //what about zones?
        const defaultAglMinMax = [0, 0];

        let aglMinMax = (currentEditablePrimitive && currentEditablePrimitive.aglMinMax)
            ? currentEditablePrimitive.aglMinMax
            : defaultAglMinMax;

        //currently mission editor!
        const heightBundle = makeHeightBundleForEditor(currentEditablePrimitive, editablePrimitives, defaultAglMinMax);

        ///hmm render block should be combined/moved ...

        //ugh
        return (

            <div className="map-window">

                <DeckGLOverlay
                    cursor = {cursor}
                    icos = {icos}
                    polys = {displayedPolys}
                    points = {points}
                    paths = {paths}

                />

                {/*test display in full screen! and fix css ?*/}
                <MyMenu menuComponent={menuComponent} menuStore={menuStore} onMenuChange={this.onMenuChange} />
                {showDrawToolbar? <RndDrawToolbar/> : null }

                <div className={classes.slider}>

                    <MyVerticalSlider min={0} max={300}
                                      //defaultValue={[0, 150]}
                                      value={aglMinMax} //ugh?
                                      marks={{100: '100m', 200: '200m', 300: '300m'}}
                                      step={10}
                                      onChange={this.onMinMaxChange}
                                      //onAfterChange={onAfterMinMaxChange}
                    />

                </div>

                <EditorMapHeightBox heightValuesBundle={heightBundle}/>

            </div>

        );
    }
}

const mapStateToProps = state => {
    console.log('EditorMap, mapStateToProps', state);

    const drawEditorData = state.drawEditorData;
    const currentEditablePrimitive = drawEditorData.currentPrimitive;
    const currPrimitiveCentroid = (currentEditablePrimitive)?currentEditablePrimitive.centroid:null;

    return {
        //isLoading: state.admin.loading > 0, //fix rerenders on props change
        alertsV1 : state.alertsV1,
        //checkinsV1 : state.checkinsV1,
        zonesV2 : state.zonesV2,
        drawMode : drawEditorData.drawMode, //unused?
        editablePrimitives : drawEditorData.editablePrimitives,
        currentEditablePrimitive,
        currPrimitiveCentroid, //required to update dirtiness
    }
};

const enhance = compose(
    withStyles(styles),
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        null
    )
);

export default enhance(EditorMap);
