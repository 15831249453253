export const GOV_EVENTS = {
    REGISTER: "REGISTER",
    PHONE_VERIFY: "PHONE_VERIFY",
    PHONE_CHANGE: "PHONE_CHANGE",
    PASSWORD_RESET: "PASSWORD_RESET",
    LOGIN: "LOGIN",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT: "LOGOUT",
    ACCOUNT_UPDATE: "ACCOUNT_UPDATE",
    CERT_READ: "CERT_READ",
    CERT_VALIDATED: "CERT_VALIDATED",
    CERT_REJECTED: "CERT_REJECTED",
    DRONE_VALIDATED: "DRONE_VALIDATED",
    DRONE_REJECTED: "DRONE_REJECTED",
    MISSION_APPROVE: "MISSION_APPROVE",
    MISSION_REJECT: "MISSION_REJECT",
    EMAIL_VERIFY: "EMAIL_VERIFY",
    VALIDATION_ERROR: "VALIDATION_ERROR",
    BAN_OPERATOR: "BAN_OPERATOR",
    OPERATION_UPDATE: " OPERATION_UPDATE",
};

export const OPERATOR_EVENTS = {
    REGISTER: "REGISTER",
    ANONYMOUS_REGISTER: "ANONYMOUS_REGISTER",
    EMAIL_MALFORMED: "EMAIL_MALFORMED", // catched in ./node_modules/loopback/common/models/user.js
    EMAIL_EXISTS: "EMAIL_EXISTS",       // catched in ./node_modules/loopback/common/models/user.js
    USER_EXISTS: "USER_EXISTS",         // catched in ./node_modules/loopback/common/models/user.js
    EMAIL_VERIFY: "EMAIL_VERIFY",
    EMAIL_CONFIRM: "EMAIL_CONFIRM",
    INVALID_TOKEN: "INVALID_TOKEN",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    EMAIL_CHANGE: "EMAIL_CHANGE",
    EMAIL_CHANGE_EMAIL_EXISTS: "EMAIL_CHANGE_EMAIL_EXISTS",
    EMAIL_CHANGE_SENT: "EMAIL_CHANGE_SENT",
    EMAIL_CHANGE_SENT_ERROR: "EMAIL_CHANGE_SENT_ERROR",
    EMAIL_CHANGE_INFORMATIONAL_SENT: "EMAIL_CHANGE_INFORMATIONAL_SENT",
    //EMAIL_CHANGE_EMAIL_SENT: "MAIL_CHANGE_EMAIL_SENT", // same as email change sent?
    PHONE_VERIFY: "PHONE_VERIFY",
    PHONE_VERIFY_UID_NOT_FOUND: "PHONE_VERIFY_UID_NOT_FOUND",
    PHONE_NUMBER_EXISTS: "PHONE_NUMBER_EXISTS",
    PHONE_NUMBER_INVALID: "PHONE_NUMBER_INVALID",
    PHONE_NUMBER_MALFORMED: "PHONE_NUMBER_MALFORMED",
    TWILIO_ERROR: "TWILIO_ERROR",
    PHONE_VERIFIED: "PHONE_VERIFIED",
    VERIFY_CODE_CODE_INVALID: "VERIFY_CODE_CODE_INVALID",
    VERIFY_CODE_UID_INVALID: "VERIFY_CODE_UID_INVALID",
    PASSWORD_RESET: "PASSWORD_RESET",
    LOGIN: "LOGIN",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT: "LOGOUT",
    ACCOUNT_UPDATE: "ACCOUNT_UPDATE",
    ACCOUNT_DISABLE: "ACCOUNT_DISABLE",
    CERT_CREATE: "CERT_CREATE",
    CERT_DEACTIVATED: "CERT_DEACTIVATED",
    CERT_UPDATE: "CERT_UPDATE",
    CERT_VALIDATED: "CERT_VALIDATED",
    DRONE_CREATE: "DRONE_CREATE",
    DRONE_UPDATE: "DRONE_UPDATE",
    DRONE_DEACTIVATED: "DRONE_DEACTIVATED",
    DRONE_VALIDATED: "DRONE_VALIDATED",
    MISSION_CREATE: "MISSION_CREATE",
    MISSION_UPDATE: "MISSION_UPDATE",
    MISSION_ARCHIVE: "MISSION_ARCHIVE",
    NICKNAME_CHANGE: 'NICKNAME_CHANGE',
    ACCOUNT_UPDATE_ATTEMPT: 'ACCOUNT_UPDATE_ATTEMPT',
    LEG_CREATE: "LEG_CREATE",
    LEG_UPDATE: "LEG_UPDATE",
    LEG_DELETE: "LEG_DELETE",
    ORG_CREATE: "ORG_CREATE",
    ORG_UPDATE: "ORG_UPDATE",
    ORG_ARCHIVE: "ORG_ARCHIVE",
    OPERATION_UPDATE: "OPERATION_UPDATE",
    VALIDATION_ERROR: "VALIDATION_ERROR",
    CHECK_IN: "CHECK_IN",
    MERGE_ACCOUNT: "MERGE_ACCOUNT"
};

