//v0.1
//simplified version for display in react admin as inner record view
//to unify with AppDeckGl (params) .. but later..

//extended version of simplified map, but with live data


import React, {Component} from "react";
import { findDOMNode } from 'react-dom';

import 'mapbox-gl/dist/mapbox-gl.css';

import PubSub from 'pubsub-js';
import sizeMe from 'react-sizeme';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import DeckGLOverlay from '../../Components/DeckGLOverlay';

import { processRedraws as processTestRedraws, getTestIco, getTestPath, getTestPoly, getTestPoints, getTestCursor} from "../../glSetups/oldCommon/testDeckInits";

import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'

import {defaultViewportData, mapboxOpts, myAppDefaults} from "../../ConfigurableOpts";

import {
    InspectorWndWrapper,
} from "../../glSetups/oldCommon/_CommonStuff";

import {RndInspectorWnd} from "../../old2review/InspectorWindow"


import {MyMenu} from "../../Components/_MyMenus";
import {clearAllEditablePrimitives} from "../../glSetups/deckDraw/deckDrawStoreBridge";

import MySocketIoV1Client from '../../../LogicV1Redux/oldLogic/MySocketIoV1Client'
import AdsbSources from "../../../LogicV1Redux/oldLogic/RtdmSources";

import {
    ICON_MAPPING,
    LIGHT_SETTINGS,
    invalidateAlerts,
    invalidateZones,

} from "../../glSetups/oldCommon/deckInits";

import {LiveMapStateConstructor, initializeLayersObject} from "../../glSetups/newCommon/mapStateConstructors";
import {FullMapLayersRedraw} from "../../glSetups/newCommon/mapStateLayersRedraws";

import {timerStartRq, timerStopRq} from "../../../LogicV1Redux/actions/TickActions";

import "../../simplified.css";


let map;

const handleClick = (event) => {
    console.log(event.lngLat);
};

const tickInterval = myAppDefaults.tick; //back to main tick as 1 sec

const menuOffset = 30;

class LiveMap extends Component {

    constructor() {
        super();
        this.state = LiveMapStateConstructor();
        //logger.debug('MyCheckInFSMView', this.state);
        //this.onTick = this.onTick.bind(this);

    };

    forceRedraw = ({onTick}) => {

        this.setState(
            FullMapLayersRedraw({
                onTick,
                prevStatePolys: this.state.polys,
                props: this.props,
                alertsDirty: this.alertsDirty,
                zonesDirty: this.zonesDirty
            })
        );

        this.alertsDirty = false;
        this.zonesDirty = false;

        //disabling tick publish
        //if (!onTIck)
        //PubSub.publish(PubSubSignals.TICK, tick);


    };

    // //hackin redraw update for deck gl anims.. NOT OPTIMIZED
    // compareTick = (e) => {
    //     console.log('dataCompare',e.tick, this.state.tick);
    //     console.log('e', e);
    //     if (e.tick === this.state.tick)
    //         return true;
    //     e.tick = this.state.tick;
    //     return false;
    // };


    onMenuChange = menuStore => {
        console.log('Live map menu store change', menuStore);
        //console.log('was',JSON.stringify(this.state.menuStore));

        //dang! previous state needed!
        if (this.lastMenushowZonesV2 !== menuStore.showZonesV2) {
            invalidateZones();
            this.zonesDirty = true;
            this.lastMenushowZonesV2 = menuStore.showZonesV2;

        }
        if (this.lastMenuShowAlerts !== menuStore.showAlerts) {
            invalidateAlerts();
            this.alertsDirty = true;
            this.lastMenuShowAlerts = menuStore.showAlerts;
        }

        //adsb sources is COMBINED -> to split?
        if (this.lastMenuAdsbSources !== menuStore.adsbSources) {
            if (menuStore.adsbSources && !AdsbSources.isOpened()) {
                //AdsbSources.open({pollIntervalInSec: myAppDefaults.adsbSourcesPollInSec});
            }
            else if (AdsbSources.isOpened()) {
                //AdsbSources.close();
            }
            this.lastMenuAdsbSources = menuStore.adsbSources;
        }

        this.setState({menuStore});
    };

    componentDidMount() {


        //hmm
        console.error('fixme inits/disabes alerts/checkins/socket', this.props);

        console.warn('fixme! running in gov now (next show)');

        //MySocketIOClient.init(APP_MODES.OPERATOR);


        //fixme to redux
        // this.pubsubZonesRulesChange = PubSub.subscribe(PubSubSignals.ON_ZONES_RULES_CHANGE, (msg, data) => {
        //     invalidateZones();
        //     this.zonesDirty = true;
        // });

        console.warn('fixme authheader!!');

        //fixme authheader from state here?
        //AlertsListRedux.requestAlerts();
        //requestCheckInsV1();

        console.warn('fixme request zones delay (not onmount)!!');

        //ZonesListV2.requestZonesV2();

        const menuStore = {...this.state.measure, ...this.props.menuStore}; // hmm no measure now
        this.onMenuChange(menuStore);

        this.lastZonesPolys = [];
        this.lastAlertsPolys =[];

        //very wrong place :)
        if (this.props.mapUxConfig.uiTickEnabled) {
            this.props.timerStartRq({interval:tickInterval})
        }

        console.error('fix unmounting!!! (liveadsb/timer)')
    }

    componentWillUnmount() {
        this.props.timerStopRq();

        MySocketIoV1Client.close();

        PubSub.unsubscribe(this.pubsubZonesRulesChange); //hmm
        //PubSub.unsubscribe(this.pubsubAlerts);

        clearAllEditablePrimitives();

        //FIXME KILLING TIMERS WITH MENU NO LONGER WORKS

        this.onMenuChange({liveADSB:false, tickUpdate:false})
    };

    render() {
        // HACK: Wait for setting relative to parent element.
        //if (!this.state.isMounted) return <div />;

        //console.log('LiveMap state' ,this.state);
        //console.log('LiveMap props' ,this.props);

        //console.error('3d mode removed for 1.1, to fix and review')
        //see hardcoded use3d=false in deck.gl
        //return null;

        //TODO optimize... later .. hmm

        //err ..
        const {icos, points, paths, cursor, polys, texts} = this.state;

        //const {viewport} = this.state;
        const {menuComponent, menuStore} = this.props;

        const showDrawToolbar = false; //hmm
        //viewport.height -= menuOffset;

        ///hmm render block should be combined/moved ...
        return (
            <div className="map-window">

                <DeckGLOverlay
                    cursor={cursor}
                    icos={icos}
                    polys={polys}
                    points={points}
                    paths={paths}
                    texts = {texts}
                />

                <MyMenu menuComponent={menuComponent}
                        menuStore={menuStore}
                        onMenuChange={this.onMenuChange}

                />

                <div className='app-playground' style={{top: menuOffset}}>
                    {/* rnd hack conflicts with text inputs*/}
                    {/*{menuStore.missionPlanner ? <MissionPlanerWrapper /> : null}*/}
                    {/*{menuStore.zoneEditor ? <ZoneV2EditorWrapper /> : null}*/}
                    {/*{menuStore.zonesViewerWnd ? <ZonesV2ViewerWrapper /> : null}*/}
                    {menuStore.inspectorWnd ? <RndInspectorWnd /> : null}

                </div>
                {/*{showDrawToolbar ? <RndDrawToolbar /> : null}*/}
            </div>
        );
    }
}

//export default LiveMap;


const mapStateToProps = state => {
    //console.log('liveMap, mapStateToProps', state);

    return {
        //isLoading: state.admin.loading > 0, //fix rerenders on props change
        zonesV1: state.rtMapZonesV1, //just for compare with rt-map old setup
        alertsV1 : state.alertsV1,
        checkinsV1 : state.checkinsV1,
        inspectData: state.inspectData,
        lprHelipadsV1 : state.lprHelipadsV1,
        mapUxConfig : state.mapUxConfig,
        tick: state.tick,
        zonesV2 : state.zonesV2, //do not use before format setup is finished. enable just for tests...
    }
};

const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            timerStartRq, timerStopRq
        },
    )
);

export default enhance(LiveMap);
