import React from 'react';
import { Filter, Responsive, SimpleList, RadioButtonGroupInput, NumberInput, SelectArrayInput, Edit, Create, Datagrid, ReferenceField, TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput , SimpleShowLayout, Show, ShowButton, FunctionField, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {dateTimeFormatter} from "../../utils/momentHelpers";

import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import {MyRecordTitle, MyResourceListTitle} from "../MyTitle";
import Typography from "@material-ui/core/Typography";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import MyTextField from "../MyTextField";
import {canUpdate} from "../../logic/MyRestConfig";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import {SaveButton, SelectField} from "ra-ui-materialui";
import {SaveOnlyToolbar} from "../MyBasicEditToolbar";
import {TooltipProxy as Tooltip} from "../TooltipProxy";
import {
    droneCategories,
    droneClasses,
    droneDrives,
    droneFailsafes,
    droneFlightModes, droneLights,
    droneTypes,
    failSafes2Array
} from "./Drones";
import MyIlikeSearch from "./MyIlikeSearch";
/*
  {
    "model": "string",
    "make": "string",
    "type": "string",
    "class": "string",
    "mtom": "string",
    "ft": 0,
    "vmax": 0,
    "fs": "string",
    "etc": {},
    "id": 0
  }
*/


export const DroneModelFullName = ({ record }) => {
    return <span><Typography>{record ? `${record.make} ${record.model}` : ''}</Typography></span>;
};

export const DroneModelsList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureDroneModelsList {...props}/>
    </MyTransitionDecorator>
);

const DroneModelsFilter = (props) => {
    if (!props.permissions) return null;

    return (
        <Filter {...props}>
            <MyIlikeSearch source="make"
                           label='resources.droneModels.fields.make'
            />
            <MyIlikeSearch source="model"
                           label='resources.droneModels.fields.model'
            />
            <SelectInput source="class"
                         label='resources.droneModels.fields.class'
                         choices={droneClasses}
                         allowEmpty={false}
            />
            <SelectInput source="type"
                         label='resources.droneModels.fields.type'
                         choices={droneTypes}
                         allowEmpty={false}
            />
        </Filter>
    )};


const PureDroneModelsList = ({permissions, ...props}) => (
    <List {...props}
          bulkActions={false}
          actions={<BasicListActions />}
          title={<MyResourceListTitle resource={'droneModels'}/>}
          pagination={<MyPagination/>}
          filters={<DroneModelsFilter permissions={permissions}/>}
    >

        <Datagrid>
            {/*atc columns reviewed in #96/98 issues*/}

            {/*<DroneModelFullName />*/}
            {/*<TextField source="id" />*/}
            <TextField source="make" />
            <TextField source="model" />
            <TextField source="type" />
            <TextField source="class" />
            {
                canUpdate(props.resource, permissions) ? <EditButton/> : <ShowButton/>
            }

        </Datagrid>
    </List>
);

//no etc support now
//fixme validation
export const DroneModelsCreate = ({permissions, ...props}) => (
    <Create {...props}
        /* undoable={false} -  no undo in create anyway */
            title={'resources.droneModels.create'}
    >
        <SimpleForm toolbar={
            <SaveOnlyToolbar/>
        }>
            {droneModelEditFields}
        </SimpleForm>
    </Create>
);

export const DroneModelsEdit = ({permissions, ...props}) => (
    <Edit {...props}
          undoable={false}
          actions={<BasicEditActions />}
          title={<MyRecordTitle resource={'droneModels'} recordName={'model'}/>}
    >
        <SimpleForm
            toolbar={
                <SaveOnlyToolbar/>
            }
        >
            <DisabledInput source="id" />
            {droneModelEditFields}
        </SimpleForm>
    </Edit>
);

export const DroneModelsShow = ({permissions, ...props}) => (
    <Show {...props}
      actions={<BasicShowActions/>}
          title={<MyRecordTitle resource={'droneModels'} recordName={'model'}/>}
    >
        <SimpleShowLayout>

            <TextField source="model" />
            <TextField source="make" />
            <SelectField source="type"
                                   label={'resources.droneModels.fields.type'}
                                   choices={droneTypes}
            />
            <SelectField source="class"
                                   label={'resources.droneModels.fields.class'}
                                   choices={droneClasses}
            />
            <TextField source="ft" />
            <TextField source="vmax"
                       label={'resources.droneModels.fields.vmaxH'}/>
            <MyTextField label={'resources.droneModels.fields.vmaxV'}
                         source="etc.vmaxV" />
            <SelectField source="fs"
                              label={'resources.droneModels.fields.fs'}
                              choices={droneFailsafes}
            />
            <SelectField source="etc.category"
                                   label={'resources.droneModels.fields.category'}
                                   choices={droneCategories}
            />
            <MyTextField label={'resources.droneModels.fields.mtom'}
                         source="mtom" />

            <MyTextField label={'resources.droneModels.fields.range'}
                         source="etc.range" />
            <MyTextField label={'resources.droneModels.fields.rangeFollowMe'}
                         source="etc.rangeFollowMe" />
            <MyTextField label={'resources.droneModels.fields.maxTakeOffHeight'}
                         source="etc.maxTakeOffHeight" />
            <SelectField source="etc.drive"
                                   label={'resources.droneModels.fields.drive'}
                                   choices={droneDrives}
            />
            <SelectField source="etc.flightModes"
                              label={'resources.droneModels.fields.flightModes'}
                              choices={droneFlightModes}
            />
            <MyTextField label={'resources.droneModels.fields.camera'}
                         source="etc.camera" />
            <SelectField source="etc.lights"
                              label={'resources.droneModels.fields.lights'}
                              choices={droneLights}
            />
            <MyTextField label={'resources.droneModels.fields.altitudeSensor'}
                         source="etc.altitudeSensor" />
            <MyTextField label={'resources.droneModels.fields.uSpaceIntf'}
                         source="etc.uSpaceIntf" />
            <MyTextField label={'resources.droneModels.fields.safeEquipment'}
                         source="etc.safeEquipment" />

        </SimpleShowLayout>
    </Show>
);

{/*TODO temporary - change to mtoff*/
}
{/*TODO temporary - change to cat*/
}
{/*TODO temporary - change to drive*/
}
const droneModelEditFields = [

    <TextInput source="model"
               label={'resources.droneModels.fields.model'}
               validate={[required(), minLength(2), maxLength(32)]}
               key="model"
    />,
    <TextInput source="make"
               label={'resources.droneModels.fields.make'}
               validate={[required(), minLength(2), maxLength(16)]}
               key="make"
    />,
    <RadioButtonGroupInput source="type"
                           label={'resources.droneModels.fields.type'}
                           choices={droneTypes}
                           validate={[required()]}
                           key="type"
    />,
    <RadioButtonGroupInput source="class"
                           label={'resources.droneModels.fields.class'}
                           choices={droneClasses}
                           key="class"
    />,
    <TextInput source="mtom"
               label={'resources.droneModels.fields.mtom'}
               validate={[required(), number(), minValue(1), minLength(2)]}
               key="mtom"
    />,
    <NumberInput source="ft"
                 label={'resources.droneModels.fields.ft'}
                 validate={[required(), number(), minValue(1)]}
                 key="ft"
    />,
    <NumberInput source="vmax"
                 label={'resources.droneModels.fields.vmaxH'}
                 validate={[required(), number(), minValue(1)]}
                 key="vmax"
    />,
    <SelectArrayInput source="fs"
                      label={'resources.droneModels.fields.fs'}
                      choices={droneFailsafes}
                      format={failSafes2Array}
                      key="fs"
    />,
    <RadioButtonGroupInput source="etc.category"
                           label={'resources.droneModels.fields.category'}
                           choices={droneCategories}
                           key="cat"
    />,

    <RadioButtonGroupInput source="etc.drive"
                           label={'resources.droneModels.fields.drive'}
                           choices={droneDrives}
                           key="drive"
    />,
    <NumberInput source="etc.maxTakeOffHeight"
                 label={'resources.droneModels.fields.maxTakeOffHeight'}
                 validate={[number(), minValue(10)]}
                 key="mtoff"
    />,
    <NumberInput source="etc.range"
                 label={'resources.droneModels.fields.range'}
                 validate={[number(), minValue(100)]}
                 key="range"
    />,
    <NumberInput source="etc.rangeFollowMe"
                 label={'resources.droneModels.fields.rangeFollowMe'}
                 validate={[number(), minValue(10)]}
                 key="rangeFollowMe"
    />,
    <NumberInput source="etc.vmaxV"
                 label={'resources.droneModels.fields.vmaxV'}
                 validate={[number(), minValue(1)]}
                 key="vmaxV"
    />,
    <SelectArrayInput source="etc.flightModes"
                      label={'resources.droneModels.fields.flightModes'}
                      choices={droneFlightModes}
                      key="flightModes"
    />,
    <TextInput source="etc.camera"
               label={'resources.droneModels.fields.camera'}
               key="camera"
    />,
    <SelectArrayInput source="etc.lights"
                      label={'resources.droneModels.fields.lights'}
                      choices={droneLights}
                      key="lights"
    />,
    <TextInput source="etc.bms"
               label={'resources.droneModels.fields.bms'}
               key="bms"
    />,
    <TextInput source="etc.altitudeSensor"
               label={'resources.droneModels.fields.altitudeSensor'}
               key="altitudeSensor"
    />,
    <TextInput source="etc.uSpaceIntf"
               label={'resources.droneModels.fields.uSpaceIntf'}
               key="uSpaceIntf"
    />,
    <TextInput source="etc.safeEquipment"
               label={'resources.droneModels.fields.safeEquipment'}
               key="safeEquipment"
    />,
];
