import React from 'react';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import {withStyles} from '@material-ui/core'

import {connect} from 'react-redux'

import {translate, userLogout as userLogoutAction, showNotification} from 'ra-core';
import compose from 'recompose/compose';

//import {SelfOperatorsEditForm} from './resources/Operators'
//import {SelfGovsEditForm} from './auth/MyAccountForms'

//import {SelfOperatorsEditForm} from './auth/MyAccountForms'

//import {OperatorEdit} from './components/resources/Operators'
//import { //getColorsFromTheme,
    //renderInput,
//     isSuperAdmin
// } from '../utils/tempCommon';
// import {CertificatesList, CertificatesShow} from "./resources/Certificates";
// import {canRead, canUpdate} from "../MyRestConfig";
// import {MissionsEdit, MissionsList, MissionsShow} from "./resources/Missions";
// import {Resource} from 'react-admin'
// import FlightTakeoff from "@material-ui/core/SvgIcon/SvgIcon";
import DataProvider from "../MyDataProvider";

import {
    RejectedMissionsCard,
    AcceptedMissionsCard,
    PendingMissionsCard,
    UnknownMissionsCard,
    AcceptedApplicationsCard,
    RejectedApplicationsCard,
    PendingApplicationsCard,
    UnknownApplicationsCard,
    PendingAgainApplicationsCard,
    RejectedMeetingsApplicationsCard,
    AcceptedMeetingsApplicationsCard,
    RequestedMeetingsApplicationsCard, SelectedMeetingsApplicationsCard, CreatedMissionsCard
} from "./dashboard/DashboardCards";
import {Title} from "ra-ui-materialui";
import TranslatableTypography from "./TranslatableTypography";
import {fetchStart, fetchEnd} from "react-admin";
import {bindActionCreators} from "redux";
import {loadingStart, loadingEnd, setTitle} from "../logic/ui/MyUiActions";
import {MissionV2Statuses} from '../../LogicV1Redux/constants/MissionsV2Enums'
import moment from "moment";
import {isCaaEditor, isOperator, isGov, isSuperAdmin, isDraiEditor, isLauEditor} from "../logic/MyRestConfig";
import {AppTitle} from "../../LogicV1Redux/constants/AppTitleEnums";
import {mapStatusToEntryGroupName} from "../utils/Dashboard/groups";
import {has, get, defaultTo} from "lodash";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../LogicV1Redux/constants/PubSubSignals";
import {CaaApplicationStatuses} from "../../LogicV1Redux/constants/CaaApplicationEnums";
import {isAtcMaster} from "../logic/MyRestConfig";

import {RESOURCES} from '../logic/MyRestConfig'
import {API_VERBS} from "../MyApiVerbs";
import axios from "axios";
import Configs, {FEATURES_V1_2_ENUMS, urls} from "../../Configs";
import {fixUserId} from '../../LogicV1Redux/oldLogic/LoopbackUtils'
import {getAuthHeaderV2} from "../../LogicV1Redux/oldLogic/AuthConverters";
import {isCaaApplMeetingHoldStatus} from "../utils/applicationsHelpers";

const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);

// export default () => (
//     <Card>
//         <CardHeader title="Welcome to the administration" />
//         <CardContent>Lorem ipsum sic dolor amet...</CardContent>
//     </Card>
// );

//TODO style sanitize
const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "stretch",
        paddingBottom: "2rem",

        [theme.breakpoints.up('lg')]: {
            justifyContent: "space-between",
        },

        [theme.breakpoints.up('xl')]: {
            justifyContent: "flex-start",
        },
    }
});

const LIMIT_SHORT = 5 + 1; // 5 +1 -> counts not passed now. hacking more button
const LIMIT_LONG = 10 + 1;  // 10 +1 -> counts not passed now. hacking more button

//hmm fixme archived filter .. on frontend?
function _filteredDataProviderPromise(resource, filter = {}, limit = LIMIT_LONG) {
    const dataProvider = DataProvider.getDataProvider();

    //cleaning up. if null
    if (filter.unitUids === null) {
        delete filter.unitUids
    }

    const pagination = {perPage: limit};

    //!temp fixing issue droneadmin#131
    // removing count in list
    const sort = {field: "modified", order: "DESC"};
    return dataProvider(API_VERBS.GET_LIST, resource, {pagination, sort, filter, skipCount:true})
        .then((ret) => {
            //hacking continued
            if (isNaN(ret.total) ) {
                ret.total = -1;
            }
            return ret;
        });

}

function _getCountDataProviderPromise(resource, filter) {
    //!temp fixing issue droneadmin#131
    return Promise.resolve({data:[],total:-1});

    // const dataProvider = DataProvider.getDataProvider();
    // return dataProvider(API_VERBS.COUNT, resource, {filter})
}

//rewritten again to support custom view endpoint
function _processMissions4Dashboard(response, myAppExtras) {
    const isGov = myAppExtras.appMode === 'GOV'; //fixme!
    const ret = {data:[], total:response.total};

    for (const mission of response.data) {
        const etc = mission.etc || {}
        const observers = etc.observers || []
        const isKSID = observers.some((o) => o.name.startsWith("POL") && o.name.length === 16)
        const is112 = !!etc.is112

        const extras = isGov ? {
            operatorName : mission.operatorName,
            zoneUids: (mission.zoneUids)? mission.zoneUids.split(';'):[],
            zoneNames: (mission.zoneNames)? mission.zoneNames.split(';'):[],
            zoneTypes: (mission.zoneNames)? mission.zoneTypes.split(';'):[],
            _modified: mission.modified,
        } : {
            type: mission.typ,
            start: mission.start,
            modified: mission.modified,
        }

        const slimMission = {
            uid: mission.uid,
            name: mission.name,
            status: mission.status,
            isKSID,
            is112,
            ...extras
        };
        ret.data.push(slimMission);
    }
    //console.log('====ret M', ret);
    return ret;
}

function _processApplication4Dashboard(response) {
    const ret = {data:[], total:response.total};

    for (const application of response.data) {
        const {uid, name, registry, status, operator, etc, modified} = application;
        const govMsgs = defaultTo(get(application, 'govMsgs'), []);

        let meeting;
        if(isCaaApplMeetingHoldStatus(status)) {
            const meetingRequestMsg = [...govMsgs].reverse().find(msg=>msg.status === CaaApplicationStatuses.HOLD_MEETING_REQUEST);
            meeting = meetingRequestMsg ? meetingRequestMsg.meetings[0] : etc.meeting
        }
        const slimApplication = {
            uid,
            name,
            //add registry key only when present - only for drones
            ...(has(application, 'registry') && {registry}),
            kind: has(application, 'registry') ? 'drones' : 'certs',
            status,
            //OPTIMIZE TESTS

            operator,
            ...(meeting && {meeting}),
            _modified: modified

        };

        ret.data.push(slimApplication);

        //this._splitIntoApplicationsGroups(application, slimApplication, applicationsGroups);
    }

    console.log('====ret A', ret);

    return ret;

}

export const DashboardContext = React.createContext(null);

const TabContainer = (props) => {
    return (
        <Typography component="div" style={{padding: 8 * 3, backgroundColor: "#eeeeee"}}>
            {props.children}
        </Typography>
    );
};

const devTab = (myAuth, mySelf, myAppExtras, translate) => {
    return (
        <div>
            <MuiCardHeader title={translate('myroot.dashboard.title')}/>
            <MuiCardContent>user uuid: {myAuth.userId}</MuiCardContent>
            <MuiCardContent>myAuth token: {myAuth.token}</MuiCardContent>
            <MuiCardContent>myAuth ttl: {myAuth.ttl}</MuiCardContent>
            <pre>
                {JSON.stringify(myAppExtras, null, 2)}
            </pre>
            <pre>
                {JSON.stringify(mySelf, null, 2)}
            </pre>

        </div>
    )
};

class DashboardWithStore extends React.Component {

    constructor(props) {
        console.log('dashboard here', props);
        super(props);
        this.reference = React.createRef();
    }

    state = {
        value: 0,
        missions: null,
        applications: null,
    };


    componentWillMount() {
        const roles = this.props.mySelf.roles;
        this._setTitle(roles);
    }

    _setTitle(roles) {
        const {setTitle} = this.props;
        let title;
        //TODO optimize - move somewhere after login instead of setting title each time entering dashboard
        //order matters!!! CAA and DRAI is also GOV...
        if (isSuperAdmin(roles)) title = AppTitle.SUPERADMIN;
        else if (isCaaEditor(roles)) title = AppTitle.CAA;
        else if (isLauEditor(roles)) title = AppTitle.LAU;
        else if (isGov(roles)) title = AppTitle.ASM;
        else if (isOperator(roles)) title = AppTitle.OPERATOR;
        else title = AppTitle.CORE;

        document.title = title;
        setTitle(title);
    }

    componentDidMount() {

        //hmm sometimes was not connected?
        //moved to dashboard.

        const roles = this.props.mySelf.roles;
        if(isCaaEditor(roles)) {
            const p = this._fetchApplications();
            ///FIXME - was fast test, blurring sucks
            this.pubsubOnDroneDirty = PubSub.subscribe(PubSubSignals.DRONE_STATUS_CHANGE, (msg, data) => {
                this._fetchApplications({blur: false});
                this.setState({droneDirty:data});
            });
            this.pubsubOnCertDirty = PubSub.subscribe(PubSubSignals.CERT_STATUS_CHANGE, (msg, data) => {
                this._fetchApplications({blur: false});
                this.setState({certDirty:data});
            });
        }
        else if(isOperator(roles)) {
            this.pubsubOnMissionDirty = PubSub.subscribe(PubSubSignals.MISSION_STATUS_CHANGE, (msg, data) => {
                //console.log('=======on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
                this._fetchMissions({blur: false});
                this.setState({dirtyMission:data});
            });
            this.pubsubOnDroneDirty = PubSub.subscribe(PubSubSignals.DRONE_STATUS_CHANGE, (msg, data) => {
                this._fetchApplications({blur: false});
                this.setState({droneDirty:data});
            });
            this.pubsubOnCertDirty = PubSub.subscribe(PubSubSignals.CERT_STATUS_CHANGE, (msg, data) => {
                this._fetchApplications({blur: false});
                this.setState({certDirty:data});
            });

            const p = this._fetchMissions();
            const g = this._fetchApplications();
        }
        else {
            this.pubsubOnMissionDirty = PubSub.subscribe(PubSubSignals.MISSION_STATUS_CHANGE, (msg, data) => {
                //console.log('=======on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
                this._fetchMissions({blur: false});
                this.setState({dirtyMission:data});
            });

            const p = this._fetchMissions();
        }

    }

    componentWillUnmount() {

        PubSub.unsubscribe(this.pubsubOnDroneDirty);
        PubSub.unsubscribe(this.pubsubOnCertDirty);
        PubSub.unsubscribe(this.pubsubOnMissionDirty);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const roles = this.props.mySelf.roles;

        if(prevProps.viewVersion !== this.props.viewVersion) {
            if(isCaaEditor(roles)) {
                const p = this._fetchApplications();
            }
            else if(isOperator(roles)) {
                const p = this._fetchMissions();
                const g = this._fetchApplications();
            }
            else {
                const p = this._fetchMissions();
            }
        }

    }


    _splitIntoApplicationsGroups(application, slimApplication, applicationsInCategories) {
        //TODO correct when model consistenecy proven to application.status
        const group = mapStatusToEntryGroupName(slimApplication.status);
        applicationsInCategories[group].data.push(slimApplication);
    }

    //was: fixme support for finally in Edge, Firefox
    //see https://gidrone.azure.pansa.pl/playground/ra2-playground/issues/216
    async _fetchMissions(options = {}) {
        const {blur = true} = options;
        const {fetchStart, fetchEnd, loadingStart, loadingEnd, myAppExtras, mySelf, myAuth, showNotification} = this.props;

        const roles = mySelf.roles;

        const isOp = isOperator(roles);
        const isCaa = isCaaEditor(roles);
        const _isAtcMaster = isAtcMaster(mySelf.roles);
        const isSuperadmin = isSuperAdmin(roles);

        if (isCaa) {
            return;
        }

        fetchStart();
        blur && loadingStart('resources.missions.loading');

        const missionsGroups = {
            pending: {data:[],total:-1},
            accepted: {data:[],total:-1},
            rejected: {data:[],total:-1},
            // unknown: {data:[],total:-1},
            created: {data:[],total:-1},
        };
        let count = -1;

        console.log('myself in _fetchMissions', mySelf);

        let run;

        //TODO: review and whitelist requests fields here to reduce bandwith!
        const opRequestedData = {
            "mission":[
                {"fields":{"attn":false},"where":{"status":"planned"},"order":["modified DESC"],"limit":LIMIT_LONG},
                {"fields":{"attn":false},"where":{"status":"accepted"},"order":["modified DESC"],"limit":LIMIT_LONG},
                {"fields":{"attn":false},"where":{"status":"rejected"},"order":["modified DESC"],"limit":LIMIT_LONG},
                {"fields":{"attn":false},"where":{"status":"created"},"order":["modified DESC"],"limit":LIMIT_LONG}],
        };

        const govRequestedData = {
            "missionCustomView":[
                {"where":{"status":"planned"},"order":["modified DESC"],"limit":LIMIT_LONG},
                {"where":{"status":"accepted"},"order":["modified DESC"],"limit":LIMIT_LONG},
                {"where":{"status":"rejected"},"order":["modified DESC"],"limit":LIMIT_LONG}
                ]
        };

        //gov only (with unit) but not atc master and superadmin..
        if (!isOp && !(_isAtcMaster || isSuperadmin) && mySelf.units && mySelf.units[0]) {
            //only one unit due to loopback text search filter, not cool btw.
            const unitFilter = {unitUids:{'like': `%${mySelf.units[0].uid}%`}};
            for (let i = 0; i < govRequestedData.missionCustomView.length; i++) {
                govRequestedData.missionCustomView[i].where.unitUids = unitFilter.unitUids;
            }

        }

        //low level axios call, as this is NOT a RA2 resource, see droneadmin merge request !461
        //later can be splitted into drones and certs to better manage realtime refresh request.
        run = axios(fixUserId(mySelf.uid, (isOp)?urls.reqOperatorDashboardUrl:urls.reqGovDashboardUrl), {
            method: 'post',
            headers : getAuthHeaderV2(myAuth),
            data : (isOp)?opRequestedData:govRequestedData

        }).then((ret) => {
            console.log('ret from dashboard _fetchMissions', ret);

            //faking old combined promises response structure
            const mission = (isOp)?ret.data.mission:ret.data.missionCustomView;

            const oldResolveFormat = [
                {data:mission[0]}, {data:mission[1]}, {data:mission[2]},
            ];

            oldResolveFormat[3] = {data:(mission[3]) ? mission[3]:[]};
            oldResolveFormat[4] = {data:{count:-1}}; //total count not used now

            //aargh.. hacking not passed now counts
            for (let i=0; i<4; i++) {
                oldResolveFormat[i].total = oldResolveFormat[i].data.length;
                if (oldResolveFormat[i].total === LIMIT_LONG) {
                    oldResolveFormat[i].data.pop();
                }
            }

            return Promise.resolve(oldResolveFormat);

        }).catch(e => {
            showNotification('myroot.dashboard.fetchError', 'warning');
            return Promise.reject(e)
        });



        run.then(res => {
            console.log('===res', res);
            // let [pending, accepted, rejected, created, unknown, total] = res;
            let [pending, accepted, rejected, created, total] = res;
            missionsGroups.pending = _processMissions4Dashboard(pending, myAppExtras);
            missionsGroups.accepted = _processMissions4Dashboard(accepted, myAppExtras);
            missionsGroups.rejected = _processMissions4Dashboard(rejected, myAppExtras);
            // missionsGroups.unknown = _processMissions4Dashboard(unknown, myAppExtras);
            missionsGroups.created = _processMissions4Dashboard(created, myAppExtras);

            count = total.data.count;
            console.log('====test missionsGroups A', missionsGroups);
        }).catch(e => {
            //err, should create some ux notification? or already called in dataProvider?

            console.error(e);

        }).finally(() => {
            console.log('====test missionsGroups B', missionsGroups);

            this.setState({missions: {...missionsGroups, count}});

            fetchEnd();
            blur && loadingEnd();
        });

    }

    async _fetchApplications(options = {}) {
        const {blur = true} = options;
        const {fetchStart, fetchEnd, loadingStart, loadingEnd, mySelf, myAuth, showNotification} = this.props;

        console.log('mySelf', mySelf);

        const roles = mySelf.roles;
        const isCaa = isCaaEditor(roles);
        const isOp = isOperator(roles);
        const isSuperadmin = isSuperAdmin(roles);

        if (!(isCaa || isOp || isSuperadmin) ) {
            return;
        }

        fetchStart();
        blur && loadingStart('resources.applications.loading');

        //see mapStatusToEntryGroupName

        const applicationsGroups = {
            pending: {data:[],total:-1},
            pendingAgain: {data:[],total:-1},
            accepted: {data:[],total:-1},
            rejected: {data:[],total:-1},
            unknown: {data:[],total:-1},
            meetingsRequest: {data:[],total:-1},
            meetingsAccepted: {data:[],total:-1},
            meetingsRejected: {data:[],total:-1},
        };

        let count = -1;

        //TODO: review and whitelist requests fields here to reduce bandwith!
        const opRequestedData = {
            "drone":[
                {"fields":{"attn":false},"where":{"status":"verify"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"verify again"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"accepted"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":{"inq":["rejected","modified"]}},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"meeting request"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"meeting accepted"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"meeting rejected"},"order":["modified DESC"],"limit":LIMIT_SHORT}],
            "cert":[
                {"fields":{"attn":false},"where":{"status":"verify"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"verify again"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"accepted"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":{"inq":["rejected","modified"]}},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"meeting request"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"meeting accepted"},"order":["modified DESC"],"limit":LIMIT_SHORT},
                {"fields":{"attn":false},"where":{"status":"meeting rejected"},"order":["modified DESC"],"limit":LIMIT_SHORT}]
        };

        const govRequestedData = {
        "drone":[
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":"verify"},"order":["modified DESC"],"limit":LIMIT_SHORT},
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":"verify again"},"order":["modified DESC"],"limit":LIMIT_SHORT},
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":"accepted"},"order":["modified DESC"],"limit":LIMIT_SHORT},
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":{"inq":["rejected","modified"]}},"order":["modified DESC"],"limit":LIMIT_SHORT},
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":"meeting request"},"order":["modified DESC"],"limit":LIMIT_SHORT},
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":"meeting accepted"},"order":["modified DESC"],"limit":LIMIT_SHORT},
            {"include":[{"relation":"operator"}],"fields":{"attn":false},"where":{"status":"meeting rejected"},"order":["modified DESC"],"limit":LIMIT_SHORT},
            ]
        };

        //low level axios call, as this is NOT a RA2 resource, see droneadmin merge request !461
        //later can be splitted into drones and certs to better manage realtime refresh request.
        const run = axios(fixUserId(mySelf.uid, (isOp)?urls.reqOperatorDashboardUrl:urls.reqGovDashboardUrl), {
                method: 'post',
                headers : getAuthHeaderV2(myAuth),
                data : (isOp)?opRequestedData:govRequestedData
            }).then((ret) => {
                console.log('ret from operator dashboard _fetchApplications', ret);

                //faking old combined promises response structure
                //TODO change request to get filtered combined statuses, and fix response like mission to fake subtotals.
                const {drone} =  ret.data;

                return Promise.resolve([
                    {data:drone[0]}, {data:drone[1]}, {data:drone[2]}, {data:drone[3]}, {data:drone[4]}, {data:drone[5]}, {data:drone[6]},
                    {data:{count:-1}}, //total count not used now
                    {data:{count:-1}}, //total count not used now
                ]);


            }).catch(e => {
                showNotification('myroot.dashboard.fetchError', 'warning');
                return Promise.reject(e)
            });

        run.then(res => {
            console.log('===res', res);
            let [
                pendingDrones, pendAgainDrones, acceptedDrones, rejectedDrones, meetReqDrones, meetAccDrones, meetRejDrones, totalDrones
            ] = res;

            applicationsGroups.pending = _processApplication4Dashboard(
                {data:[...pendingDrones.data],
                    total:pendingDrones.total });
            applicationsGroups.pendingAgain = _processApplication4Dashboard(
                {data:[...pendAgainDrones.data],
                    total:pendAgainDrones.total });
            applicationsGroups.accepted = _processApplication4Dashboard(
                {data:[...acceptedDrones.data],
                    total:acceptedDrones.total });
            applicationsGroups.rejected = _processApplication4Dashboard(
                {data:[...rejectedDrones.data],
                    total:rejectedDrones.total });
            applicationsGroups.meetingsRequest = _processApplication4Dashboard(
                {data:[...meetReqDrones.data],
                    total:meetReqDrones.total });

            applicationsGroups.meetingsAccepted = _processApplication4Dashboard(
                {data:[...meetAccDrones.data],
                    total:meetAccDrones.total});

            applicationsGroups.meetingsRejected = _processApplication4Dashboard(
                {data:[...meetRejDrones.data],
                    total:meetRejDrones.total});


            //console.error('total sample', totalCerts);

            count = totalDrones.data.count;
            console.log('====test applicationsGroups A', applicationsGroups);
        }).catch(e => {
            //err, should create some ux notification? or already called in dataProvider?

            console.error(e);

        }).finally(() => {
            console.log('====test applicationsGroups B', applicationsGroups);

            this.setState({applications: {...applicationsGroups, count}});

            fetchEnd();
            blur && loadingEnd();
        });

        /* very old approach getting FULL list. currently works (with some minor issues), but on long lists will stuck

        const dronesPromise = await dataProvider("GET_LIST", dronesResource, {pagination: {perPage: null}, sort: {field: "modified", order: "DESC"}})
            .catch((e) => {
                console.error(e);
                fetchEnd();
                blur && loadingEnd();
            });

        const certsPromise = await dataProvider("GET_LIST", certsResource, {pagination: {perPage: null}, sort: {field: "modified", order: "DESC"}})
            .catch((e) => {
                console.error(e);
                fetchEnd();
                blur && loadingEnd();
            });

        const dronesCount = dronesPromise.data.length;
        const certsCount = certsPromise.data.length;

        const applications = {
            data: [...dronesPromise.data, ...certsPromise.data],
        };

        for (const application of applications.data) {
            const slimApplication = {
                uid: application.uid,
                name: application.name,
                //add registry key only when present - only for drones
                ...(has(application, 'registry') && {registry: application.registry}),
                kind: has(application, 'registry') ? 'drones' : 'certs',
                status: application.status,
                operator: application.operator,
                ...(has(application, 'etc.meeting') && {meeting: application.etc.meeting}),
            };
            this._splitIntoApplicationsGroups(application, slimApplication, applicationsGroups);
        }

        this.setState({applications: {...applicationsGroups, count: dronesCount + certsCount}});
        fetchEnd();
        blur && loadingEnd();
        */
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    _getOperatorDashboard = () => {
        const {translate, mySelf} = this.props;
        const {missions, applications} = this.state;
        const cardProps = {translate, missions, applications, mySelf};

        return (
            <>
                {this._getDefaultDashboard()}
                {/*<UnknownMissionsCard uniqueName={'missionsUnknown'} {...cardProps}/>*/}
                <CreatedMissionsCard uniqueName={'missionsCreated'} {...cardProps}/>
                <SelectedMeetingsApplicationsCard uniqueName={'applMeetingsSelected'} {...cardProps} />
            </>
        )
    };

    _getDefaultDashboard = () => {
        const {translate, mySelf} = this.props;
        const {missions, applications} = this.state;
        const cardProps = {translate, missions, applications, mySelf};

        return (
            <>
                <PendingMissionsCard uniqueName={'missionsPending'} {...cardProps}/>
                <AcceptedMissionsCard uniqueName={'missionsAccepted'} {...cardProps}/>
                <RejectedMissionsCard uniqueName={'missionsRejected'} {...cardProps}/>
            </>
        )
    };

    _getCaaDashboard = () => {
        const {translate, mySelf} = this.props;
        const {applications} = this.state;
        const cardProps = {translate, applications, mySelf};

        return (
            <>
                <AcceptedApplicationsCard uniqueName={'applAccepted'} {...cardProps}/>
                <PendingApplicationsCard uniqueName={'applPending'} {...cardProps}/>
                <RejectedApplicationsCard uniqueName={'applRejected'} {...cardProps}/>
                <PendingAgainApplicationsCard uniqueName={'applPendingAgain'} {...cardProps}/>
                <RequestedMeetingsApplicationsCard uniqueName={'applMeetingsRequested'} {...cardProps}/>
                <AcceptedMeetingsApplicationsCard uniqueName={'applMeetingsAccepted'} {...cardProps}/>
                <RejectedMeetingsApplicationsCard uniqueName={'applMeetingsRejected'} {...cardProps}/>
            </>
        )
    };

    _getDashboard = () => {
        const roles = this.props.mySelf.roles;
        const isCaa = isCaaEditor(roles);
        const isOp = isOperator(roles);

        if(isCaa) return this._getCaaDashboard();
        else if(isOp) return this._getOperatorDashboard();
        else return this._getDefaultDashboard();
    };


    //TODO move rodo to welcomescreens
    render() {
        const {classes, title, locale} = this.props;
        console.log('dashboard props', this.props);
        console.log('dashboard state', this.state);
        const tempDebug = this.props.mySelf;


        //FIXME, special dashboard needed
        //so this one would be HOC

        return (
            <div className={classes.root} ref={this.reference}>
                <DashboardContext.Provider value={this.reference}>
                    <Title title={title}/>
                    {this._getDashboard()}
                </DashboardContext.Provider>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        myAuth: state.myAuth,
        mySelf: state.mySelf,
        myAppExtras: state.myAppExtras,
        viewVersion: state.admin.ui.viewVersion,
        title: state.myUi.title,
        locale: state.i18n.locale,
    }
};
const mapDispatchToProps = (dispatch, { redirectTo }) => ({
    userLogout: () => dispatch(userLogoutAction(redirectTo)),
    fetchStart: bindActionCreators(fetchStart, dispatch),
    fetchEnd: bindActionCreators(fetchEnd, dispatch),
    loadingStart: bindActionCreators(loadingStart, dispatch),
    loadingEnd: bindActionCreators(loadingEnd, dispatch),
    setTitle: bindActionCreators(setTitle, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch)
});


const enhance = compose(
    translate,
    withStyles(styles),
    //muiThemeable(), // force redraw on theme change
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DashboardWithStore);
