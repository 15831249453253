import MyInputDialog from "../../MyInputDialog";
import React from "react";
import {translate, BooleanInput, SimpleForm} from 'react-admin'
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import {toggleAcmRpaDialog} from "../../../logic/ui/MyUiActions";
import has from "lodash.has";
import {loadAcmRulesCTR, saveAcmRulesRPA} from "../../../../LogicV1Redux/actions/AcmRulesActions";
import {formValueSelector, isDirty, reset, destroy} from 'redux-form';
import {RESOURCES} from "../../../logic/MyRestConfig";
import Typography from "@material-ui/core/Typography";
import {getAcmCountPromise, getDefaultRulesInfo} from "../../../../LogicV1Redux/sagas/AcmRulesSagas";
import {DEFAULT_ACM_RULES_COUNT} from "../../../../LogicV1Redux/constants/AcmRulesConstants";
import TranslatableTypography from "../../TranslatableTypography";
import {InfoText} from "../../InfoText";
import Delay from "react-delay";

const ACM_RPA_INPUT_FORM = 'acm-rpa-form';


class AcmRpaInputDialog extends React.Component {

    static defaultProps = {
        data: {}
    };

    state = {
        canEdit: false,
    };

    _updateEditState() {
        const ctr = this.props.data.etc.master;
        const countPromise = getAcmCountPromise(ctr);

        countPromise.then(
            res =>
                this.setState({
                    canEdit: res.data.count === DEFAULT_ACM_RULES_COUNT
                })
        )
    };

    componentDidMount() {
        this._updateEditState()
    }

    componentWillUnmount() {
        // destroy for removing dirty state
        this.props.destroyForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) this._updateEditState();

        if (prevProps.data.designator !== this.props.data.designator) {
            this.props.resetForm();
        }
    }

    render() {
        const {acmRpaDialog, toggleAcmRpaDialog, saveAcmRulesRpa, translate, data, etcOutput, isDirty, loadAcmRulesCTR} = this.props;
        const {canEdit} = this.state;

        const rpa = data.designator;
        const ctr = data.etc.master;

        return (
            <MyInputDialog title={`resources.${RESOURCES.ACM_RULES}.acmRpaDialogTitle`}
                           label={[`resources.${RESOURCES.ACM_RULES}.acmRpaDialogLabel`, {rpa, ctr}]}
                           confirm={'ra.action.save'}
                           cancel={'ra.action.cancel'}
                           onConfirm={() => {
                               toggleAcmRpaDialog({show: false});
                               saveAcmRulesRpa({etc: etcOutput, uid: data.uid})
                           }}
                           onClose={() => {
                               toggleAcmRpaDialog({show: false});
                           }}
                           isOpen={acmRpaDialog.visible}
                           confirmColor={'warning'}
                           confirmDisabled={!isDirty}
                           content={
                               () =>
                                   <>
                                       <SimpleForm form={ACM_RPA_INPUT_FORM}
                                                   redirect={false}
                                                   toolbar={null}
                                                   record={{
                                                       etc: {
                                                           ...data.etc,
                                                           acm: {
                                                               twr: has(data, 'etc.acm.twr') ? data.etc.acm.twr : false,
                                                               asm: has(data, 'etc.acm.asm') ? data.etc.acm.asm : false,
                                                           }
                                                       }
                                                   }}
                                       >
                                           {/*TODO Future feature*/}
                                           {/*<Labeled label={'status'}>*/}
                                           {/*    <Typography style={{color: `${FactoryRPA(data.etc.status).toString()}`}}>*/}
                                           {/*        {data.etc.status}*/}
                                           {/*    </Typography>*/}
                                           {/*</Labeled>*/}
                                           <BooleanInput source={'etc.acm.asm'}
                                                         label={`resources.${RESOURCES.ACM_RULES}.acmAsmToggle`}
                                                         options={{
                                                             disabled: !canEdit
                                                         }}
                                                         fullWidth/>
                                           <BooleanInput source={'etc.acm.twr'}
                                                         label={`resources.${RESOURCES.ACM_RULES}.acmTwrToggle`}
                                                         options={{
                                                             disabled: !canEdit
                                                         }}
                                                         fullWidth/>
                                       </SimpleForm>

                                       {
                                           !canEdit &&
                                           <Delay wait={300}>
                                               <InfoText content={<TranslatableTypography
                                                   content={`resources.${RESOURCES.ACM_RULES}.editingLocked`}
                                                   options={{ctr}}
                                                   variant={'body2'}/>}/>
                                               <br/>
                                           </Delay>
                                       }

                                       <Typography>
                                           <a href="javascript:void(0)"
                                              onClick={() => loadAcmRulesCTR(data.etc.master)}
                                           >
                                               {translate(`resources.${RESOURCES.ACM_RULES}.acmRulesTablePreview`, {ctr})}
                                           </a>
                                       </Typography>
                                   </>
                           }


            />
        )
    }
}

const mapStateToProps = state => {
    return {
        acmRpaDialog: state.myUi.acmRpaDialog,
        etcOutput: formValueSelector(ACM_RPA_INPUT_FORM)(state, 'etc'),
        isDirty: isDirty(ACM_RPA_INPUT_FORM)(state),
    }
};

const mapDispatchToProps = dispatch =>
    (
        {
            toggleAcmRpaDialog: (e) => {
                dispatch(toggleAcmRpaDialog(e));
            },
            saveAcmRulesRpa: ({etc, uid}) => dispatch(saveAcmRulesRPA({etc, uid})),
            loadAcmRulesCTR: (designator) => dispatch(loadAcmRulesCTR({designator})),
            resetForm: () => dispatch(reset(ACM_RPA_INPUT_FORM)),
            destroyForm: () => dispatch(destroy(ACM_RPA_INPUT_FORM)),
        }
    );

export default compose(
    translate,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(AcmRpaInputDialog);