
import React from "react";
import PoiGeomEditorMapUI from '../../../../gl_map/Components/Maps/PoiGeomEditorMapUI';
//import MissionConflictsSwitch from './MissionConflictsSwitch';

//currently the same as ZoneEditor,
// but
// will be extended to support multiple poi types
// currently uses zone viewer ui
// probably should switch here by type.

const PoiGeomEditor = ({record, conflicts, primitives, legsPrimitives, myAppExtras, bBox, ...rest}) => {
    const wrapperStyle = {position: "relative", height: "500px"};


    //currently zone editor!
    console.log('PoiEditor, legsPrimitives', legsPrimitives);
    return (
        <div>
            {/*<MissionConflictsSwitch*/}
                {/*conflicts={conflicts}*/}

                {/*//record={record}*/}
                {/*{...rest}*/}
            {/*/>*/}

            <PoiGeomEditorMapUI
                bBox = {bBox}
                //heightBundle = {heightBundle}
                wrapperStyle = {wrapperStyle}
                record = {record}
                //inspectData = {inspectData}
            />
        </div>
    )
};

export default PoiGeomEditor;
