
import {STORE_ALL_UNITS_V2} from "../constants/UnitsV2Constants";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {processUnitsV2ToDeck} from "../oldLogic/UnitsListV2";

//copy/paste of zone reducer. DRY me later!

const initialState = {rawData:[], deckData:initializeLayersObject(), storeTimestamp : 0};

const UnitsV2Reducer = (previousState = initialState, { type, payload }) => {
    switch (type) {
        case STORE_ALL_UNITS_V2:
            console.log('----- UnitsV2Reducer reducer STORE_ALL_UNITS_V2:', type, payload);

            if (payload.rawData) {
                payload.deckData = processUnitsV2ToDeck(payload);

            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};


        default:
            return previousState;

    }
};

export default UnitsV2Reducer;
