import React from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import MuiButton from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {translate} from 'ra-core';
import {Button} from 'react-admin';
import {Responsive} from "ra-ui-materialui";
import Dvr from '@material-ui/icons/Dvr';
import connect from "react-redux/lib/connect/connect";
import {bindActionCreators} from "redux";
import {toggleEventsStreamWindow} from "../logic/ui/MyUiActions";


const styles = theme => ({
    floating: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 60,
        left: 'auto',
        position: 'fixed',
        zIndex: 1000,
    },
    floatingLink: {
        color: 'inherit',
    },
});

const EventsStreamButton = ({
                                className,
                                classes = {},
                                translate,
                                toggleEventsStreamWindow,
                                resource,
                                ...rest
                            }) => (
    <Responsive
        medium={
            <Button
                component={MuiButton}
                className={className}
                label={translate(`resources.${resource}.live`)}
                onClick={() => toggleEventsStreamWindow({resource})}
                {...rest}
            >
                <Dvr/>
            </Button>
        }
    />
);

EventsStreamButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    size: PropTypes.string,
    translate: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
    (
        {
            toggleEventsStreamWindow: bindActionCreators(toggleEventsStreamWindow, dispatch),
        }
    );


const enhance = compose(
    translate,
    connect(
        null,
        mapDispatchToProps
    ),
    onlyUpdateForKeys(['label', 'translate']),
    withStyles(styles)
);

export default enhance(EventsStreamButton);
